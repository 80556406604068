import { useWorkspaceData } from 'features/workspace';
import { useWorkspaceSetupIntent } from './useWorkspaceSetupIntent';
import { useUpdateWorkspacePaymentMethods } from './useUpdateWorkspacePaymentMethods';
import { useDeletePaymentMethod } from './useDeletePaymentMethod';
import { useWorkspacePaymentInfo } from './useWorkspacePaymentInfo';

export const useWorkspacePaymentControls = () => {
	const { currentWorkspaceId } = useWorkspaceData();
	const { clientSecret } = useWorkspaceSetupIntent();
	const { mutate: mutateUpdatePM } = useUpdateWorkspacePaymentMethods();
	const { mutate: mutateDeletePM } = useDeletePaymentMethod();
	const { paymentMethods, refetch, isFetching } = useWorkspacePaymentInfo();

	return {
		clientSecret: clientSecret || '',
		onUpdatePaymentMethod: (cardId: string) =>
			mutateUpdatePM({ workspaceId: currentWorkspaceId, paymentMethodId: cardId }),
		onDeletePaymentMethod: (cardId: string) => mutateDeletePM({ paymentMethodId: cardId }),
		paymentMethods,
		refetch,
		isFetching,
	};
};
