import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { getErrorBody } from 'utils';
import { useRemoveInvite } from '../api/deleteInvite';
import { Invite, PendingInviteListViewProps } from '../types';
import { PendingInviteRow } from './PendingInviteRow';

export const PendingInviteListView = ({ invites }: PendingInviteListViewProps) => {
	const EMAIL_COL_WIDTH = '40%';
	const ROLE_COL_WIDTH = '20%';
	const INVITER_NAME_COL_WIDTH = '30%';
	const ACTION_COL_WIDTH = '10%';

	const { userRole } = useWorkspaceData();
	const toastNotification = useToastNotification();

	const removeInviteQuery = useRemoveInvite({
		config: {
			onError(error, _, __) {
				toastNotification.notifyFailure(getErrorBody(error));
			},
			onSuccess() {
				toastNotification.notifySuccess('Successfully removed invite');
			},
		},
	});

	const renderInviteRows = (pendingInvites: Invite[]) => {
		const isOwner = userRole.toLowerCase() === 'owner';
		return pendingInvites
			.sort((a, b) => a.date.localeCompare(b.date))
			.map((invite) => (
				<PendingInviteRow
					key={invite.id}
					invite={invite}
					emailColWidth={EMAIL_COL_WIDTH}
					roleColWidth={ROLE_COL_WIDTH}
					inviterNameColWidth={INVITER_NAME_COL_WIDTH}
					actionColWidth={ACTION_COL_WIDTH}
					onDeleteHandler={removeInviteQuery.mutate}
					deletable={isOwner}
				/>
			));
	};

	return (
		<div className="flex flex-col">
			{invites.length === 0 ? (
				<div className="w-full text-center p-6 text-sm font-semibold text-neutral-700 animate-fadeIn">
					No pending invites
				</div>
			) : (
				<>
					<div className="flex flex-row font-bold text-sm p-2.5 py-0">
						<h3 style={{ width: EMAIL_COL_WIDTH }}>Email</h3>
						<h3 style={{ width: ROLE_COL_WIDTH }}>Role</h3>
						<h3 style={{ width: INVITER_NAME_COL_WIDTH }}>Invited By</h3>
						<h3 style={{ width: ACTION_COL_WIDTH }}>&nbsp;</h3>
					</div>
					<div className="h-full max-h-36 w-full overflow-y-auto pb-4">
						{renderInviteRows(invites)}
					</div>
				</>
			)}
		</div>
	);
};
