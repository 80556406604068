import { Option, Select, SortButton, generateSortFieldList, useSort } from '@dropbaseio/components';
import { Button } from 'components/Button';
import { SectionHeader } from 'components/Common';
import { FormFieldSkeleton, FormRefreshButton } from 'components/Form';
import { useWorkspaceData } from 'features/workspace';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWorkspaceSources } from '../api/getAllSource';
import { SourceCard } from './SourceCard';

const SOURCE_SORT_STYLES = generateSortFieldList([
	{ name: 'Name', field: 'name', type: 'string' },
	{ name: 'Created', field: '', type: 'ignoreReverse' },
	{ name: 'Edited', field: 'modified_date', type: 'string', displayType: 'number' },
]);

export const SourceCardList = () => {
	const { currentWorkspaceId } = useWorkspaceData();
	const [, setSearchParams] = useSearchParams();

	const handleCreateSource = () => {
		setSearchParams({
			sourceId: 'new',
		});
	};

	const {
		sources: workspaceSources,
		refetch: refetchWorkspaceSources,
		isError: isErrorWorkspaceSources,
		isFetching: isFetchingWorkspaceSources,
	} = useWorkspaceSources({
		workspaceId: currentWorkspaceId,
	});

	const [sortMode, setSortMode] = useState(SOURCE_SORT_STYLES.find((x) => x.id === 'edited-dsc')!);
	const { sortedTarget: sortedSources } = useSort(sortMode, workspaceSources);

	if (isFetchingWorkspaceSources) {
		return (
			<div className="flex flex-col gap-1">
				<FormFieldSkeleton />
				<FormFieldSkeleton />
				<FormFieldSkeleton />
			</div>
		);
	}

	return (
		<div>
			<div className="flex flex-row justify-between">
				<div className="grow mb-2">
					<SectionHeader text="All Sources" />
				</div>
				<div className="flex flex-row gap-2">
					<Select
						value={sortMode.id}
						onChange={(val) => setSortMode(SOURCE_SORT_STYLES.find((x) => x.id === val)!)}
						buttonEntrypoint={SortButton}
						buttonProps={{ color: 'neutral', variant: 'outline', size: 'regular' }}
					>
						{SOURCE_SORT_STYLES.map((x) => (
							<Option key={x.id} value={x.id} className="font-medium text-sm">
								{x.text}
							</Option>
						))}
					</Select>
					<FormRefreshButton
						color="neutral"
						variant="outline"
						onClick={() => {
							refetchWorkspaceSources();
						}}
						className="mt-0 text-neutral-400"
					/>
					<Button color="neutral" variant="outline" onClick={handleCreateSource}>
						Create
					</Button>
				</div>
			</div>

			{isFetchingWorkspaceSources ? (
				<div className="flex flex-col gap-1">
					<FormFieldSkeleton />
					<FormFieldSkeleton />
					<FormFieldSkeleton />
				</div>
			) : (
				<div className="flex flex-col gap-1">
					{sortedSources.length === 0 ? (
						<div className="font-semibold text-sm text-neutral-500">
							No sources found.
							<br />
							Press &quot;Create&quot; to add a data source
						</div>
					) : (
						sortedSources.map((source) => {
							return <SourceCard key={source.id} source={source} />;
						})
					)}
				</div>
			)}
		</div>
	);
};
