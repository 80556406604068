import { NoPermission } from 'components/Common';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { WORKSPACE_ROLE } from 'utils';
import { ScheduleFormContainer } from './components/ScheduleFormContainer';
import { ScheduleList } from './components/ScheduleList';

export const SchedulesPage = () => {
	const { userRole } = useWorkspaceData();

	if (userRole === WORKSPACE_ROLE.BILLING) {
		return (
			<ContentLayout title="Schedules" description="Manage scheduled runs" width="max-w-4xl">
				<NoPermission />
			</ContentLayout>
		);
	}

	return (
		<ContentLayout
			title="Schedules"
			description="Manage scheduled runs"
			className="overflow-hidden"
			width="w-full"
		>
			<div className="flex flex-row gap-8 h-full animate-fadeIn">
				<div className="w-1/3 overflow-y-auto h-full min-w-[18rem] flex flex-col gap-4">
					<ScheduleList type="user" />
					<ScheduleList type="others" />
				</div>
				<div className="w-1/2 overflow-y-auto h-full animate-fadeIn">
					<ScheduleFormContainer />
				</div>
			</div>
		</ContentLayout>
	);
};
