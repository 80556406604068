import { axios } from 'libs/axios';
import { MutationConfig } from 'libs/react-query';
import { useMutation } from 'react-query';
import { TestQueryDTO } from '../types';

export const testQuery = async ({ sourceId, query }: TestQueryDTO) => {
	const { data } = await axios.post(`/query/test`, { query, source_id: sourceId });
	return data;
};

type UseTestQueryOptions = {
	config?: MutationConfig<typeof testQuery>;
};

export const useTestQuery = ({ config }: UseTestQueryOptions = {}) => {
	return useMutation({
		...config,
		mutationFn: testQuery,
	});
};
