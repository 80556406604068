import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/Common';
import { FormSwitch } from 'components/Form';
import { getHumanReadableString } from '@dropbaseio/components';
import { useAuthUserData } from 'features/auth';
import { SourceMap } from 'features/databases/constants';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WORKSPACE_ROLE, getErrorBody } from 'utils';
import { useDeleteSchedule } from '../api/deleteSchedule';
import { useUpdateScheduleStatus } from '../api/updateScheduleStatus';
import { Schedule } from '../types';

export const ScheduleListItem = ({ schedule }: { schedule: Schedule }) => {
	const [toggleEnabled, setToggleEnabled] = useState(schedule.enabled);
	const [deletingSchedule, setDeletingSchedule] = useState(false);
	const { currentWorkspaceId, userRole } = useWorkspaceData();
	const { userId } = useAuthUserData();

	const [searchParams, setSearchParams] = useSearchParams();
	const scheduleId = searchParams.get('scheduleId');

	const canEdit = userRole !== WORKSPACE_ROLE.MEMBER || schedule.user_id === userId;

	let formData = schedule.form_data;

	if (typeof formData === 'string') {
		try {
			formData = JSON.parse(formData);
		} catch (e) {
			//
		}
	}

	const toast = useToastNotification();
	const updateScheduleStatusQuery = useUpdateScheduleStatus({
		config: {
			onSuccess() {
				setToggleEnabled(!toggleEnabled);
			},
			onError(error, _, __) {
				toast.notifyFailure(`Failed to toggle schedule\n${getErrorBody(error)}`);
			},
		},
	});

	const lastRunISOTimestamp = `${schedule.status
		?.substring('Last run: '.length)
		.replace(' ', 'T')}Z`;

	const lastRunHumanReadable = schedule.status?.startsWith('Last run')
		? `Last run: ${new Date(lastRunISOTimestamp).toLocaleString(undefined, {
				timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		  })}`
		: null;

	const handleToggleOnClick = () => {
		updateScheduleStatusQuery.mutate({
			id: schedule.id,
			workspaceId: currentWorkspaceId,
			enabled: !toggleEnabled,
		});
	};

	const deleteScheduleQuery = useDeleteSchedule({
		config: {
			onSuccess() {
				toast.notifySuccess(`Successfully deleted schedule`);
			},
			onError() {
				toast.notifyFailure(`Failed to delete schedule`);
			},
		},
	});

	const handleDeleteOnClick = async () => {
		await deleteScheduleQuery.mutateAsync(schedule.id); // it does have an effect. it awaits for an async delete call
		setSearchParams({
			scheduleId: '',
		});
	};

	const EnableToggle = () => {
		if (!canEdit) {
			return <></>;
		}

		if (updateScheduleStatusQuery.isLoading) {
			return (
				<div className="w-[38px] rounded-full relative animate-fadeIn">
					<LoadingSpinner />
				</div>
			);
		}
		return <FormSwitch onClick={handleToggleOnClick} value={toggleEnabled} />;
	};

	const selected = scheduleId === schedule.id;

	return (
		<div
			className={`relative flex flex-col animate-fadeIn border rounded-md p-2 text-xs font-semibold text-neutral-700 transition-[border,box-shadow] hover:border-neutral-400 hover:shadow-lg ${
				selected ? 'border-neutral-400 shadow-lg' : 'border-neutral-300'
			}`}
		>
			<div className="flex flex-row justify-between">
				<div className="font-bold text-sm flex flex-row gap-1 items-center">
					<img
						src={SourceMap[schedule.source_type]?.iconSrc}
						alt={`${SourceMap[schedule.source_type]?.text} logo`}
						className="w-3 h-3"
					/>
					<span className="relative flex-1">{schedule.name}</span>
				</div>
				<div className="flex flex-row gap-1 h-5">
					{canEdit ? (
						<div className="flex flex-row group w-fit ml-2 transition-[border] rounded-md">
							<div className="transition-[width] overflow-hidden w-0 group-hover:w-5  text-xs font-semibold">
								<button type="button" onClick={() => setDeletingSchedule(true)}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										className="w-auto h-[18px] m-auto"
									>
										<path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
									</svg>
								</button>
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={2}
								stroke="currentColor"
								className="w-4 h-4 top-0.5 left-0.5 relative"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
								/>
							</svg>
						</div>
					) : (
						<></>
					)}

					<button
						onClick={() => {
							setSearchParams({
								scheduleId: schedule.id,
							});
						}}
						type="button"
						className="flex flex-row transition-[border,color] border border-neutral-300 text-neutral-400 rounded-md px-0.5 leading-normal hover:text-neutral-500 hover:border-neutral-400 "
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							className="w-4 h-4"
						>
							<path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
							<path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
						</svg>
					</button>

					<EnableToggle />
				</div>
			</div>
			<div className="flex flex-row">
				<div className="text-neutral-500 flex flex-col">
					<span>{schedule.description}</span>
					<span>Query: {schedule.query_name}</span>
					<span>
						Schedule:{' '}
						{getHumanReadableString({
							startTime: formData?.start_time ?? '',
							endTime: formData?.end_time ?? '',
							interval: formData?.interval,
							unit: formData?.unit,
							month_of_year: formData?.month_of_year || '*',
							day_of_month: formData?.day_of_month || '*',
							day_of_week: formData?.day_of_week || '*',
							method: schedule.method,
						})}
					</span>
					<span>{lastRunHumanReadable}</span>
				</div>
			</div>
			{deletingSchedule && (
				<div className="flex flex-col mt-2 transition-all">
					<div className="text-bold-500">Are you sure you want to delete this schedule?</div>
					<div className="flex flex-row mt-2 gap-2">
						<Button
							color="red"
							loading={deleteScheduleQuery.isLoading}
							variant="outline"
							onClick={handleDeleteOnClick}
						>
							Delete
						</Button>
						<Button onClick={() => setDeletingSchedule(false)}>Cancel</Button>
					</div>
				</div>
			)}
		</div>
	);
};
