import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useWorkspaceData } from 'features/workspace';
import { Card } from '../types';

type PaymentInfoResponse = {
	billing_portal: string;
	payment_methods: Card[];
};

export const fetchWorkspacePaymentInfo = async (workspaceId: string) => {
	const { data } = await axios.get<PaymentInfoResponse>(`/workspace/payment/${workspaceId}`);
	return data;
};
export const WorkspacePaymentInfoKey = 'paymentInfo';

export const useWorkspacePaymentInfo = (config?: QueryConfig<typeof fetchWorkspacePaymentInfo>) => {
	const { currentWorkspaceId } = useWorkspaceData();
	const queryKey = [WorkspacePaymentInfoKey, currentWorkspaceId];
	const { data, ...rest } = useQuery<ExtractFnReturnType<typeof fetchWorkspacePaymentInfo>>({
		...config,
		queryKey,
		queryFn: () => fetchWorkspacePaymentInfo(currentWorkspaceId),
	});
	return {
		billingPortal: data?.billing_portal,
		paymentMethods: data?.payment_methods || [],
		...rest,
	};
};
