import { Button, QueryListItem } from '@dropbaseio/components';
import { CloseIcon, EditIcon } from 'assets';
import { SourceMap } from 'features/databases/constants';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WORKSPACE_ROLE } from 'utils';
import { useDeleteQuery } from '../api/deleteQuery';
import { Query } from '../types';

export const QueryItem = ({ query }: { query: Query }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const toast = useToastNotification();
	const { userRole } = useWorkspaceData();

	const [deletingQuery, setDeletingQuery] = useState(false);

	const deleteQuery = useDeleteQuery({
		config: {
			onSuccess() {
				toast.notifySuccess('Successfully deleted query');
				setSearchParams({
					queryId: '',
				});
			},
			onError() {
				toast.notifyFailure('Failed to delete query. Is there a schedule still using it?');
			},
		},
	});

	const activeQueryId = searchParams.get('queryId');
	const isSelfSelected = activeQueryId === query.id;
	const querySource = SourceMap[query.source_type];

	const handleEditOnClick = () => {
		setSearchParams({
			queryId: query.id,
		});
	};

	const handleDeleteOnClick = () => {
		deleteQuery.mutateAsync(query.id);
	};

	return (
		<QueryListItem
			className={isSelfSelected ? 'border-neutral-400 shadow-lg' : 'border-neutral-300'}
		>
			<QueryListItem.Header>
				<QueryListItem.Image src={querySource.iconSrc} />
				<QueryListItem.Title>{query.name}</QueryListItem.Title>
				<QueryListItem.Actions>
					{userRole !== WORKSPACE_ROLE.MEMBER ? (
						<div className="flex flex-row group w-fit ml-2 transition-[border] rounded-md">
							<div className="transition-[width] overflow-hidden w-0 group-hover:w-5  text-xs font-semibold">
								<Button
									size="small"
									variant="outline"
									color="neutral"
									onClick={() => setDeletingQuery(true)}
									className="hover:border-red-400 hover:text-red-400 pl-0 pr-0"
								>
									<CloseIcon />
								</Button>
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={2}
								stroke="currentColor"
								className="w-4 h-4 top-0.5 left-0.5 relative"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
								/>
							</svg>
						</div>
					) : null}
					<Button
						variant="outline"
						color="neutral"
						size="small"
						className="pl-0.5 pr-0.5"
						onClick={handleEditOnClick}
					>
						<EditIcon />
					</Button>
				</QueryListItem.Actions>
			</QueryListItem.Header>
			<QueryListItem.Description>{query.description}</QueryListItem.Description>
			{deletingQuery && (
				<div className="flex flex-col mt-2 transition-all">
					<div className="text-bold-500">Are you sure you want to delete this query?</div>
					<div className="flex flex-row mt-2 gap-2">
						<Button color="red" variant="outline" onClick={handleDeleteOnClick}>
							Delete
						</Button>
						<Button onClick={() => setDeletingQuery(false)}>Cancel</Button>
					</div>
				</div>
			)}
		</QueryListItem>
	);
};
