import { Button } from 'components/Button';
import { FullscreenModal } from 'features/modal';
import { useCancelSubscription } from 'features/settings/api/cancelSubscription';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';

export const CancelSubscription = ({ className }: { className?: string }) => {
	const toast = useToastNotification();
	const { currentWorkspaceId } = useWorkspaceData();
	const [confirmCancelSubscription, setConfirmCancelSubscription] = useState(false);
	const cancelSubscriptionQuery = useCancelSubscription({
		config: {
			onSuccess() {
				toast.notifySuccess('Successfully cancelled subscription');
			},
			onError(error, variables, context) {
				toast.notifyFailure('Failed to cancel subscription');
			},
		},
	});
	const handleCancelSubscription = () => {
		cancelSubscriptionQuery.mutate(currentWorkspaceId);
		setConfirmCancelSubscription(false);
	};
	return (
		<>
			<div className={className ? `${className}` : 'w-1/3'}>
				<Button
					color="red"
					variant="outline"
					className="my-2"
					onClick={() => setConfirmCancelSubscription(true)}
					loading={cancelSubscriptionQuery.isLoading}
				>
					Cancel subscription
				</Button>
				<div className="text-sm font-semibold text-yellow-700">
					Note: Cancelling your subscription will mark your subscription to be cancelled at the end
					of the current billing period and any usage exceeding your plan limit will be added to
					your bill. All workspace schedules will stop running at that time.
				</div>
			</div>
			{confirmCancelSubscription && (
				<FullscreenModal onClose={() => setConfirmCancelSubscription(false)}>
					<div className="font-medium text-sm text-neutral-700">
						<span className="text-lg text-neutral-900 font-bold">
							Are you sure you want to cancel your subscription?
						</span>
						<br />
						Your subscription will be cancelled at the end of the current billing cycle and any
						extra usage will be charged to your credit card.
						<br />
					</div>
					<br />
					<div className="flex flex-row gap-2">
						<Button color="red" variant="outline" onClick={handleCancelSubscription}>
							Confirm
						</Button>
						<Button onClick={() => setConfirmCancelSubscription(false)}>Cancel</Button>
					</div>
				</FullscreenModal>
			)}
		</>
	);
};
