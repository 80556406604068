import { FormFieldSkeleton } from './FormFieldSkeleton';

export const FormSekeleton = () => {
	return (
		<div className="flex flex-col gap-5 animate-fadeIn">
			<FormFieldSkeleton />
			<div className="flex flex-row gap-2">
				<FormFieldSkeleton />
				<FormFieldSkeleton />
			</div>
			<FormFieldSkeleton />
			<FormFieldSkeleton />
			<div className="bg-neutral-200 w-1/2 h-10 rounded-md animate-pulse" />
			<div className="flex flex-row gap-2">
				<FormFieldSkeleton />
				<FormFieldSkeleton />
				<FormFieldSkeleton />
			</div>
		</div>
	);
};
