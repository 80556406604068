import { ExternalLinkIcon } from 'assets';
import { NoPermission, SectionHeader } from 'components/Common';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { useState } from 'react';
import { WORKSPACE_ROLE } from 'utils';
import { CardOptions } from './components/CardOptions';
import { InvoiceHistory } from './components/InvoiceHistory';
import { InvoiceSummary } from './components/InvoiceSummary';
import { PlanOptions } from './components/PlanOptions';
import { useWorkspacePaymentControls } from './api/useWorkspacePaymentControls';

export const BillingPage = () => {
	const { currentWorkspace, userRole } = useWorkspaceData();
	const [recommendedPlan, setRecommendedPlan] = useState('pro');
	const workspacePaymentControls = useWorkspacePaymentControls();

	if (userRole === WORKSPACE_ROLE.ADMIN || userRole === WORKSPACE_ROLE.MEMBER) {
		return (
			<ContentLayout
				title="Billing"
				description="Manage plans, payment methods, and invoices"
				width="w-full"
			>
				<NoPermission />
			</ContentLayout>
		);
	}

	return (
		<ContentLayout
			title="Billing"
			description="Manage plans, payment methods, and invoices"
			width="w-full"
		>
			<br />
			<SectionHeader text="Plans" />
			<a className="inline-flex min-w-min hover:underline" href="https://www.castodia.com/pricing">
				<div className="text-neutral-700 font-bold mr-1">Go to pricing</div>
				<ExternalLinkIcon />
			</a>
			<PlanOptions
				currentPlan={currentWorkspace ? currentWorkspace.plan : ''}
				recommendedPlan={recommendedPlan}
			/>

			{/* COMMENTED OUT FOR PROD MIGRATION
            TODO: UNCOMMENT FOR DEV */}
			<br />
			<SectionHeader text="Payment Methods" />
			<CardOptions {...workspacePaymentControls} forSubscription />
			<br />
			{/* <SectionHeader text="Account Balance" />
			<AccountBalance />
			<br /> */}
			{currentWorkspace?.status !== 'trialing' ? (
				<>
					<SectionHeader text="Invoices" />
					<InvoiceSummary />
					<br />
					<InvoiceHistory />
				</>
			) : null}
			<br />
			<br />
			<br />
			<br />
		</ContentLayout>
	);
};
