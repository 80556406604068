import { FormSekeleton } from 'components/Form';
import { ScheduleError, ScheduleForm, Button } from '@dropbaseio/components';
import { useAuthUserData } from 'features/auth';
import { WORKSPACE_ROLE } from 'utils';
import { useWorkspaceData } from 'features/workspace';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToastNotification } from 'hooks/useToastNotification';
import { useDeleteSchedule } from '../api/deleteSchedule';
import { useSchedule } from '../api/schedule';
import { useUpdateSchedule } from '../api/updateSchedule';
import { QuerySelect } from './QuerySelect';

export const ScheduleFormContainer = () => {
	const toast = useToastNotification();
	const { userId } = useAuthUserData();
	const { userRole } = useWorkspaceData();

	const [searchParams] = useSearchParams();
	const scheduleId = searchParams.get('scheduleId');
	const selectedScheduleId = scheduleId || '';

	const navigate = useNavigate();

	const {
		schedule: scheduleInfo,
		isError: hasScheduleError,
		error: scheduleError,
		isFetching: isFetchingSchedule,
	} = useSchedule({
		scheduleId: selectedScheduleId || '',
		userId,
		config: {
			enabled: !!selectedScheduleId,
		},
	});

	const updateScheduleQuery = useUpdateSchedule({
		config: {
			onSuccess() {
				toast.notifySuccess(`Successfully updated schedule`);
			},
			onError() {
				toast.notifyFailure(`Failed to update schedule`);
			},
		},
	});

	const deleteScheduleQuery = useDeleteSchedule({
		config: {
			onSuccess() {
				navigate('..');
				toast.notifySuccess(`Successfully deleted schedule`);
			},
			onError() {
				toast.notifyFailure(`Failed to delete schedule`);
			},
		},
	});

	const handleSave = (values: any) => {
		const {
			startTime: newStartTime,
			endTime: newEndTime,
			runFrequency: frequency,
			runInterval: interval,
			runUnit: unit,
			timezone,

			scheduleName,
			scheduleDescription,
			action,

			dateOfMonth: submittedDOM,
			dayOfWeek: submittedDOW,
			monthOfYear: submittedMOY,

			query,
		} = values;

		const submittedData: any = {
			start_time: newStartTime,
			end_time: newEndTime || null,
			interval,
			unit,
			frequency,
			month_of_year: submittedMOY || '*',
			day_of_month: submittedDOM || '*',
			day_of_week: submittedDOW || '*',
		};

		if (query === '-1' || scheduleName === '') {
			toast.notifyFailure('Form is incomplete.');
			return;
		}

		if (scheduleInfo?.sheet_data) {
			updateScheduleQuery.mutate({
				id: selectedScheduleId,
				payload: {
					name: scheduleName,
					description: scheduleDescription,

					query_id: query,
					user_id: scheduleInfo?.user_id || '',
					sheet_data: scheduleInfo.sheet_data,

					method: action,
					timezone,

					form_data: submittedData,
				},
			});
		}
	};

	const handleDeleteSchedule = () => {
		deleteScheduleQuery.mutate(selectedScheduleId);
	};

	const canEditSchedule = userRole !== WORKSPACE_ROLE.MEMBER || scheduleInfo?.user_id === userId;

	const renderScheduleForm = () => {
		if (hasScheduleError) {
			return <ScheduleError error={scheduleError} />;
		}

		if (isFetchingSchedule) {
			return (
				<div className="mt-6">
					<FormSekeleton />
				</div>
			);
		}

		// show view only schedule if user is a member accessing others schedules
		if (!canEditSchedule) {
			return (
				<ScheduleForm schedule={scheduleInfo} onSubmit={handleSave}>
					<QuerySelect disabled />
					<ScheduleForm.Name disabled />
					<ScheduleForm.Description disabled />

					<div className="flex flex-row gap-2">
						<ScheduleForm.Spreadsheet />
						<ScheduleForm.Sheets
							disabled
							sheets={[
								{
									id: scheduleInfo?.sheet_data?.id || '',
									name: scheduleInfo?.sheet_data?.sheet_name || '',
								},
							]}
						/>
					</div>

					<ScheduleForm.Action disabled />
					<ScheduleForm.RepeatOption disabled />
					<ScheduleForm.ScheduleSettings disabled />
					<ScheduleForm.TimeZone disabled />

					<ScheduleForm.QueryString />
				</ScheduleForm>
			);
		}

		if (scheduleInfo) {
			return (
				<ScheduleForm schedule={scheduleInfo} onSubmit={handleSave}>
					<QuerySelect />
					<ScheduleForm.Name />
					<ScheduleForm.Description />

					<div className="flex flex-row gap-2">
						<div className="flex-1">
							<ScheduleForm.Spreadsheet />
						</div>
						<div className="flex-1">
							<ScheduleForm.Sheets
								disabled
								sheets={[
									{
										id: scheduleInfo.sheet_data.sheet_id,
										name: scheduleInfo.sheet_data.sheet_name,
									},
								]}
							/>
						</div>
					</div>

					<ScheduleForm.Action />
					<ScheduleForm.RepeatOption />
					<ScheduleForm.ScheduleSettings />
					<ScheduleForm.TimeZone />

					<ScheduleForm.QueryString />

					<div className="flex flex-row gap-2">
						<Button
							onClick={handleDeleteSchedule}
							color="red"
							variant="outline"
							loading={deleteScheduleQuery.isLoading}
							className="flex-1"
						>
							Delete
						</Button>
						<Button loading={updateScheduleQuery.isLoading} type="submit" className="flex-1">
							Update
						</Button>
					</div>
				</ScheduleForm>
			);
		}

		return null;
	};

	if (scheduleId) {
		return (
			<div className="sticky top-0">
				<div className="text-xl font-bold truncate mb-2">
					{`${canEditSchedule ? 'Editing' : 'Viewing'} ${scheduleInfo?.name || ''}`}
				</div>
				<div className="mb-20 relative -top-4">{renderScheduleForm()}</div>
			</div>
		);
	}

	return null;
};
