import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useAuthUserData } from 'features/auth';

type SetupIntentResponse = {
	client_secret: string;
};

export const fetchUserSetupIntent = async (userId: string) => {
	const { data } = await axios.get<SetupIntentResponse>(`/user/setup_payment/${userId}`);
	return data;
};

export const USER_SETUP_INTENT_QUERY_KEY = 'user-setup-intent';

export const useUserSetupIntent = (config?: QueryConfig<typeof fetchUserSetupIntent>) => {
	const { userId } = useAuthUserData();
	const { data, ...rest } = useQuery<ExtractFnReturnType<typeof fetchUserSetupIntent>>({
		...config,
		queryKey: USER_SETUP_INTENT_QUERY_KEY,
		queryFn: () => fetchUserSetupIntent(userId),
	});
	return {
		clientSecret: data?.client_secret,
		...rest,
	};
};
