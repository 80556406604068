import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { Role } from '../types';
import { ROLES_QUERY_KEY } from './getRoles';

export const removeRole = (roleId: string) => {
	return axios.delete(`/role/${roleId}`);
};

type UseRemoveRoleOptions = {
	config?: MutationConfig<typeof removeRole>;
};

export const useRemoveRole = ({ config }: UseRemoveRoleOptions = {}) => {
	return useMutation({
		...config,
		onSuccess: (data, variables, context) => {
			if (config?.onSuccess) {
				config?.onSuccess(data, variables, context);
			}

			queryClient.invalidateQueries(ROLES_QUERY_KEY);
			// console.log('invalidated');
		},
		mutationFn: removeRole,
	});
};
