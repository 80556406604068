import { AxiosError } from 'axios';
import {
	QueryCache,
	QueryClient,
	UseMutationOptions,
	MutationCache,
	UseQueryOptions,
} from 'react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity, // make data stale in 5 seconds
			retry: 1,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	},
	queryCache: new QueryCache({
		onError: (err, query) => {
			if (query.meta?.showError) {
				console.log(err);
			}
		},
	}),
	mutationCache: new MutationCache({
		onError(err, variables, context, mutation) {
			if (mutation.meta?.hideError) {
				return;
			}
			console.log(err);
		},
	}),
});

// Taken from: https://github.com/alan2207/bulletproof-react/blob/master/src/lib/react-query.ts#L15-L28

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
	UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
	'queryKey' | 'queryFn'
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
	ExtractFnReturnType<MutationFnType>,
	AxiosError,
	Parameters<MutationFnType>[0]
>;
