import { PropsWithChildren } from 'react';

export const FormSelectDropdown = ({ children }: PropsWithChildren) => {
	return (
		<div className="relative tracking-wide text-neutral-700 text-xs font-bold">
			<div className="relative w-full">
				<ul className="absolute shadow-lg rounded-md mt-1 z-20 w-full bg-white max-h-60 overflow-y-auto border border-neutral-400">
					{children}
				</ul>
			</div>
		</div>
	);
};
