import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { SourcePayload } from '../types';
import { ALL_SOURCES_QUERY_KEY } from './getAllSource';

export interface UpdateSourceDto {
	sourceId: string;
	payload: SourcePayload;
}

export const updateSource = ({ sourceId, payload }: UpdateSourceDto) => {
	return axios.put(`/source/${sourceId}`, payload);
};

type UseUpdateSourceOptions = {
	config?: MutationConfig<typeof updateSource>;
};

export const useUpdateSource = ({ config }: UseUpdateSourceOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(ALL_SOURCES_QUERY_KEY);
		},
		mutationFn: updateSource,
	});
};
