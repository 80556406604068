import { useWorkspaceData } from 'features/workspace';
import { useLeaveWorkspace } from 'features/workspace/api/leaveWorkspace';
import { useNavigate } from 'react-router-dom';
import { useAuthUserData } from 'features/auth';
import { useToastNotification } from 'hooks/useToastNotification';
import { useRemoveRole } from '../api/removeRole';
import { MemberListViewProps, Role } from '../types';
import { MemberRow } from './MemberRow';

export const MemberListView = ({
	roles,
	allRoleOptions,
	selectableRoleOptions,
}: MemberListViewProps) => {
	const EMAIL_COL_WIDTH = '40%';
	const USERNAME_COL_WIDTH = '30%';
	const ROLE_COL_WIDTH = '20%';
	const ACTION_COL_WIDTH = '5%';

	const navigate = useNavigate();
	const toastNotification = useToastNotification();

	const { currentWorkspaceId: workspaceId } = useWorkspaceData();
	const { userId } = useAuthUserData();

	const removeRoleQuery = useRemoveRole({
		config: {
			onSuccess: () => {
				toastNotification.notifySuccess('Successfully removed member');
			},
			onError: () => {
				toastNotification.notifyFailure('Failed to remove member');
			},
		},
	});

	const leaveWorkspaceMutation = useLeaveWorkspace({
		config: {
			onSuccess: () => {
				navigate('/');
				toastNotification.notifySuccess('Successfully left workspace');
			},
			onError: () => {
				toastNotification.notifyFailure('Failed to leave workspace');
			},
		},
	});

	const handleOnRemove = async (roleId: string) => {
		await removeRoleQuery.mutateAsync(roleId);
	};

	const handleLeaveWorkspace = async () => {
		leaveWorkspaceMutation.mutate({
			workspaceId,
			userId,
		});
	};

	const renderMemberRows = (members: Role[]) => {
		return members
			.sort((a, b) => a.id.localeCompare(b.id))
			.map((member) => {
				return (
					<MemberRow
						key={member.id}
						allRoleOptions={allRoleOptions}
						selectableRoleOptions={selectableRoleOptions}
						usernameColWidth={USERNAME_COL_WIDTH}
						emailColWidth={EMAIL_COL_WIDTH}
						roleColWidth={ROLE_COL_WIDTH}
						actionColWidth={ACTION_COL_WIDTH}
						role={member}
						onRemoveHandler={handleOnRemove}
						onLeaveWorkspace={handleLeaveWorkspace}
					/>
				);
			});
	};

	const renderTable = (members: Role[]) => {
		if (members.length === 0) {
			return (
				<div className="w-full text-center p-12 text-sm font-semibold text-neutral-700">
					No members in workspace
				</div>
			);
		}
		return (
			<>
				<div className="flex flex-row font-bold text-sm p-2.5 py-0">
					<h3 style={{ width: EMAIL_COL_WIDTH }}>Email</h3>
					<h3 style={{ width: USERNAME_COL_WIDTH }}>Name</h3>
					<h3 style={{ width: ROLE_COL_WIDTH }}>Role</h3>
					<h3 style={{ width: ACTION_COL_WIDTH }}>&nbsp;</h3>
				</div>
				<div className="overflow-hidden min-h-[16rem]">
					<div className="h-full w-full overflow-y-auto">{renderMemberRows(members)}</div>
				</div>
			</>
		);
	};
	return <div className="flex flex-col">{renderTable(roles)}</div>;
};
