import { axios } from 'libs/axios';
import { MutationConfig } from 'libs/react-query';
import { useMutation } from 'react-query';

export const testSource = async ({
	type,
	workspaceId,
	creds,
}: {
	type: string;
	workspaceId: string;
	creds: Record<string, string>;
}) => {
	const resp = await axios.post(`/source/test/${type}?workspace_id=${workspaceId}`, creds);
	return resp;
};

type UseTestSourceOptions = {
	config?: MutationConfig<typeof testSource>;
};

export const useTestSource = ({ config }: UseTestSourceOptions = {}) => {
	return useMutation({
		...config,
		mutationFn: testSource,
	});
};
