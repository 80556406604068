import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';

export const cancelSubscription = (workspaceId: string) => {
	return axios.post(`/workspace/cancel_subscription/${workspaceId}`);
};

export const useCancelSubscription = ({
	config,
}: { config?: MutationConfig<typeof cancelSubscription> } = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries();
			queryClient.clear();
		},
		mutationFn: cancelSubscription,
	});
};
