import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { CreateWorkspaceResponse, WorkspacePayload } from '../types';

export const createWorkspace = async (
	workspacePayload: WorkspacePayload,
): Promise<CreateWorkspaceResponse> => {
	const { data } = await axios.post('/workspace/', workspacePayload);
	return data;
};

type UseCreateWorkspaceOptions = {
	config?: MutationConfig<typeof createWorkspace>;
};

export const useCreateWorkspace = ({ config }: UseCreateWorkspaceOptions = {}) => {
	return useMutation({
		...config,
		mutationFn: createWorkspace,
	});
};
