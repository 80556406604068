import { LoadingSpinner, SectionHeader } from 'components/Common';
import { PromptLayout } from 'components/Layout';
import { useAuthUserData } from 'features/auth';
import { Link } from 'react-router-dom';
import { AccountIcon } from 'assets';
import { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { useAtomValue } from 'jotai';
import { FormFieldSkeleton } from 'components/Form';
import { useUserWorkspaces } from './api/userWorkspaces';
import { WorkspaceItem } from './components/WorkspaceItem';

import { useUserWorkspaceInvites } from './api/userWorkspaceInvites';
import { NewWorkspaceInviteList } from './new-components/WorkspaceInviteList';
import { activeWorkspaceAtom } from './atoms';
import { CreateWorkspaceWizard } from './new-components/CreateWorkspaceWizard';

export const WorkspaceList = () => {
	const activeWorkspaceId = useAtomValue(activeWorkspaceAtom);
	const { userId } = useAuthUserData();
	const { workspaces, isLoading: isLoadingWorkspaces } = useUserWorkspaces({
		userId,
	});
	const [creatingWorkspace, setCreatingWorkspace] = useState(false);
	const currentWorkspace = workspaces.find(
		(workspace) => workspace.workspace_id === activeWorkspaceId,
	);

	const hasOwnWorkspace = !!workspaces.find((w) => w.role === 'owner');

	const { invites, isLoading: isLoadingUserInvites } = useUserWorkspaceInvites({ userId });

	const renderLoadingPrompt = (message: string) => {
		return (
			<PromptLayout>
				<div className="relative w-80">
					<LoadingSpinner>
						<div className="text-sm font-bold text-neutral-700 text-center p-4">{message}</div>
					</LoadingSpinner>
				</div>
			</PromptLayout>
		);
	};
	const renderWorkspaceContent = () => {
		if (creatingWorkspace) {
			return (
				<>
					<div className="border-b mt-10 mb-8 w-full" />
					<CreateWorkspaceWizard />
				</>
			);
		}
		if (workspaces.length > 0) {
			return (
				<>
					<div className="mb-6 flex flex-col gap-1">
						{workspaces.map((workspace) => (
							<Link to={`/${workspace.workspace_id}`} key={workspace.workspace_id}>
								<WorkspaceItem name={workspace.name} userRole={workspace.role} />
							</Link>
						))}
					</div>
				</>
			);
		}
		return (
			<div className="text-sm font-bold text-neutral-700 text-center p-2">No workspaces found</div>
		);
	};
	useEffect(() => {
		if (!hasOwnWorkspace) {
			setCreatingWorkspace(true);
		}
	}, [hasOwnWorkspace]);

	if (isLoadingWorkspaces) {
		return renderLoadingPrompt('Retrieving your workspaces...');
	}

	return (
		<PromptLayout>
			<div className="min-w-[24rem] max-w-[40vw] max-h-[40rem] overflow-y-auto">
				<div className="flex">
					<SectionHeader text="Select a workspace" />
					<Button
						variant="outline"
						className="ml-auto w-min h-min"
						onClick={() => setCreatingWorkspace(!creatingWorkspace)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-4 p-0"
						>
							{creatingWorkspace ? (
								<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15" />
							) : (
								<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
							)}
						</svg>
					</Button>
				</div>
				{renderWorkspaceContent()}

				{isLoadingUserInvites ? <FormFieldSkeleton /> : null}
				{invites.length > 0 ? (
					<div className="animate-fadeIn">
						<div className="border-b mt-10 mb-8 w-full" />
						<SectionHeader text="Join a Workspace" />
						<NewWorkspaceInviteList />
					</div>
				) : null}
			</div>
			<div className="border-b relative mt-6 mb-2 w-full" />
			<Link to="/account">
				<button
					type="button"
					className="flex flex-row font-semibold gap-2 mt-4 text-neutral-700 hover:text-neutral-900 transition-colors"
				>
					{AccountIcon} Profile
				</button>
			</Link>
		</PromptLayout>
	);
};
