import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { Invite, Role } from '../types';

export const getPendingInvites = async (workspaceId: string): Promise<Invite[]> => {
	const { data } = await axios.get(`/invite/all/${workspaceId}`);
	return data;
};

type QueryFnType = typeof getPendingInvites;

export const PENDING_INVITE_QUERY_KEY = 'workspace-pending-invites';

type UseRolesOptions = {
	workspaceId: string;
	config?: QueryConfig<QueryFnType>;
};

export const usePendingInvites = ({ workspaceId, config }: UseRolesOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: [PENDING_INVITE_QUERY_KEY, workspaceId],
		queryFn: () => getPendingInvites(workspaceId),
	});
	return {
		pendingInvites: data || [],
		...rest,
	};
};
