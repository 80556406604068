import { SmallMembersIcon } from 'assets';
import { FormFieldSkeleton, FormRefreshButton, FormSelect } from 'components/Form';
import { useAuthUserData } from 'features/auth';
import { useWorkspaceQuery } from 'features/queries/api/getWorkspaceQuery';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { Controller, useFormContext } from 'react-hook-form';

export const QuerySelect = ({ disabled }: { disabled?: boolean }) => {
	const toast = useToastNotification();
	const { currentWorkspaceId } = useWorkspaceData();
	const { userId } = useAuthUserData();

	const { control } = useFormContext();

	const {
		workspaceQueries,
		refetch: refetchWorkspaceQueries,
		isFetching: isFetchingWorkspaceQueries,
		isError: hasQueryError,
	} = useWorkspaceQuery({
		workspaceId: currentWorkspaceId,
	});

	const userQueries = workspaceQueries.filter((x) => x.user_id === userId);
	const othersQueries = workspaceQueries.filter((x) => x.user_id !== userId);
	const queryOptions = [...userQueries, ...othersQueries].map((query) => ({
		id: query.id,
		text: '',
		textComponent: (
			<div className="flex flex-row justify-between h-full">
				{query.name}
				{query.user_id !== userId ? (
					<div className="my-auto">
						<SmallMembersIcon />
					</div>
				) : null}
			</div>
		),
	}));

	const handleOnQueriesRefresh = () => {
		refetchWorkspaceQueries();
		toast.notifyMessage('Refetching queries');
	};

	if (hasQueryError) {
		return (
			<div className="font-semibold text-xs text-neutral-700 p-4 text-center">
				Failed to fetch query
				<br /> Please come back another time
			</div>
		);
	}

	return (
		<div className="flex flex-row gap-2 items-end">
			<div className="w-full">
				<Controller
					name="query"
					control={control}
					render={({ field: { onChange, value: currentQuery } }) => {
						const selectedQueryOption = queryOptions.find((q) => q.id === currentQuery) || {
							text: 'Please select query',
							id: '-1',
						};

						if (isFetchingWorkspaceQueries) {
							return <FormFieldSkeleton />;
						}
						return (
							<FormSelect
								id="query"
								label="Query"
								selectItems={queryOptions}
								value={selectedQueryOption}
								onChangeHandler={(newOption) => {
									onChange(newOption.id);
								}}
							/>
						);
					}}
				/>
			</div>
			<FormRefreshButton
				onClick={handleOnQueriesRefresh}
				disabled={isFetchingWorkspaceQueries || disabled}
			/>
		</div>
	);
};
