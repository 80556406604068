import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { InvitePayload } from '../types';
import { ROLES_QUERY_KEY } from './getRoles';
import { PENDING_INVITE_QUERY_KEY } from './pendingInvites';

export const createInvite = (invitePayload: InvitePayload) => {
	return axios.post('/invite/', invitePayload);
};

type UseCreateInviteOptions = {
	config?: MutationConfig<typeof createInvite>;
};

export const useCreateInvite = ({ config }: UseCreateInviteOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(PENDING_INVITE_QUERY_KEY);
		},
		mutationFn: createInvite,
	});
};
