import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'components/Button';
import { useState } from 'react';

export const AddCard = ({
	onSuccess,
	disableButton = false,
	buttonClassName,
}: {
	onSuccess?: (result: any) => void;
	disableButton?: boolean;
	buttonClassName?: string;
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [isAddingCard, setIsAddingCard] = useState(disableButton);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmitPayment = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!stripe || !elements) return;
		setIsLoading(true);
		const result = await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: 'https://app.castodia.com',
			},
			redirect: 'if_required',
		});
		if (!result.error) {
			setIsAddingCard(false);
			setIsLoading(false);
			if (onSuccess) {
				onSuccess(result);
			}
		}
	};

	return (
		<>
			{!disableButton && (
				<button
					type="button"
					onClick={() => {
						setIsAddingCard(!isAddingCard);
					}}
					className={`inline-flex transition-[border,box-shadow,background] align-bottom h-[119px] w-[120px] mt-1 flex-col rounded-md border border-neutral-300 text-neutral-700 font-bold text-sm hover:border-neutral-400 hover:shadow-md active:bg-neutral-100 ${buttonClassName}`}
				>
					<div className="w-full h-full flex flex-col justify-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-full m-auto"
						>
							{isAddingCard ? (
								<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15" />
							) : (
								<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
							)}
						</svg>
					</div>
				</button>
			)}
			<div className="mt-4">
				<form onSubmit={handleSubmitPayment}>
					{isAddingCard ? (
						<>
							<PaymentElement />
							<Button className="mt-2" type="submit" loading={isLoading}>
								Add Payment Method
							</Button>
						</>
					) : null}
				</form>
			</div>
		</>
	);
};
