import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useWorkspaceData } from 'features/workspace';

export type SubscriptionPlan = {
	name: string;
	show_on_UI: boolean;
	description?: string;
	auto_refreshes: string;
	additional_cost?: string;
	user_limit?: any;
	monthly_fee?: number;
	internal?: string;
	is_custom?: boolean;
};

export const fetchSubscriptionPlans = async (workspaceId: string) => {
	const { data } = await axios.get<SubscriptionPlan[]>(`/stripe/plans/${workspaceId}`);
	return data;
};

export const SubscriptionPlansKey = 'subscriptionPlans';

export const useWorkspaceSubscriptionPlans = (
	config?: QueryConfig<typeof fetchSubscriptionPlans>,
) => {
	const { currentWorkspaceId } = useWorkspaceData();
	const { data, ...rest } = useQuery<ExtractFnReturnType<typeof fetchSubscriptionPlans>>({
		...config,
		queryKey: [SubscriptionPlansKey, currentWorkspaceId],
		queryFn: () => fetchSubscriptionPlans(currentWorkspaceId),
		enabled: !!currentWorkspaceId,
	});

	const standardPlans = data?.filter((plan) => !!plan?.is_custom) || [];
	const customPlans = data?.filter((plan) => plan?.is_custom) || [];
	const allPlans = data || [];

	return {
		standardPlans,
		customPlans,
		allPlans,
		...rest,
	};
};
