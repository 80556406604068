import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { ALL_SOURCES_QUERY_KEY } from './getAllSource';

export const deleteSource = (sourceId: string) => {
	return axios.delete(`/source/${sourceId}`);
};

type UseDeleteSourceOptions = {
	config?: MutationConfig<typeof deleteSource>;
};

export const useDeleteSource = ({ config }: UseDeleteSourceOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(ALL_SOURCES_QUERY_KEY);
		},
		mutationFn: deleteSource,
	});
};
