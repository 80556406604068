import { AxiosError } from 'axios';
import { SectionHeader } from 'components/Common';
import { FormFieldSkeleton, FormRefreshButton } from 'components/Form';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { MEMBER_ROLES, SELECTABLE_MEMBER_ROLES, WORKSPACE_ROLE } from 'utils';
import { useRoles } from './api/getRoles';
import { usePendingInvites } from './api/pendingInvites';
import { MemberInviteControl } from './components/MemberInviteControl';
import { MemberListView } from './components/MemberListView';
import { PendingInviteListView } from './components/PendingInviteListView';

export const MembersPage = () => {
	const { currentWorkspaceId, userRole } = useWorkspaceData();
	const toastNotification = useToastNotification();

	const {
		roles,
		isFetching: isFetchingRoles,
		refetch: refetchRoles,
	} = useRoles({
		workspaceId: currentWorkspaceId,
		config: {
			onError() {
				toastNotification.notifyFailure('Failed retireve workspace members');
			},
			staleTime: 5 * 1000,
			refetchOnMount: true,
		},
	});

	const {
		pendingInvites,
		isFetching: isFetchingInvites,
		refetch: refetchInvites,
	} = usePendingInvites({
		workspaceId: currentWorkspaceId,
		config: {
			onError(err) {
				const error: AxiosError = err as AxiosError;
				if (error.response?.status !== 404) {
					toastNotification.notifyFailure('Failed to retrieve pending invites');
				}
			},
			staleTime: 5 * 1000,
			refetchOnMount: true,
		},
	});

	return (
		<ContentLayout title="Team" description="Manage team members and roles">
			<div className="h-full overflow-y-auto flex flex-col min-h-[45rem] gap-2 animate-fadeIn">
				{userRole !== WORKSPACE_ROLE.MEMBER ? (
					<>
						<div>
							<SectionHeader text="Invite Member" />
							<MemberInviteControl
								roleOptions={SELECTABLE_MEMBER_ROLES}
								currentMemberCount={roles.length}
								pendingInviteCount={pendingInvites.length}
							/>
						</div>

						{pendingInvites.length > 0 ? (
							<>
								<div className="flex flex-row justify-between mt-4">
									<div className="relative bottom-0">
										<SectionHeader text="Pending Invites" />
									</div>
									<FormRefreshButton
										color="neutral"
										variant="outline"
										className="pl-2 pr-2 text-neutral-400 w-min"
										onClick={() => {
											refetchInvites();
										}}
									/>
								</div>

								{isFetchingInvites ? (
									<div className="flex flex-col gap-1 mt-6 animate-fadeIn">
										<FormFieldSkeleton />
										<FormFieldSkeleton />
										<FormFieldSkeleton />
									</div>
								) : (
									<PendingInviteListView invites={pendingInvites} />
								)}
							</>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
				<div className="flex flex-row justify-between mt-4">
					<div className="relative bottom-0">
						<SectionHeader text="Current Members" />
					</div>
					<FormRefreshButton
						color="neutral"
						variant="outline"
						className="pl-2 pr-2 text-neutral-400 w-min"
						onClick={() => {
							refetchRoles();
						}}
					/>
				</div>
				{isFetchingRoles ? (
					<div className="flex flex-col gap-1 mt-7 animate-fadeIn">
						<FormFieldSkeleton />
						<FormFieldSkeleton />
						<FormFieldSkeleton />
					</div>
				) : (
					<MemberListView
						roles={roles}
						allRoleOptions={MEMBER_ROLES}
						selectableRoleOptions={SELECTABLE_MEMBER_ROLES}
					/>
				)}
				<br />
				<br />
			</div>
		</ContentLayout>
	);
};
