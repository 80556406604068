import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { USER_WORKSPACE_INVITES_KEY } from './userWorkspaceInvites';

export const rejectInvite = ({ inviteId }: { inviteId: string }) => {
	return axios.post(`/invite/user/reject/${inviteId}`);
};

type UseRejectInviteOptions = {
	config?: MutationConfig<typeof rejectInvite>;
};

export const useRejectInvite = ({ config }: UseRejectInviteOptions = {}) => {
	return useMutation({
		...config,
		mutationFn: rejectInvite,
		onSuccess() {
			queryClient.invalidateQueries(USER_WORKSPACE_INVITES_KEY);
		},
	});
};
