import { useComponentVisible } from 'hooks/useComponentVisible';
import React from 'react';
import { v4 } from 'uuid';
import { FormSelectDropdown } from './FormSelectDropdown';
import { FormSelectDropdownItem } from './FormSelectDropdownItem';

export interface FormSelectValue {
	id: string;
	text: string;
	textComponent?: React.ReactNode;
	isBreakpoint?: boolean;
	iconSrc?: string;
}
export interface FormSelectProp {
	id: string;
	label?: string;
	selectItems: FormSelectValue[];
	value: FormSelectValue;
	inline?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	onChangeHandler: (value: FormSelectValue) => void;
}

export const FormSelect = ({
	id,
	label = '',
	selectItems,
	value,
	inline = false,
	hasError = false,
	disabled,
	onChangeHandler,
}: FormSelectProp) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const onMenuClick = () => {
		setIsComponentVisible(!isComponentVisible);
	};

	const onSelect = (selectedValue: FormSelectValue) => {
		setIsComponentVisible(false);
		onChangeHandler(selectedValue);
	};

	const renderItems = (listOptions: FormSelectValue[]): React.ReactNode[] => {
		return listOptions.map((element) => {
			return (
				<FormSelectDropdownItem
					key={v4()}
					onSelect={() => onSelect(element)}
					isBreakpoint={element.isBreakpoint}
					text={element.text}
					textComponent={element.textComponent}
					iconSrc={element.iconSrc}
				/>
			);
		});
	};

	const renderSelectDropdown = (isVisible: boolean, dropdownItems: FormSelectValue[]) => {
		if (!isVisible) {
			return <></>;
		}
		return <FormSelectDropdown>{renderItems(dropdownItems)}</FormSelectDropdown>;
	};

	const renderSelect = (isInline: boolean) => {
		if (value === undefined) {
			return <></>;
		}
		if (!isInline) {
			return (
				<label
					className={`block tracking-wide text-xs font-bold ${
						disabled ? 'text-neutral-300' : 'text-neutral-700'
					}`}
					htmlFor={id}
				>
					<span
						className={`relative top-2 left-2 bg-white ${
							label.length === 0 ? '' : 'px-1'
						} rounded-md z-10 text-[10px]`}
					>
						{label}
					</span>
					<div className="relative">
						<button
							id={id}
							type="button"
							onClick={onMenuClick}
							className={`block appearance-none w-full transition-[background,box-shadow,border] border border-${
								hasError ? 'red' : 'neutral'
							}-300 py-2.5 px-3 pr-8 rounded-md focus:outline-none focus:bg-white focus:border-neutral-400 text-left focus:shadow-lg flex items-center gap-1`}
						>
							{value.iconSrc && (
								<img src={value.iconSrc} alt={`${value.text} logo`} className="w-3 h-3" />
							)}
							<span>{value.text}</span>
							{value.textComponent}
						</button>
						{disabled ? (
							<></>
						) : (
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						)}
					</div>
				</label>
			);
		}
		return (
			<div className="relative flex justify-between">
				<button
					id={id}
					type="button"
					onClick={onMenuClick}
					className="flex appearance-none w-full transition-colors text-neutral-700 leading-tight hover:text-neutral-500 active:text-neutral-500 focus:outline-none text-left truncate"
				>
					{value.text}
					{value.textComponent}
					{disabled ? (
						<></>
					) : (
						<div className="pointer-events-none inset-y-0 flex items-center px-2 text-neutral-700">
							<svg
								className="fill-current h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
							</svg>
						</div>
					)}
				</button>
			</div>
		);
	};

	return (
		<div className="w-full h-fit min-w-fit" ref={ref}>
			<>{renderSelect(inline)}</>
			{disabled ? <></> : renderSelectDropdown(isComponentVisible, selectItems)}
		</div>
	);
};

export const arrayToFormSelectValues = (
	arr: any[] | undefined,
	indexAsId?: boolean,
): FormSelectValue[] => {
	if (arr === undefined || arr.length === 0) {
		return [];
	}
	return arr.map((value, index): FormSelectValue => {
		return {
			id: indexAsId ? index.toString() : value.toString().toLocaleLowerCase(),
			text: value.toString(),
		};
	});
};

export const valueToFromSelectValue = (val: any): FormSelectValue => {
	return { id: val.toString().toLocaleLowerCase(), text: val.toString() };
};

export const findFormSelectValue = (key: string, options: FormSelectValue[]) => {
	if (options.length === 0) {
		return { id: '', text: '' };
	}
	if (key === undefined) {
		return options[0];
	}
	const result = options.find((x) => x.id.toLocaleLowerCase() === key.toLocaleLowerCase());
	return result === undefined ? options[0] : result;
};

export const isOptionEqual = (a: FormSelectValue, b: FormSelectValue) => {
	return a.id.toLocaleLowerCase() === b.id.toLocaleLowerCase();
};
