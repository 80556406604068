import { NoPermission, SectionHeader } from 'components/Common';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { WORKSPACE_ROLE } from 'utils';
import { UsageHistory } from './components/UsageHistory';
import { UsageSummary } from './components/UsageSummary';

export const UsagePage = () => {
	const { userRole } = useWorkspaceData();

	if (userRole !== WORKSPACE_ROLE.OWNER) {
		return (
			<ContentLayout title="Usage" description="View current usage" width="max-w-4xl">
				<NoPermission />
			</ContentLayout>
		);
	}

	return (
		<ContentLayout title="Usage" description="View current usage" width="max-w-4xl">
			<SectionHeader text="Current Cycle" />
			<div className="mt-3">
				<UsageSummary />
			</div>
			<br />
			<SectionHeader text="Usage History" />
			<UsageHistory />
		</ContentLayout>
	);
};
