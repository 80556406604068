import { Input } from '@dropbaseio/components';
import { Button } from 'components/Button';
import { SectionHeader } from 'components/Common';
import { InfoCalloutIntegration } from 'components/Common/InfoCallout';
import { Tooltip } from 'components/Common/Tooltip';
import { FormSelect, FormSelectValue } from 'components/Form';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ALL_SOURCES, DATABASE_SOURCES, REST_SOURCES, SourceMap } from './constants';
import { DatabaseFormProps, FieldSchema, SourcePayload } from './types';

interface FormData {
	name: string;
	description: string;
	type: string;
	creds: Record<string, string>;
}

export const DatabaseForm = ({
	source,
	workspaceId,
	disabled,
	deleteIsInRequest,
	submitIsInRequest,
	testIsInRequest,
	onSubmitDataHandler,
	onDeleteHandler,
	onTestHandler,
}: DatabaseFormProps) => {
	const sourceTypesForm: FormSelectValue[] = [
		{ id: '', text: 'Databases', isBreakpoint: true },
		...DATABASE_SOURCES,
		{ id: '', text: 'REST APIs', isBreakpoint: true },
		...REST_SOURCES,
	];

	const methods = useForm<FormData>({
		shouldUnregister: false,
		defaultValues: {
			type: source.type.length === 0 ? ALL_SOURCES[0].id : source.type,
		},
	});

	const {
		control,
		handleSubmit,
		watch,
		formState: { isValid },
		reset,
		setValue,
		getValues,
	} = methods;
	const typeId = watch('type');

	useEffect(() => {
		reset({
			name: source.name,
			description: source.description,
			type: source.type || ALL_SOURCES[0].id,
			creds: source.creds,
		});
		const newCreds = { ...source.creds };
		SourceMap[source.type]?.formSchema.forEach((field: FieldSchema) => {
			newCreds[field.id] = source.creds[field.id] || field.defaultValue || '';
		});

		setValue('creds', newCreds, {
			shouldDirty: false,
		});
	}, [reset, setValue, source]);

	useEffect(() => {
		if (source.id) {
			return;
		}
		SourceMap[typeId]?.formSchema.forEach((field: FieldSchema) => {
			setValue(`creds.${field.id}`, field.defaultValue || '');
		});
	}, [typeId, getValues, setValue, source.id]);

	const disableSubmitButtons = disabled || !isValid;

	const constructSourcePayload = () => {
		const creds = methods.getValues()?.creds || {};

		const credsPayload = SourceMap[typeId]?.formSchema.reduce((agg, field: FieldSchema) => {
			return {
				...agg,
				[field.id]: creds[field.id] || '',
			};
		}, {});

		const payload: SourcePayload = {
			...methods.getValues(),
			creds: credsPayload,
			workspace_id: workspaceId,
		};
		return payload;
	};

	const handleSubmitOnClick = async () => {
		const payload = constructSourcePayload();
		onSubmitDataHandler(source.id, payload);
	};

	const handleTestOnClick = async () => {
		const { creds, type } = constructSourcePayload();
		onTestHandler(type.toLowerCase(), creds);
	};

	return (
		<form className="w-full flex flex-col gap-1" onSubmit={handleSubmit(handleSubmitOnClick)}>
			<FormProvider {...methods}>
				<SectionHeader
					text={source.id?.length === 0 ? 'Add a Data Source' : `Editing ${source.name}`}
				/>
				<div className="flex flex-col relative -top-5">
					<div className="flex gap-2">
						<Controller
							name="name"
							control={control}
							rules={{ required: 'Name is required' }}
							render={({ field: { onChange, value, name: idName } }) => (
								<Input
									id={idName}
									label="Nickname"
									placeholder="My Database"
									value={value}
									onChange={(event) => onChange(event.target.value)}
									disabled={disabled}
								/>
							)}
						/>
						<Controller
							name="type"
							control={control}
							render={({ field: { onChange, value: newTypeId, name: idName } }) => (
								<FormSelect
									id={idName}
									label="Source Type"
									selectItems={sourceTypesForm}
									value={SourceMap[newTypeId]}
									onChangeHandler={(event) => onChange(event.id)}
									disabled={disabled}
								/>
							)}
						/>
					</div>
					<Controller
						name="description"
						control={control}
						render={({ field: { onChange, value, name: idName } }) => (
							<Input
								id={idName}
								label="Description"
								placeholder="Customer information"
								value={value}
								onChange={(event) => onChange(event.target.value)}
								disabled={disabled}
							/>
						)}
					/>
					<div className="relative top-4">
						<SectionHeader text="Credentials" />
					</div>

					{SourceMap[typeId]?.formSchema?.map(
						({ id, inputType, label, required = true, placeholder = '', defaultValue }) => (
							<Controller
								name={`creds.${id}`}
								control={control}
								rules={{ required: required ? `${label} is required` : false }}
								key={id}
								render={({ field: { onChange, value } }) => (
									<Input
										id={`database-${id}`}
										type={inputType}
										label={label}
										placeholder={placeholder}
										value={value}
										onChange={(event) => onChange(event.target.value)}
										disabled={disabled}
									/>
								)}
							/>
						),
					)}

					<InfoCalloutIntegration type={SourceMap[typeId]} />
				</div>
				<div className="flex justify-evenly gap-2 mt-4">
					<Tooltip label={disabled || source.id.length === 0 ? 'Database is not saved' : undefined}>
						<Button
							onClick={onDeleteHandler}
							disabled={disabled || source.id.length === 0}
							loading={deleteIsInRequest}
							color="red"
							variant="outline"
						>
							Delete
						</Button>
					</Tooltip>
					<Tooltip label={disableSubmitButtons ? 'Credentials are incomplete' : undefined}>
						<Button
							onClick={handleTestOnClick}
							disabled={disableSubmitButtons}
							loading={testIsInRequest}
						>
							Test
						</Button>
					</Tooltip>
					<Tooltip label={disableSubmitButtons ? 'Form is incomplete' : undefined}>
						<Button
							type="submit"
							disabled={disableSubmitButtons}
							loading={submitIsInRequest}
							className="w-full"
						>
							Save
						</Button>
					</Tooltip>
				</div>
			</FormProvider>
		</form>
	);
};
