import { LoadingSpinner } from 'components/Common';
import { PromptLayout } from 'components/Layout';
import { useAuthUserData } from 'features/auth';
import { PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isUUID } from 'utils';
import { useUserWorkspaces } from './api/userWorkspaces';

import { activeWorkspaceAtom } from './atoms';

export const WorkspaceContainer = ({ children }: PropsWithChildren) => {
	const [activeWorkspaceId, setActiveWorkspaceId] = useAtom(activeWorkspaceAtom);

	const { pathname } = useLocation();
	const potentialWorkspaceId = pathname.split('/')?.[1];
	const featureURL = pathname.split('/')?.[2];

	const currentWorkspaceId = isUUID(potentialWorkspaceId)
		? potentialWorkspaceId
		: activeWorkspaceId;

	const { userId } = useAuthUserData();

	const { workspaces, isLoading: isLoadingWorkspaces } = useUserWorkspaces({
		userId,
	});

	const currentWorkspace = workspaces.find(
		(workspace) => workspace.workspace_id === currentWorkspaceId,
	);
	const isCurrentWorkspaceNotValid = !currentWorkspace;

	useEffect(() => {
		if (currentWorkspaceId) {
			setActiveWorkspaceId(currentWorkspaceId);
		}
	}, [currentWorkspaceId, setActiveWorkspaceId]);

	if (isLoadingWorkspaces) {
		return (
			<PromptLayout>
				<div className="relative w-80">
					<LoadingSpinner>
						<div className="text-sm font-bold text-neutral-700 text-center p-4">
							Retrieving your workspaces...
						</div>
					</LoadingSpinner>
				</div>
			</PromptLayout>
		);
	}

	if (isCurrentWorkspaceNotValid || workspaces.length === 0) {
		if (pathname !== '/workspaces' && pathname !== '/account') {
			return <Navigate to="/workspaces" />;
		}
	}

	if (pathname.replaceAll('/', '') === 'workspaces' || pathname.replaceAll('/', '') === 'account') {
		return <>{children}</>;
	}

	if (pathname === '/' || !pathname.includes(currentWorkspaceId || '')) {
		return <Navigate to={`/${currentWorkspaceId}/${featureURL || ''}`} />;
	}

	return <>{children}</>;
};
