import { axios, setAuthorizationHeader } from 'libs/axios';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useQuery } from 'react-query';
import { ValidateAuthResponse } from '../types';

export const validateAuthAccess = async (jwtToken: string): Promise<ValidateAuthResponse> => {
	setAuthorizationHeader(jwtToken);
	const { data } = await axios.post('/user/auth/validate?type=JWT');
	return data;
};

export const AUTH_VALIDATION_RESULT_KEY = 'auth_validation';

type QueryFnType = typeof validateAuthAccess;

type UseValidateAuthAccessOptions = {
	jwtToken: string;
	config?: QueryConfig<QueryFnType>;
};

export const useValidateAuthAccess = ({ jwtToken, config }: UseValidateAuthAccessOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: AUTH_VALIDATION_RESULT_KEY,
		queryFn: () => validateAuthAccess(jwtToken),
	});
	return {
		isValid: data?.valid,
		...rest,
	};
};
