import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { axios } from 'libs/axios';
import { Source } from '../types';

export const getWorkspaceSources = async (workspace_id: string): Promise<Source[]> => {
	const { data } = await axios.get<Source[]>(`/source/workspace/${workspace_id}`);
	return data;
};

export const ALL_SOURCES_QUERY_KEY = 'db-workspace-sources';

type QueryFnType = typeof getWorkspaceSources;

type UseWorkspaceSourcesOptions = {
	workspaceId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useWorkspaceSources = ({ workspaceId, config }: UseWorkspaceSourcesOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: [ALL_SOURCES_QUERY_KEY, workspaceId],
		queryFn: () => getWorkspaceSources(workspaceId),
		enabled: !!workspaceId,
	});

	return {
		sources: data || [],
		...rest,
	};
};
