import { InfoIcon } from 'assets';
import { FormSelectValue } from 'components/Form';
import { HTMLProps, ReactNode } from 'react';

const CASTODIA_IP = '54.177.198.56';
const INLO_CSS_CLASS = 'flex text-xs p-2 bg-blue-100 mt-4 gap-2 rounded-md items-center';
const DATABASE_TYPES = ['postgres', 'snowflake', 'mysql', 'maria', 'oracle', 'mssql', 'redshift'];

export const InfoCallout = ({
	children,
	...props
}: {
	children: ReactNode;
} & HTMLProps<HTMLDivElement>) => {
	return (
		<div {...props} className={`${INLO_CSS_CLASS} ${props.className ?? ''}`}>
			<InfoIcon />
			<p>{children}</p>
		</div>
	);
};

export const InfoCalloutIntegration = ({ type }: { type: FormSelectValue }) => {
	const docsLink = (
		<>
			To learn more about integrating {type.text} with Castodia, click{' '}
			<a
				href={`https://docs.castodia.com/integrations/${type.id}`}
				target="_blank"
				rel="noreferrer"
				className="underline underline-offset-1"
			>
				here.
			</a>
		</>
	);
	return (
		<InfoCallout>
			{DATABASE_TYPES.includes(type.id) ? (
				<>
					Ensure that the IP <b>{CASTODIA_IP}</b> is whitelisted on your database.
					<br />
				</>
			) : null}
			{docsLink}
		</InfoCallout>
	);
};
