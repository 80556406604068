import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from 'components/Common';
import { PromptLayout } from 'components/Layout';
import { AuthMenu } from './components/AuthMenu';
import { handleGoogleLogin, initGoogleLogin } from './api';
import { AuthProviderContext } from './AuthProviderContext';
import { AuthProviderContextType } from './types';

export const AuthProvider = ({ children }: PropsWithChildren) => {
	const [userJWT, setUserJWT] = useState('');
	const [userId, setUserId] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const existingJWT = document.cookie
			.split('; ')
			.find((row) => row.startsWith('castodiaUserCookie='))
			?.split('=')[1];

		// console.log(existingJWT);
		if (existingJWT !== undefined) {
			setIsLoading(true);
			setUserJWT(existingJWT);
			handleGoogleLogin(existingJWT, setUserId);
			return;
		}

		initGoogleLogin(
			async (response: any) => {
				setIsLoading(true);
				setUserJWT(response.credential);
				const success = await handleGoogleLogin(response.credential, setUserId);
				setIsLoading(false);
				if (success) {
					document.cookie = `castodiaUserCookie=${response.credential}`;
				}
			},
			() => {
				setIsLoading(true);
			},
		);
	}, []);

	const contextValue = useMemo((): AuthProviderContextType => {
		return {
			userJWT,
			userId,
			clearData: () => {
				setUserJWT('');
				setUserId('');
				setIsLoading(false);
				document.cookie = `castodiaUserCookie=; Max-Age=-99999999;`;
				window.location.reload();
			},
		};
	}, [userJWT, userId]);

	if (userId.length === 0) {
		return (
			<>
				{isLoading ? (
					<PromptLayout>
						<div className="relative w-80">
							<LoadingSpinner>
								<div className="text-sm font-bold text-neutral-700 text-center p-4">
									Signing in...
								</div>
							</LoadingSpinner>
						</div>
					</PromptLayout>
				) : (
					<>
						<AuthMenu />
					</>
				)}
			</>
		);
	}
	// console.log(contextValue);

	return (
		<AuthProviderContext.Provider value={contextValue}>{children}</AuthProviderContext.Provider>
	);
};
