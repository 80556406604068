import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { PENDING_INVITE_QUERY_KEY } from './pendingInvites';

export const removeInvite = (inviteId: string) => {
	return axios.delete(`/invite/${inviteId}`);
};

type UseInviteOptions = {
	config?: MutationConfig<typeof removeInvite>;
};

export const useRemoveInvite = ({ config }: UseInviteOptions = {}) => {
	return useMutation({
		...config,
		onSuccess: (data, variables, context) => {
			if (config?.onSuccess) {
				config?.onSuccess(data, variables, context);
			}

			queryClient.invalidateQueries(PENDING_INVITE_QUERY_KEY);
			// console.log('invalidated');
		},
		mutationFn: removeInvite,
	});
};
