import { axios, setAuthorizationHeader } from 'libs/axios';

const registerUser = async (idHandler: (id: string) => void): Promise<boolean> => {
	try {
		const response = await axios.post('/user/register?type=JWT');
		// console.log(`register ${response.data}`);
		idHandler(response.data.id);
		return true;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const handleGoogleLogin = async (
	credential: string,
	idHandler: (id: string) => void,
): Promise<boolean> => {
	// console.log(credential);
	setAuthorizationHeader(credential);

	// try login to check if registered
	try {
		const response = await axios.post('/user/login?type=JWT');
		// console.log(response.data);
		idHandler(response.data.id);
		return true;
	} catch (error: any) {
		// register if not logged in
		if (error?.response?.status === 404) {
			return registerUser(idHandler);
		}
	}

	return false;
};

// for login in
export const initGoogleLogin = (
	loginHandler: (response: any) => void,
	clickListener: () => void,
) => {
	// console.log(process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID);
	// @ts-expect-error error: google not found
	google.accounts.id.initialize({
		client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
		auto_select: false,
		callback: loginHandler,
		data_type: 'icon',
	});
	// @ts-expect-error error: google not found
	google.accounts.id.renderButton(document.getElementById('loginWithGoogleDiv'), {
		theme: 'outline',
		size: 'large',
		click_listener: clickListener,
	});

	// @ts-expect-error error: google not found
	google.accounts.id.prompt();
};

// 	// change to login endpoint once its added
// 	await fetch(`${ENDPOINT_URL}/login`, {
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 		},
// 		body: JSON.stringify({
// 			type: JWT_TYPE,
// 			value: credential,
// 		}),
// 	})
// 		.then((res) => {
// 			if (res.ok) {
// 				return res.text();
// 			}
// 			return res.text().then((text) => {
// 				throw new Error(JSON.parse(text).detail);
// 			});
// 		})
// 		.then((data) => {
// 			isLoggedIn = JSON.parse(data);
// 			if (isLoggedIn) {
// 				console.log(`successfully logged in: ${data}`);
// 				setIsSignedIn(true);
// 			}
// 		})
// 		.catch((error) => {
// 			console.log(error.message);
// 			setIsSignedIn(false);
// 		});
// 	return isLoggedIn;
// };

// const registerUser = async (credential) => {
// 	await fetch(`${ENDPOINT_URL}/register`, {
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 		},
// 		body: JSON.stringify({
// 			type: JWT_TYPE,
// 			value: credential,
// 		}),
// 	})
// 		.then((res) => {
// 			if (res.ok) {
// 				return res.text();
// 			}
// 			return res.text().then((text) => {
// 				throw new Error(JSON.parse(text).detail);
// 			});
// 		})
// 		.then((data) => {
// 			console.log(`successfully registered: ${data}`);
// 			setIsSignedIn(true);
// 		})
// 		.catch((error) => {
// 			console.log(error.message);
// 			setIsSignedIn(false);
// 		});
// 	console.log('finished');
// };

export const handleGoogleLoginResponse = (response: any) => {
	console.log(response);
	// setJWT(response.credential);
	// loginUser(response.credential).then((isLoggedIn) => {
	// 	if (!isLoggedIn) {
	// 		registerUser(response.credential);
	// 	}
	// });
};

// const handleGoogleSignOut = () => {
// 	google.accounts.id.disableAutoSelect();
// 	setIsSignedIn(false);
// };

// const validateAuthAccess = () => {
// 	fetch(`${ENDPOINT_URL}/auth/validate`, {
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 		},
// 		body: JSON.stringify({
// 			type: JWT_TYPE,
// 			value: jwt,
// 		}),
// 	})
// 		.then((res) => res.json())
// 		.then((data) => {
// 			if (data.valid) {
// 				console.log('successfully authorized');
// 				setIsAuthed(true);
// 			} else {
// 				setIsAuthed(false);
// 				setAuthMessage(`missing permissions on \n${data.missing_scopes}\n please re authorize`);
// 			}
// 		});
// };

const handleGoogleAuthResponse = async (response: any) => {
	console.log('');
	// console.log(response);
	// await fetch(`${ENDPOINT_URL}/auth`, {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json',
	// 	},
	// 	body: JSON.stringify({
	// 		type: 'CODE',
	// 		value: response.code,
	// 	}),
	// })
	// 	.then((res) => {
	// 		if (res.ok) {
	// 			validateAuthAccess();
	// 		} else {
	// 			return res.text().then((text) => {
	// 				throw new Error(JSON.parse(text).detail);
	// 			});
	// 		}
	// 	})
	// 	.catch((error) => {
	// 		console.log(`failed to authorize\n${error}`);
	// 		setIsAuthed(false);
	// 	});
};

// for authorization
// export const client = google.accounts.oauth2.initCodeClient({
// 	client_id: process.env.GOOGLE_OAUTH_CLIENT_ID,
// 	scope: 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive',
// 	access_type: 'offline',
// 	ux_mode: 'popup',
// 	callback: handleGoogleAuthResponse,
// });
