import { Controller, useFormContext } from 'react-hook-form';
import { FormInputNew } from 'components/Form';
import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { Button } from 'components/Button';

export const Name = () => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<>
			<Controller
				control={control}
				name="name"
				rules={{
					required: 'Missing workspace name',
				}}
				render={({ field: { onChange, value } }) => {
					return (
						<FormInputNew
							id="workspace-name-input"
							className={errors.name ? 'border-red-300' : ''}
							label="Workspace Name"
							placeholder="my workspace name"
							value={value}
							onChange={onChange}
						/>
					);
				}}
			/>
			<FormErrorMessage name="name" />
		</>
	);
};
