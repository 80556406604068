import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { UserWorkspaceInvite } from '../types';

export const getUserWorkspaceInvites = async (userId: string): Promise<UserWorkspaceInvite[]> => {
	const { data } = await axios.get(`/invite/user/${userId}`);
	return data;
};

export const USER_WORKSPACE_INVITES_KEY = 'user-workspace-invites';

type QueryFnType = typeof getUserWorkspaceInvites;

type UseGetUserWorkspaceInvitesOptions = {
	userId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useUserWorkspaceInvites = ({ userId, config }: UseGetUserWorkspaceInvitesOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: USER_WORKSPACE_INVITES_KEY,
		queryFn: () => getUserWorkspaceInvites(userId),
	});

	return {
		...rest,
		invites: data || [],
	};
};
