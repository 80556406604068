import {
	TOAST_NOTIFICATION_FAILURE_TYPE,
	TOAST_NOTIFICATION_SUCCESS_TYPE,
	TOAST_NOTIFICATION_WARNING_TYPE,
} from 'utils';
import { ToastNotificationItemProps } from '../types';

export const ToastNotificationItem = ({ children, type }: ToastNotificationItemProps) => {
	const MESSAGE_TYPE_COLOR = 'bg-neutral-700 text-neutral-100';
	const SUCCESS_TYPE_COLOR = 'bg-blue-500 text-neutral-50';
	const FAILURE_TYPE_COLOR = 'bg-red-500 text-neutral-50';
	const WARNING_TYPE_COLOR = 'bg-yellow-500 text-neutral-50';

	const getTypeColor = () => {
		switch (type) {
			case TOAST_NOTIFICATION_FAILURE_TYPE:
				return FAILURE_TYPE_COLOR;
			case TOAST_NOTIFICATION_WARNING_TYPE:
				return WARNING_TYPE_COLOR;
			case TOAST_NOTIFICATION_SUCCESS_TYPE:
				return SUCCESS_TYPE_COLOR;
			default:
				return MESSAGE_TYPE_COLOR;
		}
	};

	const getTypeText = () => {
		switch (type) {
			case TOAST_NOTIFICATION_FAILURE_TYPE:
				return 'Error';
			case TOAST_NOTIFICATION_WARNING_TYPE:
				return 'Warning';
			case TOAST_NOTIFICATION_SUCCESS_TYPE:
				return 'Success';
			default:
				return 'Message';
		}
	};

	return (
		<div
			className={`w-72 p-3 px-5 rounded-md text-sm my-2 ${getTypeColor()} shadow-neutral-400 shadow-md transition-all opacity-0 animate-fade max-h-80 overflow-y-auto break-words`}
		>
			<span className="font-bold text-base">{getTypeText()}</span>
			<div className="text-xs font-medium  whitespace-pre-line">{children}</div>
		</div>
	);
};
