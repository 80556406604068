import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { Role } from '../types';

export const getRoles = async (workspaceId: string): Promise<Role[]> => {
	const { data } = await axios.get(`/role/${workspaceId}`);
	return data;
};

export const ROLES_QUERY_KEY = 'member-workspace-roles';

type QueryFnType = typeof getRoles;

type UseRolesOptions = {
	workspaceId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useRoles = ({ workspaceId, config }: UseRolesOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: [ROLES_QUERY_KEY, workspaceId],
		queryFn: () => getRoles(workspaceId),
	});
	return {
		roles: data || [],
		...rest,
	};
};
