import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

export const FormErrorMessage = ({ name }: { name: string }) => {
	const {
		formState: { errors },
	} = useFormContext();
	return (
		<ErrorMessage
			name={name}
			errors={errors}
			render={({ message }) => <div className="text-red-500 text-sm">{message}</div>}
		/>
	);
};
