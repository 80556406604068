import { Button } from 'components/Button';
import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
	return (
		<div className="w-full h-full flex flex flex-col justify-center items-center">
			<div className="relative text-2xl font-semibold">Something went wrong.</div>
			<span className="text-sm text-neutral-700 text-center">
				We apologize for the inconvenience and have reported this issue to our team.
				<br />
				If this issue persists, please get in touch with us.
			</span>
			<div className="w-fit mt-4">
				<Button onClick={resetErrorBoundary}>Try again</Button>
			</div>
		</div>
	);
};
