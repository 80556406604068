import { ToastNofiticationContext } from 'features/notification/ToastNotificationContext';
import { useContext } from 'react';

export const useToastNotification = () => {
	const {
		notifications,
		notifySuccess,
		notifyFailure,
		notifyWarning,
		notifyMessage,
		removeNotification,
	} = useContext(ToastNofiticationContext);
	return {
		notifications,
		notifySuccess,
		notifyFailure,
		notifyWarning,
		notifyMessage,
		removeNotification,
	};
};
