import { arrayToFormSelectValues, FormSelectValue } from 'components/Form';
import { Plan } from './types';

export const BASIC: Plan = {
	name: 'basic',
	cost: '$50',
	autoRefreshes: '100 auto-refreshes',
	additionalCost: '$0.05 per additional run',
	users: '1 user',
	buttonText: 'Update to Basic',
};
export const PRO: Plan = {
	name: 'pro',
	cost: '$100',
	autoRefreshes: '5,000 auto-refreshes',
	additionalCost: '$0.03 per additional run',
	users: 'up to 3 users',
	buttonText: 'Update to Pro',
};
export const BUSINESS: Plan = {
	name: 'business',
	cost: '$400',
	autoRefreshes: '25,000 auto-refreshes',
	additionalCost: '$0.025 per additional run',
	users: 'up to 5 users',
	buttonText: 'Update to Business',
};
export const CONTACT = {
	name: 'custom',
	auto_refreshes: 'Custom # of ',
	user_limit: 'Custom # of ',
	button_text: 'Request a Call',
	show_on_UI: true,
};

export const PERIODS_SELECTABLES: FormSelectValue[] = [
	{ id: '1', text: '1 months' },
	{ id: '3', text: '3 months' },
	{ id: '6', text: '6 months' },
	{ id: '12', text: '12 months' },
];

export const INVOICE_DATES = ['1 month', '3 months', '6 months', '12 months'];
export const INVOICE_DATES_SELECTABLE = arrayToFormSelectValues(INVOICE_DATES);
