import { FormSelect, FormSelectValue } from 'components/Form';
import { useWorkspaceData } from 'features/workspace';
import { useEffect, useState } from 'react';
import { useUsage } from '../api/usage';
import { Usage } from '../types';
import { UsageHistoryItem } from './UsageHistoryItem';

export const UsageHistory = () => {
	const PERIODS_SELECTABLES: FormSelectValue[] = [
		{ id: '1', text: '1 month' },
		{ id: '3', text: '3 months' },
		{ id: '6', text: '6 months' },
		{ id: '12', text: '12 months' },
	];
	const [selectedPeriod, setSelectedPeriod] = useState<FormSelectValue>(PERIODS_SELECTABLES[0]);

	const { currentWorkspaceId } = useWorkspaceData();
	const { usages, refetch } = useUsage({
		workspaceId: currentWorkspaceId,
		period: selectedPeriod.id,
	});

	useEffect(() => {
		refetch();
		// eslint-disable-next-line
	}, [selectedPeriod]);

	const renderHistoryItem = (usageRecords: Usage[]) => {
		return usageRecords

			.sort((a, b) => {
				return new Date(b.day).getTime() - new Date(a.day).getTime();
			})
			.map((usage, index) => (
				<UsageHistoryItem key={`${index}-${usage.day}-usage`} usage={usage} />
			));
	};

	return (
		<div className="w-2/3">
			<FormSelect
				id="usage-history-selectable"
				label="Show Last"
				selectItems={PERIODS_SELECTABLES}
				value={selectedPeriod}
				onChangeHandler={setSelectedPeriod}
			/>
			{usages && usages.length > 0 ? (
				<>
					<div className="flex flex-row text-sm font-bold mt-2">
						<span className="w-1/2 ">{`Date (YYYY-MM-DD) - UTC${''}`}</span>
						<span className="w-1/2 ">{`Usage (auto-refreshes)${''}`}</span>
					</div>
					{renderHistoryItem(usages)}
				</>
			) : (
				<div className="text-center text-xs font-semibold text-neutral-700 p-4">
					No usage so far
				</div>
			)}
		</div>
	);
};
