import { axios } from 'libs/axios';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useQuery } from 'react-query';
import { User } from '../types';

export const userData = async (userId: string): Promise<User> => {
	const { data } = await axios.get(`/user/${userId}`);
	return data;
};

type QueryFnType = typeof userData;

type UseUserDataOptions = {
	userId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useUserData = ({ userId, config }: UseUserDataOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: userId,
		queryFn: () => userData(userId),
	});
	return {
		name: data?.name,
		id: data?.id,
		email: data?.email,
		trialEligible: data?.trial_eligible,
		...rest,
	};
};
