import { PropsWithChildren } from 'react';

export interface ContentLayoutProp extends PropsWithChildren {
	title: string;
	description: string;
	width?: string;
	className?: string;
}
export const ContentLayout = ({
	title,
	description,
	children,
	width,
	className,
}: ContentLayoutProp) => {
	return (
		<div className="m-16 w-8/12 flex flex-col mb-0">
			<div className="mb-4">
				<div className="relative text-4xl font-semibold">{title}</div>
				<span className="text-sm text-neutral-700 font-semibold">{description}</span>
			</div>
			<div
				className={`${width !== undefined ? width : 'max-w-xl'} h-full ${
					className?.includes('overflow') ? '' : 'overflow-y-auto overflow-x-hidden'
				} pb-6 pr-12 ${className || ''}`}
			>
				<>{children}</>
			</div>
		</div>
	);
};
