import { axios } from 'libs/axios';
import { useMutation, useQueryClient } from 'react-query';
import { MutationConfig } from 'libs/react-query';
import { WorkspacePaymentInfoKey } from './useWorkspacePaymentInfo';

export const updateWorkspacePaymentMethods = ({
	workspaceId,
	paymentMethodId,
}: {
	workspaceId: string;
	paymentMethodId: string;
}) => {
	return axios.post(`/workspace/payment_method/${workspaceId}`, {
		payment_method_id: paymentMethodId,
	});
};

export const useUpdateWorkspacePaymentMethods = (
	config?: MutationConfig<typeof updateWorkspacePaymentMethods>,
) => {
	const queryClient = useQueryClient();
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries(WorkspacePaymentInfoKey);
		},
		mutationFn: updateWorkspacePaymentMethods,
	});
};
