import { Navbar } from 'components/Navbar';
import { AccountPage } from 'features/account';
import { DatabasePage } from 'features/databases';
import { MembersPage } from 'features/members';
import { QueriesPage } from 'features/queries';
import { ScheduleLogsPage } from 'features/logs';
import { SchedulesPage } from 'features/schedules';
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { BillingPage } from 'features/billing';
import { UsagePage } from 'features/usage';
import { SettingPage } from 'features/settings';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'components/ErrorFallback';
import { PropsWithChildren } from 'react';
import { WorkspaceList } from 'features/workspace/WorkspaceList';

// The error boundary will reset for different keys (paths), so navigation is still accessible.
// Making this it's own component avoids rerendering routes when pathname changes.
const ErrorHandler = ({ children }: PropsWithChildren) => {
	const { pathname } = useLocation();
	return (
		<ErrorBoundary key={pathname} FallbackComponent={ErrorFallback}>
			{children}
		</ErrorBoundary>
	);
};

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path="" element={<WorkspaceList />} />
			<Route path="workspaces" element={<WorkspaceList />} />
			<Route
				path="account"
				element={
					<div className="flex h-screen w-full flex-row">
						<Navbar profile />
						<ErrorHandler>
							<AccountPage />
						</ErrorHandler>
					</div>
				}
			/>
			<Route
				path="/"
				element={
					<div className="flex h-screen w-full flex-row">
						<Navbar profile={false} />
						<ErrorHandler>
							<Outlet />
						</ErrorHandler>
					</div>
				}
			>
				<Route path=":workspaceId">
					<Route path="" element={<Navigate to="schedule" />} />
					<Route path="account" element={<AccountPage />} />
					<Route path="usage" element={<UsagePage />} />
					<Route path="source" element={<DatabasePage />} />
					<Route path="schedule" element={<SchedulesPage />} />
					<Route path="team" element={<MembersPage />} />
					<Route path="query" element={<QueriesPage />} />
					<Route path="billing" element={<BillingPage />} />
					<Route path="logs" element={<ScheduleLogsPage />} />
					<Route path="setting" element={<SettingPage />} />

					<Route path="*" element={<h1>404 page not defined</h1>} />
				</Route>
			</Route>
		</Routes>
	);
};
