import { atom } from 'jotai';
import { addCustomHeader } from 'libs/axios';

const storageKey = 'lastUsedWorkspace';

const attachWorkspaceIdToAxios = (id: string) => {
	addCustomHeader('X-workspace-id', id);
};

const workspaceIdAtom = atom<string | null>(localStorage.getItem(storageKey) || null);

export const removeStorageKey = () => {
	localStorage.removeItem(storageKey);
};

export const activeWorkspaceAtom = atom(
	(get) => get(workspaceIdAtom),
	(get, set, newStr: string) => {
		set(workspaceIdAtom, newStr);
		attachWorkspaceIdToAxios(newStr);
		if (newStr) {
			localStorage.setItem(storageKey, newStr || '');
		} else {
			removeStorageKey();
		}
	},
);
