import { useAuthUserData } from 'features/auth';
import { useUserWorkspaceInvites } from '../api/userWorkspaceInvites';

import { WorkspaceInviteItem } from './WorkspaceInviteItem';

export const NewWorkspaceInviteList = () => {
	const { userId } = useAuthUserData();
	const { invites, isLoading } = useUserWorkspaceInvites({ userId });

	if (isLoading) {
		return <div className="text-xs font-semibold text-neutral-400">Fetching invites...</div>;
	}

	if (invites.length === 0) {
		return <div className="text-xs font-semibold text-neutral-400">No pending invites found</div>;
	}

	return (
		<div className="flex flex-col gap-1">
			{invites.map((x) => (
				<WorkspaceInviteItem key={x.id} invite={x} />
			))}
		</div>
	);
};
