import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { Schedule } from '../types';

export const getWorkspaceSchedules = async (workspaceId: string) => {
	const { data } = await axios.get<Schedule[]>(`/schedule_v2/all/${workspaceId}`);
	return data;
};

export const WORKSPACE_USER_SCHEDULE_ID_KEY = 'user-workspace-schedule-ids';

type QueryFnType = typeof getWorkspaceSchedules;

type UseWorkspaceScheduleIdentifiersOptions = {
	workspaceId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useWorkspaceSchedules = ({
	workspaceId,
	config,
}: UseWorkspaceScheduleIdentifiersOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: [WORKSPACE_USER_SCHEDULE_ID_KEY, workspaceId],
		queryFn: () => getWorkspaceSchedules(workspaceId),
	});
	return {
		schedules: data || [],
		...rest,
	};
};
