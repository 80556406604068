import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { Query } from '../types';

export const getWorkspaceQuery = async (workspaceId: string) => {
	const { data } = await axios.get<Query[]>(`/query/workspace/${workspaceId}`);
	return data;
};

export const WORKSPACE_QUERY_ID_KEY = 'query-workspace-query-ids';

type QueryFnType = typeof getWorkspaceQuery;

type UseWorkspaceQueryOptions = {
	workspaceId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useWorkspaceQuery = ({ workspaceId, config }: UseWorkspaceQueryOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: [WORKSPACE_QUERY_ID_KEY, workspaceId],
		queryFn: () => getWorkspaceQuery(workspaceId),
	});
	return {
		workspaceQueries: data || [],
		...rest,
	};
};
