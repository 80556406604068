import { v4 } from 'uuid';
import { ToastNotification, ToastNotificationStackProps } from '../types';
import { ToastNotificationItem } from './ToastNotificationItem';

export const ToastNotificationStack = ({ toasts }: ToastNotificationStackProps) => {
	const renderToasts = (notifications: ToastNotification[]) => {
		return notifications.map((notification) => {
			return (
				<ToastNotificationItem key={notification.id} type={notification.type}>
					{notification.message}
				</ToastNotificationItem>
			);
		});
	};

	return <div className="p-2 px-4">{renderToasts(toasts)}</div>;
};
