import { useBillingInfo } from '../api/useBillingInfo';

export const InvoiceSummary = () => {
	const { upcomingInvoice } = useBillingInfo();
	const unixToDate = (unix_timestamp: number) => {
		const date = new Date(unix_timestamp * 1000);
		const dateStr = date.toLocaleDateString();
		return dateStr;
	};
	return (
		<div className="text-neutral-700 font-semibold flex flex-row justify-between w-2/3 gap-4">
			<div className="flex flex-col">
				<span className="text-sm">Next invoice</span>
				<span className="text-2xl font-bold text-neutral-900">{`$${upcomingInvoice?.total_fee}`}</span>
				<span className="text-sm">
					Billing date: {unixToDate(upcomingInvoice?.next_payment_attempt || 0)}
				</span>
			</div>
			{/* <div className="flex flex-col justify-center text-sm">
				<div className="flex flex-row gap-4">
					<div className="flex flex-col">
						<span>Flat Fee</span>
						<span>{`$${data?.upcoming_invoice.monthy_fee}`}</span>
					</div>
					<div className="flex flex-col justify-center">
						<span>+</span>
					</div>
					<div className="flex flex-col text-center">
						<span>Additional Usage</span>
						<span>{`$${data?.upcoming_invoice.usage_fee}`}</span>
					</div>
					<div className="flex flex-col justify-center">
						<span>=</span>
					</div>
					<div className="flex flex-col text-center">
						<span>Total</span>
						<span>{`$${data?.upcoming_invoice.total_fee}`}</span>
					</div>
				</div>
			</div> */}
		</div>
	);
};
