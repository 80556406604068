import { RefreshButton } from '@dropbaseio/components';
import { Button } from 'components/Button';
import { SectionHeader } from 'components/Common';
import { FormFieldSkeleton } from 'components/Form';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, RefreshCw } from 'react-feather';
import { useScheduleLogs } from './api/getLogs';

export const ScheduleLogsPage = () => {
	const OFFSET_VALUE = 100;
	const { currentWorkspaceId } = useWorkspaceData();
	const [offset, setOffset] = useState(0);
	const {
		logs: scheduleLogs,
		refetch: refetchScheduleLogs,
		isError: isErrorScheduleLogs,
		isFetching: isFetchingScheduleLogs,
	} = useScheduleLogs({
		workspaceId: currentWorkspaceId,
		offset,
	});

	const [olderDisabled, setOlderDisabled] = useState(false);

	useEffect(() => {
		if (scheduleLogs.length < OFFSET_VALUE) {
			setOlderDisabled(true);
		} else {
			setOlderDisabled(false);
		}
	}, [scheduleLogs]);

	const handleOlderButton = () => {
		setOffset((o) => {
			const prevPage = o - OFFSET_VALUE;
			if (prevPage < 0) {
				return 0;
			}
			return prevPage;
		});
	};

	const handleNewerButton = () => {
		setOffset((o) => o + OFFSET_VALUE);
	};

	const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	return (
		<ContentLayout title="Logs" description="History of your schedule runs" width="max-w-4xl">
			<div className="relative overflow-x-auto">
				<div className="flex w-full">
					<div className="w-2/3 ">
						<SectionHeader text="Schedule Logs" />
					</div>
					<div className="flex w-1/3 gap-2 pb-2 justify-end">
						<RefreshButton
							variant="outline"
							color="neutral"
							disabled={isFetchingScheduleLogs}
							onClick={() => {
								refetchScheduleLogs();
							}}
						/>
						<Button
							color="neutral"
							variant="outline"
							style={{ width: '20%' }}
							onClick={handleOlderButton}
							disabled={(offset === 0 && olderDisabled) || isFetchingScheduleLogs}
						>
							<ChevronLeft size={20} />
						</Button>
						<Button
							color="neutral"
							variant="outline"
							disabled={isFetchingScheduleLogs || olderDisabled}
							style={{ width: '20%' }}
							onClick={handleNewerButton}
						>
							<ChevronRight size={20} />
						</Button>
					</div>
				</div>
				{isFetchingScheduleLogs ? (
					<div className="flex flex-col gap-1">
						<FormFieldSkeleton />
						<FormFieldSkeleton />
						<FormFieldSkeleton />
					</div>
				) : (
					<>
						{scheduleLogs.length === 0 ? (
							<div className="font-semibold text-sm text-neutral-500">No logs found.</div>
						) : (
							<table className="w-full text-xs text-left text-gray-500">
								<thead className="border dark:border-gray-300">
									<tr>
										<th scope="col" className="px-4 py-3" style={{ width: '25%' }}>
											Schedule
										</th>
										<th scope="col" className="px-4 py-3" style={{ width: '15%' }}>
											Run time
										</th>
										<th scope="col" className="px-4 py-3" style={{ width: '5%' }}>
											Status
										</th>
										<th scope="col" className="px-4 py-3" style={{ width: '55%' }}>
											Message
										</th>
									</tr>
								</thead>
								<tbody>
									{scheduleLogs.map((log) => {
										const lastRunISOTimestamp = `${log.date.replace(' ', 'T')}Z`;
										const date = new Date(lastRunISOTimestamp);
										const dateStr = date.toLocaleString(undefined, { timeZone: userTimezone });
										const badgeClass =
											log.status === '200'
												? 'text-[0.7rem] bg-green-400 text-white text-center px-2 py-1 rounded rounded-lg'
												: 'text-[0.7rem] bg-red-400 text-white  text-center px-2 py-1 rounded rounded-lg';

										return (
											<tr id={log.schedule_id} className="border dark:border-gray-300 font-medium">
												<th className="px-4 py-2">
													<div className="flex items-center gap-2">
														{log.name}
														{log.retry !== null && <RefreshCw className="h-3 flex-none" />}
													</div>
												</th>
												<td className="px-4 py-2">{dateStr}</td>
												<td className="px-4 py-2">
													<p className={badgeClass}>{log.status === '200' ? 'OK' : 'Error'}</p>
												</td>
												<td className="px-4 py-2">{log.message}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
					</>
				)}
			</div>
		</ContentLayout>
	);
};
