import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';

import { USER_WORKSPACES_KEY } from './userWorkspaces';

export const leaveWorkspace = ({
	userId,
	workspaceId,
}: {
	userId: string;
	workspaceId: string;
}) => {
	return axios.put(`/user/leave/${workspaceId}/${userId}`);
};

type UseLeaveWorkspaceOptions = {
	config?: MutationConfig<typeof leaveWorkspace>;
};

export const useLeaveWorkspace = ({ config }: UseLeaveWorkspaceOptions = {}) => {
	return useMutation({
		...config,
		onSuccess: (data, variables, context) => {
			if (config?.onSuccess) {
				config?.onSuccess(data, variables, context);
			}

			queryClient.invalidateQueries(USER_WORKSPACES_KEY);
		},
		mutationFn: leaveWorkspace,
	});
};
