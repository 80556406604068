import { NoPermission, SectionHeader } from 'components/Common';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { WORKSPACE_ROLE } from 'utils';
import { DeleteWorkspaceControl } from './components/DeleteWorkspaceControl';
import { TransferOwnership } from './components/TransferOwnership';
import { UpdateWorkspace } from './components/UpdateWorkspace';

export const SettingPage = () => {
	const { userRole } = useWorkspaceData();

	return (
		<ContentLayout title="Settings" description="Edit workspace settings">
			{userRole === WORKSPACE_ROLE.OWNER && (
				<>
					<SectionHeader text="Transfer Workspace Ownership" />
					<TransferOwnership />
				</>
			)}
			<br />
			<SectionHeader text="Workspace" />
			<UpdateWorkspace />
			<br />
			<SectionHeader text="Danger" className="text-red-500" />
			<DeleteWorkspaceControl />
		</ContentLayout>
	);
};
