import { LoadingSpinner } from 'components/Common';
import { useAuthUserData } from 'features/auth';
import { useToastNotification } from 'hooks/useToastNotification';
import { getErrorBody } from 'utils';
import { useAcceptInvite } from '../api/acceptInvite';
import { useRejectInvite } from '../api/rejectInvite';
import { UserWorkspaceInvite } from '../types';

export const WorkspaceInviteItem = ({ invite }: { invite: UserWorkspaceInvite }) => {
	const { userId } = useAuthUserData();
	const toast = useToastNotification();

	const acceptInviteQuery = useAcceptInvite({
		config: {
			onError(error, _, __) {
				toast.notifyFailure(`Failed to join workspace\n${getErrorBody(error)}`);
			},
		},
	});
	const rejectInviteQuery = useRejectInvite({
		config: {
			onError() {
				toast.notifyFailure('Failed to reject invite');
			},
		},
	});

	const parseDate = (date: string) => {
		const tIndex = date.indexOf('T');
		return date.substring(0, tIndex);
	};

	return (
		<div className="flex flex-row text-sm border rounded-md justify-between group hover:shadow-lg transition-[box-shadow,border] hover:border-neutral-400">
			<div className="p-4">
				<div>
					<span className="font-bold text-sm ">{invite.workspace_name}</span>
					<span className="font-semibold text-xs text-neutral-500"> as </span>
					<span className="font-bold">{invite.role}</span>
				</div>
				<span className="text-xs text-neutral-500 font-semibold">
					Invited you on {parseDate(invite.date)}
				</span>
			</div>
			<div className="flex flex-row text-neutral-500 pr-1">
				<button
					onClick={() => rejectInviteQuery.mutate({ inviteId: invite.id })}
					type="button"
					className="relative h-full w-10 flex flex-col justify-center transition-colors hover:text-red-400"
				>
					{rejectInviteQuery.isLoading ? (
						<LoadingSpinner />
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={2}
							stroke="currentColor"
							className="w-6 h-6 mx-auto"
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					)}
				</button>
				<button
					onClick={() => acceptInviteQuery.mutate({ inviteId: invite.id, userId })}
					type="button"
					className="relative h-full w-10 flex flex-col justify-center transition-colors hover:text-blue-400"
				>
					{acceptInviteQuery.isLoading ? (
						<LoadingSpinner />
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={2}
							stroke="currentColor"
							className="w-6 h-6 mx-auto"
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
						</svg>
					)}
				</button>
			</div>
		</div>
	);
};
