import { PropsWithChildren, useCallback, useState, createContext, useMemo, useEffect } from 'react';
import {
	TOAST_NOTIFICATION_SUCCESS_TYPE,
	TOAST_NOTIFICATION_WARNING_TYPE,
	TOAST_NOTIFICATION_FAILURE_TYPE,
	TOAST_NOTIFICATION_MESSAGE_TYPE,
} from 'utils';
import { v4 } from 'uuid';
import { ToastNotificationStack } from './components/ToastNotificationStack';
import { ToastNofiticationContext } from './ToastNotificationContext';
import { ToastNotification, ToastNotificationContextType } from './types';

export const NotificationProvider = ({ children }: PropsWithChildren) => {
	const SHOW_TIME = 5000;

	const [notifications, setNotifications] = useState<ToastNotification[]>([]);

	const removeNotification = useCallback(() => {
		setNotifications((notifs) => {
			const newNotifs = notifs.slice();
			newNotifs.shift();
			return newNotifs;
		});
	}, []);

	const removeNotificationDelayed = useCallback(async () => {
		await new Promise((r) => {
			setTimeout(() => {
				removeNotification();
			}, SHOW_TIME);
		});
	}, [removeNotification]);

	const addNotification = useCallback(
		async (newNotif: ToastNotification) => {
			setNotifications((notifs) => [...notifs, newNotif]);
			await removeNotificationDelayed();
		},
		[removeNotificationDelayed],
	);

	// console.log(notifications);
	const contextValue = useMemo((): ToastNotificationContextType => {
		return {
			notifications,
			notifySuccess: (message: string) =>
				addNotification({ id: v4(), type: TOAST_NOTIFICATION_SUCCESS_TYPE, message }),
			notifyFailure: (message: string) =>
				addNotification({ id: v4(), type: TOAST_NOTIFICATION_FAILURE_TYPE, message }),
			notifyWarning: (message: string) =>
				addNotification({ id: v4(), type: TOAST_NOTIFICATION_WARNING_TYPE, message }),
			notifyMessage: (message: string) =>
				addNotification({ id: v4(), type: TOAST_NOTIFICATION_MESSAGE_TYPE, message }),
			removeNotification: () => removeNotification(),
		};
	}, [notifications, addNotification, removeNotification]);

	return (
		<ToastNofiticationContext.Provider value={contextValue}>
			{children}
			<div className="absolute bottom-0 right-0">
				<ToastNotificationStack toasts={notifications} />
			</div>
		</ToastNofiticationContext.Provider>
	);
};
