import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { UpdateWorkspacePayload } from '../types';

export const updateWorkspace = ({ id, payload }: UpdateWorkspacePayload) => {
	return axios.put(`/workspace/${id}`, payload);
};

type UseUpdateWorkspaceOptions = {
	config?: MutationConfig<typeof updateWorkspace>;
};

export const useUpdateWorkspace = ({ config }: UseUpdateWorkspaceOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries();
		},
		mutationFn: updateWorkspace,
	});
};
