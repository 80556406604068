import { useEffect, useState } from 'react';
import { useToastNotification } from 'hooks/useToastNotification';
import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { Button } from 'components/Button';
import { FormInputNew } from 'components/Form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useWorkspaceData } from 'features/workspace';
import { useUpdateWorkspace } from '../api/updateWorkspace';

import { UpdateWorkspacePayload } from '../types';

export const UpdateWorkspace = () => {
	const toast = useToastNotification();
	const [isLoading, setIsLoading] = useState(false);

	const { currentWorkspace } = useWorkspaceData();

	const methods = useForm<any>({
		shouldUnregister: true,
		defaultValues: {
			name: currentWorkspace?.name,
			description: currentWorkspace?.description,
		},
	});
	const { control } = methods;
	const {
		formState: { errors },
	} = methods;

	const updateWorkspace = useUpdateWorkspace({
		config: {
			onMutate() {
				setIsLoading(true);
			},
			onSuccess() {
				toast.notifySuccess(`Successfully updated schedule`);
			},
			onError() {
				toast.notifyFailure(`Failed to update schedule`);
			},
			onSettled() {
				setIsLoading(false);
			},
		},
	});
	const handleSubmit = (workspaceDto: any) => {
		const updatePayload: UpdateWorkspacePayload = {
			id: currentWorkspace?.workspace_id || '',
			payload: workspaceDto,
		};
		updateWorkspace.mutate(updatePayload);
	};

	return (
		<div className="w-full">
			<form className="flex flex-col" onSubmit={methods.handleSubmit(handleSubmit)}>
				<FormProvider {...methods}>
					<div className="flex gap-1 flex-col">
						<Controller
							control={control}
							name="name"
							rules={{
								required: 'Name is required',
							}}
							render={({ field: { onChange, value, name } }) => {
								return (
									<div className="flex flex-col gap-1">
										<FormInputNew
											id={name}
											type="text"
											label="Name"
											color={!errors.name ? 'neutral' : 'red'}
											placeholder="name of my query"
											value={value}
											onChange={(event) => onChange(event.target.value)}
											disabled={isLoading}
										/>
										<FormErrorMessage name="name" />
									</div>
								);
							}}
						/>

						<Controller
							control={control}
							name="description"
							render={({ field: { onChange, value, name } }) => {
								return (
									<FormInputNew
										id={name}
										type="text"
										label="Description"
										placeholder="description of my query"
										value={value}
										onChange={(event) => onChange(event.target.value)}
										disabled={isLoading}
									/>
								);
							}}
						/>
					</div>
					<div className="flex justify-evenly mt-4 gap-2">
						<Button loading={isLoading} type="submit">
							Save
						</Button>
					</div>
				</FormProvider>
			</form>
		</div>
	);
};
