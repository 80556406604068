import { axios } from 'libs/axios';
import { ExtractFnReturnType, queryClient, QueryConfig } from 'libs/react-query';
import { useQuery } from 'react-query';
import { Usage } from '../types';

export const getUsage = async ({
	workspaceId,
	period,
}: {
	workspaceId: string;
	period: string;
}) => {
	const { data } = await axios.get<Usage[]>(`/usage/${workspaceId}?period=${period}`);
	return data;
};

type QueryFnType = typeof getUsage;

type UseUsageOptions = {
	workspaceId: string;
	period: string;
	config?: QueryConfig<QueryFnType>;
};

export const useUsage = ({ workspaceId, period, config }: UseUsageOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: [workspaceId, 'usage'],
		queryFn: () => getUsage({ workspaceId, period }),
	});
	return {
		usages: data,
		...rest,
	};
};
