import { useBillingInfo } from 'features/billing/api/useBillingInfo';
import { useWorkspaceSubscriptionPlans } from 'features/billing/api/useWorkspaceSubscriptionPlans';
import { useWorkspaceData } from 'features/workspace';

const dateStringOptions: Intl.DateTimeFormatOptions = {
	weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	second: 'numeric',
	timeZoneName: 'short',
	timeZone: 'UTC',
};
export const UsageSummary = () => {
	const { currentWorkspace } = useWorkspaceData();
	const { lastUsageSync, usageForCurrentCycle } = useBillingInfo();
	const { allPlans } = useWorkspaceSubscriptionPlans();

	const getPlanLimit = (plan: string | undefined) => {
		return allPlans?.find((p) => p.name.toLowerCase() === plan)?.auto_refreshes;
	};

	const lastSyncedDate = lastUsageSync
		? new Date(lastUsageSync).toLocaleString(undefined, dateStringOptions)
		: '';
	return (
		<div className="font-semibold text-sm text-neutral-700 border  border-neutral-300 rounded-md p-4 w-2/3 min-w-[20rem]">
			<div className="font-bold text-4xl text-neutral-900">
				{usageForCurrentCycle}{' '}
				<span className="text-xs text-neutral-700">auto-refreshes used for workspace</span>
				<br />
				<span className="text-xs text-neutral-400">{`Last synced: ${lastSyncedDate}`} </span>
			</div>
			<br />
			<div className="text-lg text-neutral-900 font-bold leading-tight capitalize">
				{currentWorkspace?.plan} Plan
			</div>
			<div className="text-xs">
				Total auto-refreshes in plan: {getPlanLimit(currentWorkspace?.plan)}
			</div>
		</div>
	);
};
