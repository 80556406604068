import { FormSelect, FormSelectValue } from 'components/Form';
import { useEffect, useState } from 'react';
import { useInvoiceHistory } from '../api/useInvoiceHistory';
import { PERIODS_SELECTABLES } from '../constants';
import { InvoiceHistoryItem } from './InvoiceHistoryItem';

export const InvoiceHistory = () => {
	const [selectedPeriod, setSelectedPeriod] = useState<FormSelectValue>(PERIODS_SELECTABLES[0]);

	const { previousInvoices, refetch } = useInvoiceHistory({ period: selectedPeriod });
	useEffect(() => {
		refetch();
	}, [selectedPeriod, refetch]);
	// const TEST_INVOICES: Invoice[] = [
	// 	{
	// 		id: 'abc',
	// 		invoiceNumber: 'CAS-000-111',
	// 		date: '2023-01-01',
	// 		amount: '$122',
	// 	},
	// 	{
	// 		id: 'def',''
	// 		invoiceNumber: 'CAS-000-112',
	// 		date: '2023-02-01',
	// 		amount: '$122',
	// 	},
	// ];

	const renderInvoices = (invoices: any) => {
		return invoices.map((invoice: any) => (
			<InvoiceHistoryItem key={invoice.closing_date} invoice={invoice} />
		));
	};

	return (
		<div className="w-2/3 mb-6 ">
			<FormSelect
				id="invoice-date-selectables"
				label="View previous invoices"
				selectItems={PERIODS_SELECTABLES}
				value={selectedPeriod}
				onChangeHandler={setSelectedPeriod}
			/>
			<div className="flex flex-row text-sm font-bold mt-2">
				<span className="w-1/3 text-left">Opening date</span>
				{/* <span className="w-1/3 text-left">Invoice Number</span> */}
				<span className="w-1/3 text-left">Amount due ($)</span>
				<span className="w-1/3 text-left">Amount paid ($)</span>
				<span className="w-1/3 text-left">Invoice PDF</span>
			</div>
			{renderInvoices(previousInvoices || [])}
		</div>
	);
};
