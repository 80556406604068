import { useAtom } from 'jotai';
import { useAuthUserData } from 'features/auth';
import { activeWorkspaceAtom } from '../atoms';
import { useUserWorkspaces } from '../api/userWorkspaces';

export const useWorkspaceData = () => {
	const [currentWorkspaceId, setCurrentWorkspaceId] = useAtom(activeWorkspaceAtom);
	const { userId } = useAuthUserData();
	const { workspaces } = useUserWorkspaces({
		userId,
	});

	const currentWorkspace = workspaces.find((w) => w.workspace_id === currentWorkspaceId);

	return {
		currentWorkspaceId: currentWorkspaceId || '',
		currentWorkspaceName: currentWorkspace?.name,
		userRole: currentWorkspace?.role || '',
		currentWorkspace,
		resetWorkspace: () => {
			setCurrentWorkspaceId('');
		},
	};
};
