import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { Source, SourcePayload } from '../types';
import { ALL_SOURCES_QUERY_KEY } from './getAllSource';

export const createSource = (payload: SourcePayload) => {
	return axios.post(`/source/`, payload);
};

type UseCreateSourceOptions = {
	config?: MutationConfig<typeof createSource>;
};

export const useCreateSource = ({ config }: UseCreateSourceOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(ALL_SOURCES_QUERY_KEY);
		},
		mutationFn: createSource,
	});
};
