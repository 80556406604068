import {
	Option,
	RefreshButton,
	Select,
	Skeleton,
	SortButton,
	Title,
	generateSortFieldList,
	useSort,
} from '@dropbaseio/components';
import { useAuthUserData } from 'features/auth';
import { useWorkspaceData } from 'features/workspace';
import { useState } from 'react';
import { useWorkspaceSchedules } from '../api/workspaceSchedules';
import { ScheduleListItem } from './ScheduleListItem';

const SCHEDULE_SORT_STYLES = generateSortFieldList([
	{ name: 'Name', field: 'name', type: 'string' },
	{ name: 'Created', field: '', type: 'ignoreReverse' },
	{ name: 'Edited', field: 'modified_date', type: 'string', displayType: 'number' },
	{ name: 'Active', field: 'enabled', type: 'boolean' },
]);

export const ScheduleList = ({ type = 'user' }: { type: 'user' | 'others' }) => {
	const { userId } = useAuthUserData();
	const { currentWorkspaceId } = useWorkspaceData();

	const {
		schedules: workspaceSchedules,
		refetch: refetchWorkspaceSchedules,
		isError: isErrorWorkspaceSchedules,
		isFetching: isFetchingWorkspaceSchedules,
	} = useWorkspaceSchedules({
		workspaceId: currentWorkspaceId,
	});

	const schedules = workspaceSchedules.filter((x) => {
		if (type === 'user') {
			return x.user_id === userId;
		}

		return x.user_id !== userId;
	});

	const [sortMode, setSortMode] = useState(
		SCHEDULE_SORT_STYLES.find((x) => x.id === 'edited-dsc')!,
	);
	const { sortedTarget: sortedSchedules } = useSort(sortMode, schedules);

	if (isErrorWorkspaceSchedules) {
		return (
			<div className="font-semibold text-sm text-neutral-500">
				Failed to retrive workspace schedules
			</div>
		);
	}

	return sortedSchedules.length === 0 && type !== 'user' ? (
		<></>
	) : (
		<div>
			<div className="flex flex-row justify-between mb-2">
				<div className="grow">
					<Title>{type === 'user' ? 'Mine' : 'Shared'}</Title>
				</div>
				<div className="flex flex-row gap-2">
					<Select
						value={sortMode.id}
						onChange={(val) => setSortMode(SCHEDULE_SORT_STYLES.find((x) => x.id === val)!)}
						buttonEntrypoint={SortButton}
						buttonProps={{ color: 'neutral', variant: 'outline', size: 'regular' }}
					>
						{SCHEDULE_SORT_STYLES.map((x) => (
							<Option key={x.id} value={x.id} className="font-medium text-sm">
								{x.text}
							</Option>
						))}
					</Select>
					<RefreshButton
						color="neutral"
						variant="outline"
						onClick={() => {
							refetchWorkspaceSchedules();
						}}
						className="mt-0 text-neutral-400"
					/>
				</div>
			</div>
			{isFetchingWorkspaceSchedules ? (
				<div className="flex flex-col gap-1">
					<Skeleton isLoading />
					<Skeleton isLoading />
					<Skeleton isLoading />
				</div>
			) : (
				<div className="flex flex-col gap-1">
					{sortedSchedules.length === 0 ? (
						// <InfoCallout>
						<div className="font-semibold text-sm text-neutral-500">
							No schedules found.
							<br />
							<br />
							Schedules can only be created in the{' '}
							<a
								href="https://workspace.google.com/marketplace/app/castodia_data_connector/240754477426"
								target="_blank"
								rel="noreferrer"
								className="underline text-blue-500"
							>
								Castodia Addon
							</a>
							. Once created, they can be partially edited via this dashboard.
						</div>
					) : (
						sortedSchedules.map((schedule) => {
							return <ScheduleListItem key={schedule.id} schedule={schedule} />;
						})
					)}
				</div>
			)}
		</div>
	);
};
