import { Usage } from '../types';

export const UsageHistoryItem = ({ usage }: { usage: Usage }) => {
	return (
		<div className="w-full flex flex-row text-sm text-neutral-700 font-semibold">
			<div className="w-1/2">{usage.day}</div>
			<div className="w-1/2">{usage.usage}</div>
		</div>
	);
};
