import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { WORKSPACE_QUERY_ID_KEY } from './getWorkspaceQuery';

export const deleteQuery = (queryId: string) => {
	return axios.delete(`/query/${queryId}`);
};

type UseDeleteQueryOptions = {
	config?: MutationConfig<typeof deleteQuery>;
};

export const useDeleteQuery = ({ config }: UseDeleteQueryOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(WORKSPACE_QUERY_ID_KEY);
		},
		mutationFn: deleteQuery,
	});
};
