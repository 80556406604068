export interface FormSelectItemProps {
	onSelect: () => void;
	text: string;
	isBreakpoint?: boolean;
	iconSrc?: string;
	textComponent?: React.ReactNode;
}

export const FormSelectDropdownItem = ({
	onSelect,
	text,
	isBreakpoint,
	iconSrc,
	textComponent,
}: FormSelectItemProps) => {
	const hoverClass = isBreakpoint ? '' : 'hover:bg-neutral-200';
	return (
		<li className={`transition-colors ${hoverClass}`}>
			{isBreakpoint ? (
				<div className="w-full h-full py-2 px-4 text-left">
					{text}
					{textComponent}
				</div>
			) : (
				<button
					type="button"
					onClick={() => onSelect()}
					className="flex items-center gap-1 w-full h-full py-2.5 px-4 text-left"
				>
					{iconSrc ? <img className="w-3 h-3 " src={iconSrc} alt={`${text} icon`} /> : <></>}
					<span>{text}</span>
					{textComponent}
				</button>
			)}
		</li>
	);
};
