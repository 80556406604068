import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { USER_WORKSPACE_INVITES_KEY } from './userWorkspaceInvites';
import { USER_WORKSPACES_KEY } from './userWorkspaces';

export const acceptInvite = ({ inviteId, userId }: { inviteId: string; userId: string }) => {
	return axios.post(`/invite/user/accept/${userId}/${inviteId}`);
};

type UseAcceptInviteOptions = {
	config?: MutationConfig<typeof acceptInvite>;
};

export const useAcceptInvite = ({ config }: UseAcceptInviteOptions = {}) => {
	return useMutation({
		...config,
		mutationFn: acceptInvite,
		onSuccess() {
			queryClient.invalidateQueries(USER_WORKSPACE_INVITES_KEY);
			queryClient.invalidateQueries(USER_WORKSPACES_KEY);
		},
	});
};
