import { Button } from 'components/Button';
import { Tooltip } from 'components/Common/Tooltip';
import { FormInputNew } from 'components/Form';
import { FullscreenModal } from 'features/modal';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { useDeleteWorkspace } from '../api/deleteWorkspace';

export const DeleteWorkspaceModal = () => {
	const toast = useToastNotification();
	const { currentWorkspaceId, currentWorkspaceName, resetWorkspace } = useWorkspaceData();
	const [confirmDeleteWorkspace, setConfirmDeleteWorkspace] = useState(false);
	const [deleteConfirmValue, setDeleteConfirmValue] = useState('');
	const deleteWorkspaceQuery = useDeleteWorkspace({
		config: {
			onSuccess() {
				resetWorkspace();
			},
			onError() {
				toast.notifyFailure('Failed to delete workspace');
			},
		},
	});
	const getDeleteConfirmPhrase = () => {
		return `delete ${currentWorkspaceName}`;
	};
	const handleDeleteWorkspace = () => {
		deleteWorkspaceQuery.mutate(currentWorkspaceId);
		setConfirmDeleteWorkspace(false);
	};
	return (
		<>
			<div className="w-full">
				<Button
					color="red"
					variant="outline"
					className="my-2"
					onClick={() => setConfirmDeleteWorkspace(true)}
					loading={deleteWorkspaceQuery.isLoading}
				>
					Delete workspace
				</Button>
			</div>
			{confirmDeleteWorkspace && (
				<FullscreenModal onClose={() => setConfirmDeleteWorkspace(false)}>
					<div className="font-medium text-sm text-neutral-700 flex flex-col gap-2">
						<h3 className="text-lg text-neutral-900 font-bold">
							Are you sure you want to delete this workspace?
						</h3>
						<p>
							Warning: All data sources, queries, and schedules will be deleted and cannot be
							recovered.
						</p>
						<p>
							Please type <strong>{getDeleteConfirmPhrase()}</strong> to confirm.
						</p>
					</div>
					<br />
					<div className="flex flex-col gap-2">
						<FormInputNew
							id="workspace-delete-input"
							type="text"
							label=""
							placeholder=""
							value={deleteConfirmValue}
							onChange={(event) => setDeleteConfirmValue(event.target.value)}
						/>
						<Tooltip
							label={
								deleteConfirmValue !== getDeleteConfirmPhrase() || deleteWorkspaceQuery.isLoading
									? 'Delete phrase mismatch'
									: undefined
							}
						>
							<Button
								color="red"
								variant="outline"
								className="mt-2"
								onClick={handleDeleteWorkspace}
								disabled={
									deleteConfirmValue !== getDeleteConfirmPhrase() || deleteWorkspaceQuery.isLoading
								}
								loading={deleteWorkspaceQuery.isLoading}
							>
								Delete workspace
							</Button>
						</Tooltip>
					</div>
				</FullscreenModal>
			)}
		</>
	);
};
