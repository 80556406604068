import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { Input } from '@dropbaseio/components';
import { Tooltip } from 'components/Common/Tooltip';
import { FormSelect } from 'components/Form';
import { useAuthUserData } from 'features/auth';
import { useWorkspaceSubscriptionPlans } from 'features/billing/api/useWorkspaceSubscriptionPlans';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { FormEvent, useState } from 'react';
import { useCreateInvite } from '../api/createInvite';
import { useRoles } from '../api/getRoles';
import { usePendingInvites } from '../api/pendingInvites';
import { InvitePayload, MemberInviteControlProps } from '../types';

export const MemberInviteControl = ({
	roleOptions,
	currentMemberCount,
	pendingInviteCount,
}: MemberInviteControlProps) => {
	const { userId } = useAuthUserData();
	const { currentWorkspaceId, currentWorkspace } = useWorkspaceData();
	const { roles } = useRoles({ workspaceId: currentWorkspaceId });
	const { pendingInvites } = usePendingInvites({ workspaceId: currentWorkspaceId });
	const toastNotification = useToastNotification();
	const { allPlans } = useWorkspaceSubscriptionPlans();
	const [email, setEmail] = useState('');
	const [role, setRole] = useState(roleOptions[0]);

	const membersWithoutBilling = roles.filter((roleObject) => roleObject.role !== 'billing');
	const pendingInvitesWithoutBilling = pendingInvites?.filter(
		(invite) => invite.role !== 'billing',
	);
	const totalNonBillingMembers = membersWithoutBilling.length + pendingInvitesWithoutBilling.length;

	const createInviteQuery = useCreateInvite({
		config: {
			onSuccess() {
				toastNotification.notifySuccess('Invite sent!');
			},
			onError(error, _, __) {
				const err: AxiosError = error as AxiosError;
				if (err.response?.status === 400) {
					toastNotification.notifyFailure(
						'Failed to send invite.\nInvite has already been sent for this email!',
					);
				} else {
					toastNotification.notifyFailure('Failed to send invite');
				}
			},
		},
	});

	const isEmailValid = (val: string) => {
		return String(val)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	};

	const hasReachedMemberCap = (members: number) => {
		if (role.id === 'billing') return false;
		const userLimit = allPlans?.find(
			(plan) => plan.name.toLowerCase() === currentWorkspace?.plan,
		)?.user_limit;
		return members >= userLimit;
	};

	const isAlreadyInWorkspace =
		pendingInvites.some((invite) => invite.email === email) ||
		roles.some((xRole) => xRole.email === email);

	const handleInviteOnClick = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!isEmailValid(email)) {
			toastNotification.notifyFailure('Please enter a valid email');
			return;
		}
		if (isAlreadyInWorkspace) {
			toastNotification.notifyFailure('Email is already in workspace.');
			return;
		}

		// console.log(currentMemberCount);
		if (hasReachedMemberCap(totalNonBillingMembers)) {
			toastNotification.notifyFailure(
				'This workspace has reached its user capacity. Please consider upgrading the workspace plan under Billing',
			);
			return;
		}

		const invitePayload: InvitePayload = {
			email,
			role: role.id.toLowerCase(),
			workspace_id: currentWorkspaceId,
			invited_by: userId,
		};
		await createInviteQuery.mutateAsync(invitePayload);
	};

	return (
		<div>
			<form onSubmit={handleInviteOnClick}>
				<div className="w-full flex flex-row gap-2">
					<div className="w-1/2">
						<Input
							id="member-invite-input"
							type="text"
							label="Email"
							placeholder="member@email.com"
							value={email}
							onChange={(event) => setEmail(event.target.value)}
							required
						/>
					</div>

					<div className="w-1/4">
						<FormSelect
							id="member-role-select"
							label="Role"
							selectItems={roleOptions}
							value={role}
							onChangeHandler={setRole}
						/>
					</div>

					<div className="w-1/4 mt-4">
						<Tooltip
							label={
								createInviteQuery.isLoading || !isEmailValid(email) ? 'Invalid email' : undefined
							}
						>
							<Button disabled={createInviteQuery.isLoading || !isEmailValid(email)} type="submit">
								Invite
							</Button>
						</Tooltip>
					</div>
				</div>
			</form>
		</div>
	);
};
