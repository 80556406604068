import { ButtonProps, Button } from 'components/Button';
import { RefreshCw } from 'react-feather';

export const FormRefreshButton = ({ ...props }: ButtonProps) => {
	const { className, ...rest } = props;
	return (
		<Button className="pl-2 pr-2 text-neutral-400 w-min" {...rest}>
			<RefreshCw size={20} />
		</Button>
	);
};
