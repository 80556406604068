import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { NoPermission } from 'components/Common';
import { FormFieldSkeleton } from 'components/Form';
import { ContentLayout } from 'components/Layout';
import { Modal } from 'features/modal';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getErrorBody } from 'utils';
import { DatabaseForm } from '../DatabaseForm';
import { useCreateSource } from '../api/createSource';
import { useDeleteSource } from '../api/deleteSource';
import { useWorkspaceSources } from '../api/getAllSource';
import { useTestSource } from '../api/testSource';
import { useUpdateSource } from '../api/updateSource';
import { Source, SourcePayload } from '../types';

const emptySource: Source = {
	id: '',
	name: '',
	description: '',
	type: '',
	creds: {
		host: '',
		username: '',
		password: '',
		database: '',
		port: '',
	},
	workspace_id: '',
	modified_date: '',
};

export const DatabaseFormContrainer = () => {
	const toastNotification = useToastNotification();
	const workspaceData = useWorkspaceData();

	const [searchParams, setSearchParams] = useSearchParams();
	const paramSourceId = searchParams.get('sourceId');
	const isCreateSource = paramSourceId === 'new';

	const [confirmDelete, setConfirmDelete] = useState(false);

	const {
		sources: workspaceSources,
		refetch: refetchWorkspaceSources,
		isError: isErrorWorkspaceSources,
		isFetching: isFetchingWorkspaceSources,
	} = useWorkspaceSources({
		workspaceId: workspaceData.currentWorkspaceId,
		config: {
			onError(err) {
				const error: AxiosError = err as AxiosError;
				if (error.response?.status !== 404) {
					toastNotification.notifyFailure('Failed to retrieve source');
				}
			},
		},
	});

	const selectedSource = workspaceSources.find((element) => element.id === paramSourceId);

	const createSourceQuery = useCreateSource({
		config: {
			onError() {
				toastNotification.notifyFailure('Failed to create new source');
			},
			onSuccess({ data }: any) {
				toastNotification.notifySuccess('Successfully created new source');
				setSearchParams({
					sourceId: data.id,
				});
			},
		},
	});

	const updateSourceQuery = useUpdateSource({
		config: {
			onError() {
				toastNotification.notifyFailure('Failed to update source');
			},
			onSuccess() {
				toastNotification.notifySuccess('Successfully updated source');
			},
		},
	});

	const deleteSourceQuery = useDeleteSource({
		config: {
			onError() {
				toastNotification.notifyFailure(
					'Failed to delete source. Are there any schedules or queries still using it?',
				);
			},
			onSuccess() {
				setSearchParams({
					sourceId: '',
				});
				setConfirmDelete(false);
				toastNotification.notifySuccess('Successfully deleted source');
			},
		},
	});

	const testSourceQuery = useTestSource({
		config: {
			onSuccess(resp, _, __) {
				toastNotification.notifySuccess(resp.data.message ?? 'Connection is valid');
			},
			onError(error, _, __) {
				toastNotification.notifyFailure(getErrorBody(error));
			},
		},
	});

	const handleDelete = () => {
		if (!isCreateSource && paramSourceId) {
			setConfirmDelete(false);
			deleteSourceQuery.mutate(paramSourceId);
		}
	};

	const handleOnTest = (type: string, creds: Record<string, string>) => {
		testSourceQuery.mutate({ type, workspaceId: workspaceData.currentWorkspaceId, creds });
	};

	const handleConfirmDelete = () => {
		setConfirmDelete(true);
	};

	const handleOnSubmitData = async (sourceId: string, payload: SourcePayload) => {
		if (payload.type === 'postgres') {
			try {
				await testSourceQuery.mutateAsync({
					type: payload.type,
					workspaceId: workspaceData.currentWorkspaceId,
					creds: payload.creds,
				});
			} catch (error) {
				toastNotification.notifyFailure(
					`Connection check failed. Are your credentials valid?\n${
						sourceId.length === 0 ? 'Create' : 'Update'
					} source aborted.`,
				);
				return;
			}
		}
		if (isCreateSource) {
			createSourceQuery.mutate(payload);
		} else {
			updateSourceQuery.mutate({ sourceId, payload });
		}
	};

	if (workspaceData.currentWorkspace?.role !== 'owner') {
		return (
			<ContentLayout
				title="Data Sources"
				description="Connect to databases, data warehouses, and API/SaaS Apps"
			>
				<NoPermission />
			</ContentLayout>
		);
	}

	if (!paramSourceId) {
		return null;
	}

	if (isFetchingWorkspaceSources) {
		return <FormFieldSkeleton />;
	}

	return (
		<div>
			<DatabaseForm
				source={isCreateSource ? emptySource : selectedSource || emptySource}
				workspaceId={workspaceData.currentWorkspaceId}
				onSubmitDataHandler={handleOnSubmitData}
				onDeleteHandler={handleConfirmDelete}
				onTestHandler={handleOnTest}
				disabled={
					deleteSourceQuery.isLoading ||
					updateSourceQuery.isLoading ||
					testSourceQuery.isLoading ||
					createSourceQuery.isLoading
				}
				submitIsInRequest={createSourceQuery.isLoading || updateSourceQuery.isLoading}
				testIsInRequest={testSourceQuery.isLoading}
				deleteIsInRequest={deleteSourceQuery.isLoading}
			/>

			{confirmDelete ? (
				<Modal className="p-8 w-1/2">
					<h2 className="text font-bold">Are you sure you want to delete source?</h2>

					<div className="flex flex-row gap-2 justify-end mt-4">
						<Button
							color="neutral"
							variant="outline"
							onClick={() => {
								setConfirmDelete(false);
							}}
						>
							Cancel
						</Button>
						<Button variant="outline" color="red" onClick={handleDelete}>
							Delete
						</Button>
					</div>
				</Modal>
			) : null}
		</div>
	);
};
