import { ReactNode, useState } from 'react';

interface TooltipProps {
	label?: string;
	children: ReactNode;
	position?: 'top' | 'left' | 'right' | 'bottom';
}

const tooltipContainerStyles = 'relative w-full';

const tooltipStyles =
	'absolute text-xs p-1 bg-gray-400 text-white rounded z-[1000]  w-full text-center';

const getPositionClasses = (position: TooltipProps['position']) => {
	switch (position) {
		case 'top':
			return 'top-0 left-1/2 transform -translate-x-1/2 -translate-y-full -mt-1';
		case 'left':
			return 'top-1/2 right-[100%] transform -translate-y-1/2';
		case 'bottom':
			return 'top-[100%] left-1/2 transform -translate-x-1/2 mt-1 ';
		case 'right':
			return 'top-1/2 left-[100%] transform translate-y-[-50%]';
		default:
			return 'top-[-100%] left-1/2 transform -translate-x-1/2';
	}
};

export const Tooltip = ({ label, children, position = 'top' }: TooltipProps) => {
	const [isTooltipVisible, setIsTooltipVisible] = useState(false);

	const handleMouseEnter = () => {
		setIsTooltipVisible(true);
	};
	const handleMouseLeave = () => {
		setIsTooltipVisible(false);
	};
	if (!label) return <>{children}</>;
	return (
		<div
			className={tooltipContainerStyles}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
			{isTooltipVisible ? (
				<div className={`${tooltipStyles} ${getPositionClasses(position)}`}>{label}</div>
			) : null}
		</div>
	);
};
