import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { QueryPayload } from '../types';
import { WORKSPACE_QUERY_ID_KEY } from './getWorkspaceQuery';

export const createQuery = async (queryPayload: QueryPayload) => {
	const { data } = await axios.post('/query/', queryPayload);
	return data;
};

type UseCreateQueryOptions = {
	config?: MutationConfig<typeof createQuery>;
};

export const useCreateQuery = ({ config }: UseCreateQueryOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(WORKSPACE_QUERY_ID_KEY);
		},
		mutationFn: createQuery,
	});
};
