import {
	Button,
	Option,
	RefreshButton,
	Select,
	Skeleton,
	SortButton,
	Title,
	generateSortFieldList,
	useSort,
} from '@dropbaseio/components';
import { useAuthUserData } from 'features/auth';
import { useWorkspaceData } from 'features/workspace';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWorkspaceQuery } from '../api/getWorkspaceQuery';
import { QueryItem } from './QueryListItem';

const QUERY_SORT_STYLES = generateSortFieldList([
	{ name: 'Name', field: 'name', type: 'string' },
	{ name: 'Created', field: '', type: 'ignoreReverse' },
	{ name: 'Edited', field: 'modified_date', type: 'string', displayType: 'number' },
]);

export const QueryList = ({ type = 'user' }: { type: 'user' | 'others' }) => {
	const { userId } = useAuthUserData();
	const { currentWorkspaceId } = useWorkspaceData();
	const [, setSearchParams] = useSearchParams();

	const {
		workspaceQueries,
		isFetching: isFetchingWorkspaceQueries,
		isError: isErrorWorkspaceQueries,
		refetch: refetchWorkspaceQueries,
	} = useWorkspaceQuery({
		workspaceId: currentWorkspaceId,
	});

	const queries = workspaceQueries.filter((x) => {
		if (type === 'user') {
			return x.user_id === userId;
		}
		return x.user_id !== userId;
	});

	const [sortMode, setSortMode] = useState(QUERY_SORT_STYLES.find((x) => x.id === 'edited-dsc')!);
	const { sortedTarget: sortedQueries } = useSort(sortMode, queries);

	const handleCreateQueryRoute = () => {
		setSearchParams({
			queryId: 'new',
		});
	};

	return sortedQueries.length === 0 && type !== 'user' ? (
		<></>
	) : (
		<div>
			<div className="flex flex-row justify-between mb-2">
				<div className="grow">
					<Title>{type === 'user' ? 'Mine' : 'Shared'}</Title>
				</div>
				<div className="flex flex-row gap-2">
					<Select
						value={sortMode.id}
						onChange={(val) => setSortMode(QUERY_SORT_STYLES.find((x) => x.id === val)!)}
						buttonEntrypoint={SortButton}
						buttonProps={{ color: 'neutral', variant: 'outline', size: 'regular' }}
					>
						{QUERY_SORT_STYLES.map((x) => (
							<Option key={x.id} value={x.id} className="font-medium text-sm">
								{x.text}
							</Option>
						))}
					</Select>
					<RefreshButton
						color="neutral"
						variant="outline"
						onClick={() => {
							refetchWorkspaceQueries();
						}}
						className="mt-0 text-neutral-400"
					/>
					{type === 'user' ? (
						<Button color="neutral" variant="outline" onClick={handleCreateQueryRoute}>
							Create
						</Button>
					) : null}
				</div>
			</div>
			{isFetchingWorkspaceQueries ? (
				<div className="flex flex-col gap-1">
					<Skeleton isLoading />
					<Skeleton isLoading />
					<Skeleton isLoading />
				</div>
			) : (
				<div className="flex flex-col gap-1">
					{sortedQueries.length === 0 ? (
						<div className="font-semibold text-sm text-neutral-500">
							No queries found.
							<br />
							Press &quot;Create&quot; to add a query.
						</div>
					) : (
						sortedQueries.map((query) => {
							return <QueryItem key={query.id} query={query} />;
						})
					)}
				</div>
			)}
		</div>
	);
};
