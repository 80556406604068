import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { Schedule, ScheduleResponse } from '../types';
import { WORKSPACE_USER_SCHEDULE_ID_KEY } from './workspaceSchedules';

export const updateScheduleStatus = async ({
	id,
	workspaceId,
	enabled,
}: {
	id: string;
	workspaceId: string;
	enabled: boolean;
}) => {
	const { data } = await axios.put(
		`/schedule_v2/${id}/status?workspace_id=${workspaceId}&enabled=${enabled}`,
	);
	return data;
};

type UseUpdateScheduleOptions = {
	config?: MutationConfig<typeof updateScheduleStatus>;
};

export const useUpdateScheduleStatus = ({ config }: UseUpdateScheduleOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			const schedules: Schedule[] | undefined = queryClient.getQueryData(
				WORKSPACE_USER_SCHEDULE_ID_KEY,
			);
			if (schedules) {
				const updatedScheduleIdx = schedules.findIndex((x) => x.id === variables.id);
				if (updatedScheduleIdx !== -1) {
					schedules[updatedScheduleIdx].enabled = variables.enabled;
					queryClient.setQueryData(WORKSPACE_USER_SCHEDULE_ID_KEY, schedules);
				}
			}
			const schedule: any = queryClient.getQueryData(['schedule', variables.id]);

			if (schedule) {
				schedule.enabled = variables.enabled;
				queryClient.setQueriesData(['schedule', variables.id], schedule);
			}
		},
		mutationFn: updateScheduleStatus,
	});
};
