import { NoPermission } from 'components/Common';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { QueryList } from 'features/queries/components/QueryList';
import { QueryFormContainer } from 'features/queries/components/QueryFormContainer';
import { ApiContext } from '@dropbaseio/components';
import { getQueryForm } from './api/getQueryForm';

const api = {
	api: {
		getQueryForm,
	},
};

export const QueriesPage = () => {
	const { userRole } = useWorkspaceData();

	if (userRole === 'billing') {
		return (
			<ContentLayout title="Queries" description="Manage queries">
				<NoPermission />
			</ContentLayout>
		);
	}

	return (
		<ContentLayout
			title="Queries"
			description="Manage queries"
			className="overflow-hidden"
			width="w-full"
		>
			<div className="flex h-full flex-row gap-8 animate-fadeIn">
				<div className="w-1/3 h-full overflow-y-auto min-w-[18rem]">
					<QueryList type="user" />
					<br />
					<QueryList type="others" />
				</div>
				<div className="w-1/2 h-full overflow-y-auto animate-fadeIn">
					<ApiContext.Provider value={api}>
						<QueryFormContainer />
					</ApiContext.Provider>
				</div>
			</div>
		</ContentLayout>
	);
};
