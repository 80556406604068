import { LoadingSpinner } from 'components/Common';
import { ReactNode, forwardRef } from 'react';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
	children?: ReactNode;
	loading?: boolean;
	color?: 'red' | 'blue' | 'neutral';
	variant?: 'solid' | 'outline';
	className?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{ loading, color = 'blue', variant = 'solid', className = '', children, ...props }: ButtonProps,
		ref,
	) => {
		const baseButtonClass = `relative
    min-w-fit w-full
    transition-[background,box-shadow]
    hover:shadow-lg focus:shadow-outline
    focus:outline-none
    text-sm font-semibold h-fit py-2 px-4 rounded`;

		const colorVariants = {
			blue: {
				solid: {
					active: 'bg-blue-500 hover:bg-blue-400 active:bg-blue-500 text-white',
					disabled: 'bg-blue-300 text-neutral-50',
				},
				outline: {
					active: 'border border-blue-500 text-blue-500 hover:text-blue-400 hover:border-blue-400',
					disabled: 'border border-blue-300 text-blue-300',
				},
			},
			red: {
				solid: {
					active: 'bg-red-500 hover:bg-red-400 active:bg-red-500 text-white',
					disabled: 'bg-red-300 text-neutral-50',
				},
				outline: {
					active: 'border border-red-500 text-red-500 hover:text-white hover:bg-red-500',
					disabled: 'border border-red-300 text-red-300',
				},
			},
			neutral: {
				solid: {
					active: 'bg-neutral-500 hover:bg-neutral-400 active:bg-neutral-500 text-white',
					disabled: 'bg-neutral-300 text-neutral-50',
				},
				outline: {
					active:
						'border border-neutral-300 text-neutral-400 hover:text-neutral-500 hover:border-neutral-400',
					disabled: 'border border-neutral-200 text-neutral-300',
				},
			},
		};

		const buttonDisabled = loading || props.disabled;

		return (
			<button
				type="button"
				ref={ref}
				disabled={buttonDisabled}
				className={`${baseButtonClass} ${
					buttonDisabled
						? `focus:outline-none pointer-events-none ${colorVariants[color][variant].disabled}`
						: colorVariants[color][variant].active
				} ${className || ''}`}
				{...props}
			>
				{loading ? (
					<div className="p-2.5">
						<LoadingSpinner />
					</div>
				) : (
					children
				)}
			</button>
		);
	},
);
