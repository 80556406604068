import { useUserPaymentControls } from 'features/billing/api/useUserPaymentControls';
import { Button } from 'components/Button';
import { queryClient } from 'libs/react-query';
import { AddCard } from 'features/billing/components/AddCard';
import { useFormContext } from 'react-hook-form';

import { Card } from 'features/billing/types';
import { useEffect, useState } from 'react';

const PaymentCard = ({
	card,
	onChoosePayment,
	isLoading = false,
	isSelected = false,
}: {
	card: Card;
	onChoosePayment: (paymentMethodId: string) => void;
	isLoading: boolean;
	isSelected?: boolean;
}) => {
	return (
		<div className="inline-flex transition-[border,box-shadow] flex-col rounded-md mr-1 mt-1 border border-neutral-300 p-4 w-60 text-neutral-700 font-bold text-sm hover:border-neutral-400 hover:shadow-md">
			<div className="flex">
				{card.brand} ending in {card.last4}
			</div>
			<div className="mb-2">
				Exp: {card.exp_month} / {card.exp_year}
			</div>

			{isSelected ? (
				<Button disabled>Selected</Button>
			) : (
				<div className="flex">
					<Button
						className="mr-1"
						loading={isLoading}
						onClick={() => onChoosePayment(card.payment_method_id)}
					>
						Use this payment
					</Button>
				</div>
			)}
		</div>
	);
};

export const Payment = () => {
	const { paymentInfoKey, paymentMethods, onUpdatePaymentMethod, updatePMIsLoading } =
		useUserPaymentControls();
	const { setValue, watch } = useFormContext();
	const selectedPaymentMethod = watch('chosenPaymentMethodId');
	const hasPaymentMethod = watch('hasPaymentMethod');
	useEffect(() => {
		if (paymentMethods.length > 0 && paymentMethods.some((pm) => pm.is_default)) {
			setValue('hasPaymentMethod', true);
		}
	}, [paymentMethods, setValue]);

	const onSuccess = (result: any) => {
		if (typeof result.setupIntent.payment_method === 'string') {
			onUpdatePaymentMethod(result.setupIntent.payment_method);
			setValue('hasPaymentMethod', true);
		}
		queryClient.invalidateQueries(paymentInfoKey);
	};
	const handleChoosePaymentMethod = async (cardId: string) => {
		setValue('chosenPaymentMethodId', cardId);
	};

	useEffect(() => {
		const defaultPM = paymentMethods.find((pm) => pm.is_default);
		if (defaultPM) {
			setValue('chosenPaymentMethodId', defaultPM.payment_method_id);
		}
	}, [paymentMethods, setValue]);
	return (
		<>
			<div className="flex flex-col justify-center items-center">
				{paymentMethods?.map((card: Card) => {
					return (
						<PaymentCard
							key={card.payment_method_id}
							card={card}
							onChoosePayment={handleChoosePaymentMethod}
							isLoading={updatePMIsLoading}
							isSelected={selectedPaymentMethod === card.payment_method_id}
						/>
					);
				})}
				<AddCard
					onSuccess={onSuccess}
					disableButton={paymentMethods.length === 0}
					buttonClassName="w-60"
				/>
			</div>
		</>
	);
};
