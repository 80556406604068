import { axios } from 'libs/axios';
import { useMutation, useQueryClient } from 'react-query';
import { MutationConfig } from 'libs/react-query';
import { WorkspacePaymentInfoKey } from './useWorkspacePaymentInfo';
import { UserPaymentInfoKey } from './useUserPaymentInfo';

export const deletePaymentMethod = ({ paymentMethodId }: { paymentMethodId: string }) => {
	return axios.delete(`/user/payment_method`, {
		data: { payment_method_id: paymentMethodId },
	});
};

export const useDeletePaymentMethod = (config?: MutationConfig<typeof deletePaymentMethod>) => {
	const queryClient = useQueryClient();
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries(WorkspacePaymentInfoKey);
			queryClient.invalidateQueries(UserPaymentInfoKey);
		},
		mutationFn: deletePaymentMethod,
	});
};
