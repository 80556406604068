export interface WorkspaceItemProps {
	name: string;
	userRole: string;
}

export const WorkspaceItem = ({ name, userRole }: WorkspaceItemProps) => {
	return (
		<button
			type="button"
			className="relative flex flex-row w-full h-full p-4 min-w-[20rem] border rounded-md text-sm justify-between group hover:shadow-lg transition-[box-shadow,border] hover:border-neutral-400"
		>
			<div className="flex flex-col h-full text-left mr-12">
				<span className="font-semibold text-sm">{name}</span>
				<span className="text-xs font-semibold text-neutral-500 capitalize">{userRole}</span>
			</div>
			<div className="absolute right-4 top-1/2 -translate-y-1/2 h-fit text-neutral-500 group-hover:text-blue-400 group-active:text-blue-500 transition-colors">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="w-6 h-6"
				>
					<path
						fillRule="evenodd"
						d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
						clipRule="evenodd"
					/>
				</svg>
			</div>
		</button>
	);
};
