import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useQueryClient } from 'react-query';
import { useAuthUserData } from 'features/auth';
import { useWorkspaceData } from 'features/workspace';
import { FormFieldSkeleton } from 'components/Form';
import { useWorkspaceSetupIntent } from '../api/useWorkspaceSetupIntent';
import { Card } from '../types';
import { PaymentCard } from './PaymentCard';
import { AddCard } from './AddCard';
import { useUpdateWorkspacePaymentMethods } from '../api/useUpdateWorkspacePaymentMethods';
import { useDeletePaymentMethod } from '../api/useDeletePaymentMethod';
import { useWorkspacePaymentInfo } from '../api/useWorkspacePaymentInfo';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
export const CardOptions = ({
	clientSecret,
	onUpdatePaymentMethod,
	onDeletePaymentMethod,
	paymentMethods,
	paymentInfoKey,
	setupIntentKey,
	refetch,
	isFetching,
	forSubscription = false,
}: {
	clientSecret: string;
	onUpdatePaymentMethod: (cardId: string) => void;
	onDeletePaymentMethod: (cardId: string) => void;
	paymentMethods: Card[];
	paymentInfoKey?: string;
	setupIntentKey?: string;
	refetch: () => void;
	isFetching: any;
	forSubscription?: boolean;
}) => {
	const queryClient = useQueryClient();

	const handleChoosePaymentMethod = async (cardId: string) => {
		onUpdatePaymentMethod(cardId);
	};
	const handleDeletePaymentMethod = async (cardId: string) => {
		onDeletePaymentMethod(cardId);
	};
	const handleAddCardSuccess = async (result: any) => {
		queryClient.invalidateQueries(paymentInfoKey);
		queryClient.removeQueries(setupIntentKey);

		if (typeof result.setupIntent.payment_method === 'string' && paymentMethods.length === 0) {
			onUpdatePaymentMethod(result.setupIntent.payment_method);
		}
	};

	if (!clientSecret) return null;
	return (
		<Elements
			stripe={stripePromise}
			options={{
				clientSecret,
			}}
		>
			<div>
				{isFetching ? (
					<>
						<FormFieldSkeleton />
						<br />
						<FormFieldSkeleton />
						<br />
						<FormFieldSkeleton />
					</>
				) : (
					paymentMethods?.map((card: Card) => {
						return (
							<PaymentCard
								key={card.payment_method_id}
								card={card}
								onChoosePayment={handleChoosePaymentMethod}
								onDeletePayment={handleDeletePaymentMethod}
								forSubscription={forSubscription}
							/>
						);
					})
				)}

				<AddCard onSuccess={handleAddCardSuccess} />
			</div>
		</Elements>
	);
};
