import { useContext } from 'react';
import { AuthProviderContext } from '../AuthProviderContext';

export const useAuthUserData = () => {
	const { userJWT, userId, clearData } = useContext(AuthProviderContext);
	return {
		userJWT,
		userId,
		clearData,
	};
};
