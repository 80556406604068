import {
	Button,
	FormSkeleton,
	QueriesForm,
	QueryDataPreview,
	QueryError,
	Title,
} from '@dropbaseio/components';
import { useAuthUserData } from 'features/auth';
import { useWorkspaceSources } from 'features/databases/api/getAllSource';
import { SourceMap } from 'features/databases/constants';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WORKSPACE_ROLE, getErrorBody } from 'utils';
import { useCreateQuery } from '../api/createQuery';
import { useDeleteQuery } from '../api/deleteQuery';
import { useGetQuery } from '../api/getQuery';
import { useTestQuery } from '../api/testQuery';
import { useUpdateQuery } from '../api/updateQuery';
import { QueryPayload, UpdateQueryDTO } from '../types';

export const QueryFormContainer = () => {
	const toast = useToastNotification();
	const { userId } = useAuthUserData();
	const { userRole, currentWorkspaceId } = useWorkspaceData();

	const [searchParams, setSearchParams] = useSearchParams();
	const queryId = searchParams.get('queryId');
	const selectedQueryId = queryId || '';
	const [isLoading, setIsLoading] = useState(false);

	const [queryData, setQueryData] = useState<any>(null);

	const navigate = useNavigate();

	const isCreateQuery = selectedQueryId === 'new';

	const {
		query: queryInfo,
		refetch: refetchQuery,
		error: queryError,
		isFetching: isFetchingQuery,
		isRefetching: isRefetchingQuery,
		values: queryValues,
		inputs: queryInputs,
	} = useGetQuery({
		queryId: selectedQueryId || '',
		config: {
			enabled: !!selectedQueryId && !isCreateQuery,
		},
	});

	const { sources: workspaceSources } = useWorkspaceSources({ workspaceId: currentWorkspaceId });
	const workspaceSourcesWithIcons = workspaceSources.map((source) => ({
		...source,
		iconSrc: SourceMap[source.type]?.iconSrc,
	}));

	useEffect(() => {
		setQueryData(null);
	}, [queryId]);

	const updateQuery = useUpdateQuery({
		config: {
			onMutate() {
				setIsLoading(true);
			},
			onSuccess() {
				toast.notifySuccess(`Successfully updated query`);
				refetchQuery();
			},
			onError() {
				toast.notifyFailure(`Failed to update query`);
			},
			onSettled() {
				setIsLoading(false);
			},
		},
	});

	const testQuery = useTestQuery({
		config: {
			onMutate() {
				setIsLoading(true);
			},
			onSuccess(data, _, __) {
				setQueryData(data);
			},
			onError(error, _, __) {
				toast.notifyFailure(getErrorBody(error));
			},
			onSettled() {
				setIsLoading(false);
			},
		},
	});

	const createQuery = useCreateQuery({
		config: {
			onMutate() {
				setIsLoading(true);
			},
			onSuccess(data: any) {
				setSearchParams({
					queryId: data.id,
				});
				toast.notifySuccess('Created query');
			},
			onError(data: any) {
				toast.notifyFailure(`Failed to create query\n${getErrorBody(data)}`);
			},
			onSettled() {
				setIsLoading(false);
			},
		},
	});

	const deleteQuery = useDeleteQuery({
		config: {
			onMutate() {
				setIsLoading(true);
			},
			onSuccess() {
				navigate('..');
				toast.notifySuccess(`Successfully deleted schedule`);
			},
			onError() {
				toast.notifyFailure(`Failed to delete schedule`);
			},
			onSettled() {
				setIsLoading(false);
			},
		},
	});

	const handleOnSave = (queryDto: any) => {
		if (queryDto.source_id === '-1') {
			toast.notifyFailure('Source not found');
			return;
		}

		if (queryId) {
			if (queryId === 'new') {
				const queryPayload: QueryPayload = {
					...queryDto,
					user_id: userId,
				};

				createQuery.mutate(queryPayload);
			} else {
				const updatePayload: UpdateQueryDTO = {
					id: queryId,
					payload: queryDto,
				};
				updateQuery.mutate(updatePayload);
			}
		}
	};

	const hasSheetVariables = (query: string) => {
		const CELL_REPLACE_RE = /{{\s*\S+\s*}}/;
		return !!query.match(CELL_REPLACE_RE)?.length;
	};

	const handleOnTest = (queryDto: any) => {
		if (queryDto.query?.query && hasSheetVariables(queryDto.query?.query)) {
			toast.notifyMessage(
				'Queries with variables cannot be tested on the Admin app, please go to the Addon to test it.',
			);
			return;
		}

		toast.notifyMessage(`Testing Query. Please wait.`);
		testQuery.mutate({
			query: queryDto.query,
			sourceId: queryDto.source_id,
		});
	};

	const handleDeleteQuery = () => {
		deleteQuery.mutate(selectedQueryId);
	};

	const canEditQuery = userRole !== WORKSPACE_ROLE.MEMBER || queryInfo?.user_id === userId;

	if (!queryId) {
		return null;
	}

	if (queryError) {
		return <QueryError error={queryError} />;
	}

	if (isFetchingQuery || isRefetchingQuery) {
		return (
			<div className="mt-6">
				<FormSkeleton />
			</div>
		);
	}

	if (canEditQuery || isCreateQuery) {
		return (
			<div className="animate-fadeIn sticky top-0">
				<Title>{isCreateQuery ? 'Create a new Query' : `Editing ${queryInfo?.name}`}</Title>
				<div className="relative -top-4">
					<QueriesForm
						key={queryInfo?.id}
						queryId={queryInfo?.id}
						onDeleteHandler={handleDeleteQuery}
						onTestHandler={handleOnTest}
						onSaveHandler={handleOnSave}
						disabled={isLoading}
						deleteInRequest={deleteQuery.isLoading}
						saveInRequest={updateQuery.isLoading || createQuery.isLoading}
						testInRequest={testQuery.isLoading}
						sources={workspaceSourcesWithIcons}
						queryValues={queryValues}
						queryInputs={queryInputs}
						query={queryInfo}
					>
						{({ getPayload }: any) => (
							<>
								<div className="flex justify-evenly mt-4 gap-2">
									{isCreateQuery ? null : (
										<Button
											loading={deleteQuery.isLoading}
											variant="outline"
											color="red"
											onClick={handleDeleteQuery}
											className="flex-1"
										>
											Delete
										</Button>
									)}

									<Button
										loading={testQuery.isLoading}
										onClick={() => handleOnTest(getPayload())}
										variant="outline"
										color="blue"
										className="flex-1"
									>
										Test
									</Button>

									<Button
										loading={updateQuery.isLoading || createQuery.isLoading}
										onClick={() => handleOnSave(getPayload())}
										className="flex-1"
									>
										{isCreateQuery ? 'Create' : 'Save'}
									</Button>
								</div>
							</>
						)}
					</QueriesForm>
				</div>
				<QueryDataPreview queryData={queryData} />
			</div>
		);
	}

	if (queryInfo && !canEditQuery) {
		return (
			<div className="animate-fadeIn sticky top-0">
				<Title>{`Viewing ${queryInfo.name}`}</Title>
				<div className="relative -top-4">
					<QueriesForm
						key={queryInfo?.id}
						queryId={queryInfo?.id}
						onTestHandler={handleOnTest}
						disabled
						testInRequest={testQuery.isLoading}
						sources={workspaceSources}
						queryValues={queryValues}
						queryInputs={queryInputs}
						query={queryInfo}
					>
						{({ getPayload }: any) => (
							<>
								<div className="flex justify-evenly mt-4 gap-2">
									<Button
										loading={testQuery.isLoading}
										onClick={() => handleOnTest(getPayload())}
										variant="outline"
										color="blue"
										className="flex-1"
									>
										Test
									</Button>
								</div>
							</>
						)}
					</QueriesForm>
				</div>
				<QueryDataPreview queryData={queryData} />
			</div>
		);
	}

	return null;
};
