import { DownloadIcon } from 'assets';
import { Invoice } from '../types';

export const InvoiceHistoryItem = ({ invoice }: { invoice: any }) => {
	const openingDate = new Date(invoice.period_start * 1000).toLocaleDateString();
	return (
		<div className="flex flex-row text-sm font-semibold text-neutral-700">
			<span className="w-1/3 text-left">{openingDate}</span>
			{/* <span className="w-1/3 text-left">{invoice.invoiceNumber}</span> */}
			<span className="w-1/3 text-left">{invoice.amount_due}</span>
			<span className="w-1/3 text-left">{invoice.amount_paid}</span>
			<div className="w-1/3 flex justify-center">
				<a
					href={`${invoice.invoice_pdf}`}
					className="min-w-fit min-h-fit py-0 px-1 hover:border-black border-2 border-white text-sm font-semibold h-fit py-2 px-4 rounded"
				>
					<DownloadIcon />
				</a>
			</div>
		</div>
	);
};
