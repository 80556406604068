import { axios, setAuthorizationHeader } from 'libs/axios';
import { ExtractFnReturnType, MutationConfig, queryClient, QueryConfig } from 'libs/react-query';
import { useMutation, useQuery } from 'react-query';
import { AUTH_VALIDATION_RESULT_KEY } from './validateAuth';

export const authorize = (authCode: string) => {
	setAuthorizationHeader(authCode);
	return axios.post('/user/auth?type=CODE');
};

type UseAuthorizeOptions = {
	config?: MutationConfig<typeof authorize>;
};

export const useAuthorize = ({ config }: UseAuthorizeOptions = {}) => {
	const response = useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(AUTH_VALIDATION_RESULT_KEY);
		},
		mutationFn: authorize,
	});
	return { ...response };
};
