import {
	AccountIcon,
	BillingIcon,
	DatabaseIcon,
	MembersIcon,
	QueriesIcon,
	SchedulesIcon,
	SettingIcon,
	ExternalLinkIcon,
	HelpIcon,
	UsageIcon,
	ActivityIcon,
} from 'assets';
import { WorkspaceChangeButton } from 'features/workspace/components/WorkspaceChangeButton';
import { Link, useLocation } from 'react-router-dom';
import {
	ACCOUNT_PAGE,
	BILLING_PAGE,
	DATABASES_PAGE,
	QUERIES_PAGE,
	SCHEDULES_PAGE,
	SETTING_PAGE,
	MEMBERS_PAGE as TEAM_Page,
	USAGE_PAGE,
	WORKSPACE_ROLE,
	LOGS_PAGE,
} from 'utils';

// @ts-expect-error: image import
import logo from 'assets/castodia-logo.png';
import { useBillingInfo } from 'features/billing/api/useBillingInfo';
import { useWorkspaceData } from 'features/workspace';
import { useWorkspaceInfo } from 'features/workspace/api/workspaceInfo';

interface PageInfo {
	id: number;
	name: string;
	route: string;
	icon: JSX.Element;
	absolutePath?: boolean;
}

export const Navbar = ({ profile }: { profile: boolean }) => {
	const { userRole, currentWorkspaceId, currentWorkspace } = useWorkspaceData();
	const { pathname } = useLocation();

	const isCurrentPageMatching = (itemRoute: string) => {
		return pathname.includes(itemRoute);
	};

	const renderPageItem = (info: PageInfo) => {
		return (
			<Link
				key={info.id}
				to={
					info.absolutePath ? `/${info.route}` : `/${currentWorkspace?.workspace_id}/${info.route}`
				}
			>
				<div
					className={`w-full flex px-1 py-2 my-1 text-md font-semibold leading-snug transition-[border,box-shadow,background] border rounded-md active:bg-neutral-200 ${
						isCurrentPageMatching(info.route)
							? 'border-neutral-400 shadow-md'
							: 'border-transparent hover:border-neutral-300 hover:shadow-md'
					}`}
				>
					<span className="mx-3">{info.icon}</span>
					{info.name}
				</div>
			</Link>
		);
	};

	const USAGE = renderPageItem({
		id: USAGE_PAGE,
		name: 'Usage',
		icon: UsageIcon,
		route: 'usage',
	});
	const DATABASE = renderPageItem({
		id: DATABASES_PAGE,
		name: 'Data Sources',
		icon: DatabaseIcon,
		route: 'source',
	});
	const SCHEDULE = renderPageItem({
		id: SCHEDULES_PAGE,
		name: 'Schedules',
		icon: SchedulesIcon,
		route: 'schedule',
	});
	const TEAM = renderPageItem({
		id: TEAM_Page,
		name: 'Team',
		icon: MembersIcon,
		route: 'team',
	});
	const QUERIES = renderPageItem({
		id: QUERIES_PAGE,
		name: 'Queries',
		icon: QueriesIcon,
		route: 'query',
	});
	const BILLINGS = renderPageItem({
		id: BILLING_PAGE,
		name: 'Billing',
		icon: BillingIcon,
		route: 'billing',
	});
	const ACCOUNT = renderPageItem({
		id: ACCOUNT_PAGE,
		name: 'Profile',
		icon: AccountIcon,
		route: 'account',
	});
	const SETTING = renderPageItem({
		id: SETTING_PAGE,
		name: 'Settings',
		icon: SettingIcon,
		route: 'setting',
	});
	const LOGS = renderPageItem({
		id: LOGS_PAGE,
		name: 'Logs',
		icon: ActivityIcon,
		route: 'logs',
	});

	const SEPARATOR = <hr className="mt-6 mb-4 border-neutral-200 w-full border-0 border-t" />;

	const ownerNavItem = [
		USAGE,
		SCHEDULE,
		QUERIES,
		DATABASE,
		TEAM,
		BILLINGS,
		LOGS,
		SETTING,
		SEPARATOR,
		ACCOUNT,
	];
	const adminNavItem = [SCHEDULE, QUERIES, TEAM, LOGS, SETTING, SEPARATOR, ACCOUNT];
	const memberNavItem = [SCHEDULE, QUERIES, TEAM, LOGS, SETTING, SEPARATOR, ACCOUNT];
	const billingNavItem = [BILLINGS, SETTING, SEPARATOR, ACCOUNT];

	const { workspaceInfo } = useWorkspaceInfo({ workspaceId: currentWorkspaceId });
	const { upcomingInvoice } = useBillingInfo();

	const getRoleNavItem = (role: string) => {
		switch (role) {
			case WORKSPACE_ROLE.OWNER:
				return ownerNavItem;
			case WORKSPACE_ROLE.ADMIN:
				return adminNavItem;
			case WORKSPACE_ROLE.MEMBER:
				return memberNavItem;
			case WORKSPACE_ROLE.BILLING:
				return billingNavItem;
			default:
				return ownerNavItem;
		}
	};
	const getTrialMessage = (daysUntilTrialEnd: number) => {
		if (daysUntilTrialEnd > 0) {
			return `${daysUntilTrialEnd} trial days left`;
		}
		if (daysUntilTrialEnd === 0) {
			return 'Trial expires today';
		}
		return 'Trial Expired';
	};

	const TrialMessage = () => {
		const daysUntilTrialEnd = workspaceInfo?.days_until_trial_end;
		if (
			daysUntilTrialEnd === undefined ||
			daysUntilTrialEnd === null ||
			workspaceInfo?.marked_for_cancellation
		)
			return null;

		const message = getTrialMessage(daysUntilTrialEnd);
		return (
			<div
				className={`text-sm font-semibold h-7 p-4 mb-6 rounded flex items-center justify-center ${
					daysUntilTrialEnd >= 0 ? 'bg-yellow-500 text-black' : 'bg-red-500 text-white'
				}`}
			>
				{message}
			</div>
		);
	};
	const CancellationMessage = () => {
		const markedForCancellation = workspaceInfo?.marked_for_cancellation;
		const unixCancellationDate = upcomingInvoice?.end_date;

		if (currentWorkspace?.status === 'canceled') {
			return (
				<div className="bg-red-500 text-sm font-semibold h-fit p-4 mb-6 rounded text-center text-white items-center w-full justify-center">
					<span className="font-bold text-lg">Subscription cancelled</span>
					<br />
					<span className="text-xs">Please go to Billing to choose plan and payment method</span>
				</div>
			);
		}
		if (
			unixCancellationDate === undefined ||
			unixCancellationDate === null ||
			!markedForCancellation
		)
			return null;
		const cancellationDate = new Date(unixCancellationDate * 1000).toISOString().split('T')[0];

		return (
			<div className="bg-slate-500 text-sm font-semibold h-fit p-4 mb-6 rounded text-white flex items-center justify-center w-full text-center whitespace-pre-line">
				{`Your subscription will cancel on\n${cancellationDate}.\n Any usage exceeding your plan limit will be added to your bill.`}
			</div>
		);
	};
	const UnpaidMessage = () => {
		if (currentWorkspace?.status === 'unpaid') {
			return (
				<div className="bg-red-500 text-sm font-semibold h-fit p-4 mb-6 rounded text-center text-white items-center w-full justify-center">
					<span className="font-bold text-lg">Unpaid Workspace</span>
					<br /> <span className="text-xs">Please resolve payment in Billing page</span>
				</div>
			);
		}
		return null;
	};

	return (
		<div className="p-6 min-w-[17rem] w-80 flex flex-col justify-between">
			<div className="w-full">
				<img src={logo} className="w-52 mt-6 mb-12 ml-3" alt="Castodia" />
				{profile ? (
					renderPageItem({
						id: ACCOUNT_PAGE,
						name: 'Profile',
						icon: AccountIcon,
						route: 'account',
						absolutePath: true,
					})
				) : (
					<>
						<TrialMessage />
						<CancellationMessage />
						<UnpaidMessage />
						<div className="w-full mb-4">
							<WorkspaceChangeButton />
						</div>
						{getRoleNavItem(userRole)}
					</>
				)}
			</div>
			<div className="w-full">
				<a href="https://docs.castodia.com/quickstart/" target="_blank" rel="noreferrer">
					<div className="w-full flex px-1 py-2 my-1 text-sm font-semibold leading-snug transition-colors items-center hover:text-neutral-500">
						<span className="mx-3">
							<HelpIcon />
						</span>
						Quickstart
						<ExternalLinkIcon className="w-4 h-4 mx-3" />
					</div>
				</a>
			</div>
		</div>
	);
};
