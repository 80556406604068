import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { WORKSPACE_USER_SCHEDULE_ID_KEY } from './workspaceSchedules';

export const updateSchedule = async ({
	id,
	payload,
}: {
	id: string;
	payload: {
		name: string;
		description: string;

		query_id: string;
		user_id: string;

		method: string;
		timezone: string;

		sheet_data: any;
		form_data: {
			start_time: number;
			end_time: number;
			interval: number;
			unit: string;
			day_of_month: string;
			day_of_week: string;
			month_of_year: string;
		};
	};
}) => {
	const { data } = await axios.put(`/schedule_v2/${id}`, payload);
	return data;
};

type UseUpdateScheduleOptions = {
	config?: MutationConfig<typeof updateSchedule>;
};

export const useUpdateSchedule = ({ config }: UseUpdateScheduleOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(WORKSPACE_USER_SCHEDULE_ID_KEY);
			queryClient.invalidateQueries(['schedule', data.id]);
		},
		mutationFn: updateSchedule,
	});
};
