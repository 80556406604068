import { Button } from 'components/Button';
import { useWorkspaceData } from 'features/workspace';
import { USER_WORKSPACES_KEY } from 'features/workspace/api/userWorkspaces';
import { useToastNotification } from 'hooks/useToastNotification';
import { useQueryClient } from 'react-query';
import { getErrorBody } from 'utils';
import { useWorkspacePaymentInfo } from '../api/useWorkspacePaymentInfo';
import {
	SubscriptionPlan,
	useWorkspaceSubscriptionPlans,
} from '../api/useWorkspaceSubscriptionPlans';
import { useUpdateWorkspacePlan } from '../api/useUpdateWorkspacePlan';
import { CONTACT } from '../constants';

export const PlanCard = ({
	isCurrent,
	isRecommended,
	plan,
	buttonProps,
}: {
	isCurrent: boolean;
	isRecommended: boolean;
	plan: SubscriptionPlan;
	buttonProps?: {
		text: string;
		className?: string;
		disabled?: boolean;
		onClick: (planName: string) => void;
	};
}) => {
	const toast = useToastNotification();
	const { currentWorkspaceId, currentWorkspace } = useWorkspaceData();
	const { customPlans } = useWorkspaceSubscriptionPlans();
	const queryClient = useQueryClient();
	const { mutate: mutateUpdateWorkspace, isLoading: isLoadingUpdateWorkspace } =
		useUpdateWorkspacePlan({
			config: {
				onSuccess(data, variables, context) {
					queryClient.invalidateQueries(USER_WORKSPACES_KEY);
					toast.notifySuccess(data.data.message);
				},
				onError(error, _, __) {
					toast.notifyFailure(`Failed to update plan\n${getErrorBody(error)}`);
				},
			},
		});
	const { paymentMethods } = useWorkspacePaymentInfo();
	const hasDefaultPaymentMethod = paymentMethods?.some((card) => card.is_default);
	const currentWorkspaceIsCustom = customPlans?.some(
		(cPlan) => cPlan.name.toLowerCase() === currentWorkspace?.plan.toLowerCase(),
	);
	const updateOnClick = () => {
		mutateUpdateWorkspace({ workspaceId: currentWorkspaceId, plan: plan.name });
	};
	const getButtonProps = () => {
		if (isCurrent) {
			return {
				text: 'Current Plan',
				disabled: true,
			};
		}
		if (buttonProps) {
			const { text, disabled, className, onClick: givenOnClick } = buttonProps;
			return {
				text,
				disabled,
				className,
				onClick: () => givenOnClick(plan.name),
			};
		}
		if (!hasDefaultPaymentMethod) {
			return {
				text: 'Payment method required',
				disabled: true,
			};
		}
		if (plan.name.toLowerCase() === CONTACT.name) {
			return {
				text: 'Request a Call',
				onClick: () => {
					window.open(
						`mailto:sales@castodia.com?subject=Need custom Castodia Plan&body=I'd like to schedule a call to discuss a Custom Castodia Plan`,
					);
				},
				className: 'bg-purple-500 hover:bg-purple-600',
			};
		}
		if (currentWorkspaceIsCustom) {
			return {
				text: 'Contact Support',
				disabled: true,
			};
		}

		return {
			text: `Switch to ${plan.name}`,
			onClick: updateOnClick,
			loading: isLoadingUpdateWorkspace,
		};
	};

	return (
		<div
			className={`inline-flex align-top border rounded-md transition-colors w-72 h-64 font-medium flex-col justify-between p-4 mr-1 mt-1 ${
				isCurrent
					? 'shadow-lg shadow-neutral-300 border-neutral-400 text-neutral-900'
					: 'border-neutral-300 text-neutral-500 hover:text-neutral-900'
			}`}
		>
			{plan?.internal && <div className="text-orange-500">Dev Plan</div>}
			{plan?.is_custom && <div className="text-purple-500">Custom Plan</div>}

			<div className="flex flex-col h-fit">
				<span className="text-3xl font-bold capitalize">{plan.name}</span>
				{plan?.monthly_fee && <span className="text-xl font-bold">${plan.monthly_fee}</span>}
			</div>
			<div className="flex flex-col h-20 mt-2">
				{plan?.auto_refreshes && (
					<span className="text-sm font-semibold">{plan.auto_refreshes} auto-refreshes</span>
				)}
				{plan?.additional_cost && (
					<span className="text-sm font-semibold">${plan.additional_cost} per additional run</span>
				)}
				{plan?.user_limit && <span className="text-sm font-semibold">{plan.user_limit} users</span>}
			</div>
			{/* TODO: @Jon, need your help with text passed tpo button and props */}
			<Button {...getButtonProps()}>{getButtonProps().text}</Button>
		</div>
	);
};
