import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';

export const deleteWorkspace = (workspaceId: string) => {
	return axios.delete(`/workspace/${workspaceId}`);
};

type UseDeleteWorkspaceOptions = {
	config?: MutationConfig<typeof deleteWorkspace>;
};

export const useDeleteWorkspace = ({ config }: UseDeleteWorkspaceOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries();
			queryClient.clear();
		},
		mutationFn: deleteWorkspace,
	});
};
