import { Button } from 'components/Button';
import { Card } from '../types';

export const PaymentCard = ({
	card,
	onChoosePayment,
	onDeletePayment,
	forSubscription = false,
}: {
	card: Card;
	onChoosePayment: (cardId: string) => void;
	onDeletePayment: (cardId: string) => void;
	forSubscription?: boolean;
}) => {
	const isUsedForSubscription = card.used_for_subscription;
	const isDefaultForUser = card.is_default;

	const getButtons = () => {
		if (forSubscription) {
			if (isUsedForSubscription) {
				return <Button disabled>In use</Button>;
			}
			return (
				<Button className="mr-1" onClick={() => onChoosePayment(card.payment_method_id)}>
					Use this
				</Button>
			);
		}
		if (isDefaultForUser) {
			return <Button disabled>Default</Button>;
		}
		return (
			<div className="flex">
				<Button className="mr-1" onClick={() => onChoosePayment(card.payment_method_id)}>
					Make default
				</Button>
				<Button className="bg-red-500 ml-1" onClick={() => onDeletePayment(card.payment_method_id)}>
					Delete
				</Button>
			</div>
		);
	};
	return (
		<div className="inline-flex transition-[border,box-shadow] flex-col rounded-md mr-1 mt-1 border border-neutral-300 p-4 w-60 text-neutral-700 font-bold text-sm hover:border-neutral-400 hover:shadow-md">
			<div className="flex">
				{card.brand} ending in {card.last4}
			</div>
			<div className="mb-2">
				Exp: {card.exp_month} / {card.exp_year}
			</div>

			{getButtons()}
		</div>
	);
};
