import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/Common';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDeleteSource } from '../api/deleteSource';
import { SourceMap } from '../constants';
import { Source } from '../types';

export const SourceCard = ({ source }: { source: Source }) => {
	const [disabled, setDisabled] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const sourceId = searchParams.get('sourceId');

	const selected = sourceId === source.id;

	const [deletingSource, setDeletingSource] = useState(false);

	const toast = useToastNotification();

	const deleteSource = useDeleteSource({
		config: {
			onSuccess() {
				toast.notifySuccess(`Successfully deleted source`);
			},
			onError() {
				toast.notifyFailure(`Failed to delete source`);
			},
		},
	});

	const handleDeleteOnClick = async () => {
		setDisabled(true);
		await deleteSource.mutateAsync(source.id); // it does have an effect. it awaits for an async delete call
		setSearchParams({
			sourceId: '',
		});
		setDisabled(false);
	};

	return (
		<div
			className={`relative flex flex-col animate-fadeIn border rounded-md p-2 text-xs font-semibold text-neutral-700 transition-[border,box-shadow] hover:border-neutral-400 hover:shadow-lg ${
				selected ? 'border-neutral-400 shadow-lg' : ' border-neutral-300'
			}`}
		>
			<div className="flex flex-row justify-between items-center gap-1">
				<div className="flex flex-row gap-1 items-center">
					<img
						src={SourceMap[source.type]?.iconSrc}
						alt={`${source.type} logo`}
						className="w-3 h-3"
					/>
					<span className="font-bold text-sm flex-1">{source.name}</span>
				</div>
				<div className="flex flex-row gap-1 h-5">
					{deletingSource ? (
						<div className="flex flex-row group w-fit ml-2 transition-[border] rounded-md">
							<div className="transition-[width] overflow-hidden w-0 group-hover:w-5  text-xs font-semibold">
								<button
									type="button"
									onClick={() => setDeletingSource(true)}
									className="transition-[border,color] w-5 rounded-md text-neutral-400 border border-neutral-300 hover:border-red-400 hover:text-red-400 "
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										className="w-auto h-[18px] m-auto"
									>
										<path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
									</svg>
								</button>
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={2}
								stroke="currentColor"
								className="w-4 h-4 top-0.5 left-0.5 relative"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
								/>
							</svg>
						</div>
					) : (
						<></>
					)}
					<button
						type="button"
						onClick={() => {
							setSearchParams({
								sourceId: source.id,
							});
						}}
						className="flex flex-row transition-[border,color] border border-neutral-300 text-neutral-400 rounded-md px-0.5 leading-normal hover:text-neutral-500 hover:border-neutral-400 "
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							className="w-4 h-4"
						>
							<path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
							<path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
						</svg>
					</button>
				</div>
			</div>
			<div className="flex flex-row">
				<div className="text-neutral-500 flex flex-col">
					<span className="flex flex-row gap-1 items-center">
						{SourceMap[source.type]?.text} {SourceMap[source.type]?.textComponent}
					</span>
					<span>{source.description}</span>
				</div>
			</div>
			{deletingSource && (
				<div className="flex flex-col mt-2 transition-all">
					<div className="text-bold-500">Are you sure you want to delete this source?</div>
					<div className="flex flex-row mt-2 gap-2">
						<Button color="red" variant="outline" onClick={handleDeleteOnClick}>
							Delete
						</Button>
						<Button onClick={() => setDeletingSource(false)}>Cancel</Button>
					</div>
				</div>
			)}
			<div
				className={`absolute w-full h-full transition-[background,opacity] top-0 left-0 rounded-md z-20 animate-fadeIn pointer-events-none ${
					disabled ? 'bg-white opacity-80' : 'bg-transparent opacity-0'
				}`}
			>
				{disabled ? <LoadingSpinner /> : <></>}
			</div>
		</div>
	);
};
