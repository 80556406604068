import { PropsWithChildren } from 'react';
import { Spinner } from './Spinner';

export const LoadingSpinner = ({ children }: PropsWithChildren) => {
	return (
		<>
			<div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
				<div className=" animate-spin mx-auto">
					<Spinner />
				</div>
			</div>
			<div className="absolute top-1/2 w-full">{children}</div>
		</>
	);
};
