import { useAuthUserData } from 'features/auth';
import { useUserSetupIntent, USER_SETUP_INTENT_QUERY_KEY } from './useUserSetupIntent';
import { useUpdateUserPaymentMethods } from './useUpdateUserPaymentMethods';
import { useDeletePaymentMethod } from './useDeletePaymentMethod';
import { useUserPaymentInfo, UserPaymentInfoKey } from './useUserPaymentInfo';

export const useUserPaymentControls = () => {
	const { userId } = useAuthUserData();
	const { clientSecret } = useUserSetupIntent();
	const { mutate: mutateUpdatePM, isLoading: updatePMIsLoading } = useUpdateUserPaymentMethods();
	const { mutate: mutateDeletePM } = useDeletePaymentMethod();
	const { paymentMethods, refetch, isFetching } = useUserPaymentInfo();

	return {
		clientSecret: clientSecret || '',
		onUpdatePaymentMethod: (cardId: string) => mutateUpdatePM({ userId, paymentMethodId: cardId }),
		updatePMIsLoading,
		onDeletePaymentMethod: (cardId: string) => mutateDeletePM({ paymentMethodId: cardId }),
		paymentInfoKey: UserPaymentInfoKey,
		setupIntentKey: USER_SETUP_INTENT_QUERY_KEY,
		paymentMethods,
		refetch,
		isFetching,
	};
};
