import { useWorkspacePaymentInfo } from '../api/useWorkspacePaymentInfo';
import { useWorkspaceSubscriptionPlans } from '../api/useWorkspaceSubscriptionPlans';
import { CONTACT } from '../constants';
import { PlanCard } from './PlanCard';

export const PlanOptions = ({
	currentPlan,
	recommendedPlan,
}: {
	currentPlan?: string;
	recommendedPlan?: string;
}) => {
	const { paymentMethods } = useWorkspacePaymentInfo();
	const { allPlans } = useWorkspaceSubscriptionPlans();
	const hasDefaultPaymentMethod = paymentMethods?.some((card) => card.is_default);

	const currentPlanObject = allPlans?.find(
		(planObject) => currentPlan === planObject.name.toLowerCase(),
	);
	const processedPlans = currentPlanObject?.is_custom ? [currentPlanObject] : allPlans;
	return (
		<>
			{!hasDefaultPaymentMethod && (
				<div className="text-sm font-semibold text-yellow-700">
					{' '}
					A default payment method is required to choose a plan!
				</div>
			)}
			<div className="w-full">
				{processedPlans
					?.filter((sPlan) => sPlan.show_on_UI)
					.map((plan) => (
						<PlanCard
							plan={plan}
							isCurrent={currentPlan === plan.name.toLowerCase()}
							isRecommended={recommendedPlan === plan.name.toLowerCase()}
						/>
					))}
				<PlanCard
					plan={CONTACT}
					isCurrent={currentPlan === CONTACT.name}
					isRecommended={recommendedPlan === CONTACT.name}
				/>
			</div>
		</>
	);
};
