import { axios } from 'libs/axios';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useQuery } from 'react-query';
import { Logs } from '../types';

export const scheduleLogsData = async ({
	workspaceId,
	offset,
}: {
	workspaceId: string;
	offset: number;
}): Promise<Logs[]> => {
	const { data } = await axios.get(`/logs/${workspaceId}/${offset}`);
	return data;
};

type LogsFnType = typeof scheduleLogsData;

type UseLogsDataOptions = {
	workspaceId: string;
	offset: number;
	config?: QueryConfig<LogsFnType>;
};

export const useScheduleLogs = ({ workspaceId, offset, config }: UseLogsDataOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<LogsFnType>>({
		...config,
		queryKey: [workspaceId, offset],
		queryFn: () => scheduleLogsData({ workspaceId, offset }),
	});
	return {
		logs: data || [],
		...rest,
	};
};
