import { Button } from 'components/Button';
import { useAtom } from 'jotai';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useUserPaymentControls } from 'features/billing/api/useUserPaymentControls';
import { useState, useEffect } from 'react';
import { useAuthUserData } from 'features/auth';
import { useToastNotification } from 'hooks/useToastNotification';
import { queryClient } from 'libs/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useUserData } from 'features/account/api/userData';
import { Payment } from './createWorkspaceComponents/Payment';
import { Plans } from './createWorkspaceComponents/Plans';
import { activeWorkspaceAtom } from '../atoms';
import { Name } from './createWorkspaceComponents/Name';
import { currentPageAtom } from './createWorkspaceComponents/atoms';
import { Confirmation } from './createWorkspaceComponents/Confirmation';
import { useUserWorkspaces } from '../api/userWorkspaces';
import { useCreateWorkspace } from '../api/createWorkspace';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

type FormValues = {
	name: string;
	plan: string;
	hasPaymentMethod: boolean;
	chosenPaymentMethodId?: string;
};

const wizardSteps = {
	name: {
		order: 1,
		instruction: 'Create your new workspace',
		content: <Name />,
		valueRequired: 'name',
	},
	payment: {
		order: 2,
		instruction: 'Choose a payment method',
		content: <Payment />,
		valueRequired: 'hasPaymentMethod',
	},
	plan: {
		order: 3,
		instruction: 'Choose a plan',
		content: <Plans />,
		valueRequired: 'plan',
	},
	confirmation: {
		order: 4,
		instruction: 'Confirm workspace details',
		content: <Confirmation />,
	},
};
export const CreateWorkspaceWizard = () => {
	const methods = useForm<FormValues>();
	const { watch, setValue, reset } = methods;
	const { userId } = useAuthUserData();
	const { trialEligible, refetch: refetchUserData } = useUserData({ userId });
	const { clientSecret } = useUserPaymentControls();
	const { refetch: refetchWorkspaces } = useUserWorkspaces({ userId });

	const [, setActiveWorskpace] = useAtom(activeWorkspaceAtom);
	const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
	const [endOfSteps, setEndOfSteps] = useState(false);

	const navigate = useNavigate();
	const toast = useToastNotification();

	const navigateToWorkspace = (workspaceId: string) => {
		setActiveWorskpace(workspaceId);
		navigate(`/${workspaceId}/schedule`);
	};
	const currentStep = wizardSteps[currentPage as keyof typeof wizardSteps];

	const progressBarWidth = ((currentStep.order / Object.keys(wizardSteps).length) * 100)
		.toFixed()
		.toString();

	const pageIsComplete = () => {
		if ('valueRequired' in currentStep) {
			const valueRequired = watch(currentStep?.valueRequired as keyof FormValues);
			return !!valueRequired;
		}
		return true;
	};

	const onNextWizardPage = () => {
		const nextStepOrder = currentStep.order + 1;
		const [stepName] =
			Object.entries(wizardSteps).find(([, stepInfo]) => stepInfo.order === nextStepOrder) || [];
		setCurrentPage(stepName || '');
	};
	const onPreviousWizardPage = () => {
		const previousStepOrder = currentStep.order - 1;
		const [stepName] =
			Object.entries(wizardSteps).find(([, stepInfo]) => stepInfo.order === previousStepOrder) ||
			[];
		setCurrentPage(stepName || '');
	};

	const createWorkspaceQuery = useCreateWorkspace({
		config: {
			onSuccess: async (data, _, __) => {
				await refetchWorkspaces();
				await refetchUserData();
				navigateToWorkspace(data.id);
				setCurrentPage('name');
			},
			onError(error, _, __) {
				toast.notifyFailure('Failed to create new workspace');
			},
			onSettled(data, _) {
				reset();
			},
		},
	});

	const onCreateWorkspace = () => {
		const createWorkspaceArgs: {
			name: string;
			subscription: string;
			plan: string;
			user_id: string;
			payment_method_id?: string;
		} = {
			name: watch('name'),
			subscription: '',
			plan: watch('plan').toLowerCase(),
			user_id: userId,
		};
		const chosenPmId = watch('chosenPaymentMethodId');
		if (chosenPmId) {
			createWorkspaceArgs.payment_method_id = chosenPmId;
		}
		createWorkspaceQuery.mutate(createWorkspaceArgs);
	};

	useEffect(() => {
		setEndOfSteps(currentStep.order === Object.keys(wizardSteps).length);
		if (trialEligible) {
			setEndOfSteps(true);
			setValue('plan', 'pro');
		}
	}, [trialEligible, setEndOfSteps, currentStep, setValue]);

	if (!clientSecret) return null;

	return (
		<Elements stripe={stripePromise} options={{ clientSecret }}>
			<FormProvider {...methods}>
				<div className="max-w-[40vw]">
					<div className="text-2xl font-bold text-center mb-2">{currentStep.instruction}</div>
					<div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
						<div
							className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"
							style={{ width: `${progressBarWidth}%` }}
						/>
					</div>
					{currentStep.content}
					<div className="flex">
						{currentStep.order > 1 && (
							<Button
								onClick={onPreviousWizardPage}
								variant="outline"
								className="mt-6 mr-auto flex w-min"
							>
								Back
							</Button>
						)}
						{endOfSteps && pageIsComplete() ? (
							<Button
								onClick={onCreateWorkspace}
								loading={createWorkspaceQuery.isLoading}
								className="mt-6 ml-auto flex w-min"
							>
								Create Workspace
							</Button>
						) : (
							<Button
								className="mt-6 ml-auto flex w-min"
								onClick={onNextWizardPage}
								disabled={!pageIsComplete()}
							>
								Next
							</Button>
						)}
					</div>
				</div>
			</FormProvider>
		</Elements>
	);
};
