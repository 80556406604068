import { Invite } from '../types';

export interface PendingInviteRowProps {
	invite: Invite;
	emailColWidth: string;
	roleColWidth: string;
	inviterNameColWidth: string;
	actionColWidth: string;
	deletable?: boolean;
	onDeleteHandler: (inviteId: string) => void;
}

export const PendingInviteRow = ({
	invite,
	emailColWidth,
	roleColWidth,
	inviterNameColWidth,
	actionColWidth,
	onDeleteHandler,
	deletable,
}: PendingInviteRowProps) => {
	return (
		<div className="w-full h-fit animate-fadeIn flex flex-row font-bold text-xs p-2 my-1 transition-[background,box-shadow,border] hover:shadow-lg rounded-md leading-tight border border-neutral-300 hover:bg-white hover:border text-neutral-700 focus:outline-none focus:bg-white hover:border-neutral-400 group">
			<div className="truncate" style={{ width: emailColWidth }}>
				{invite.email}
			</div>
			<div className="capitalize truncate" style={{ width: roleColWidth }}>
				{invite.role}
			</div>
			<div className="capitalize truncate" style={{ width: inviterNameColWidth }}>
				{invite.inviter_name}
			</div>
			{!deletable ? (
				<></>
			) : (
				<button
					type="button"
					className="transition-all opacity-0 h-full flex justify-center text-neutral-400 hover:text-red-500 active:text-red-600 group-hover:opacity-100"
					style={{ width: actionColWidth }}
					onClick={() => {
						onDeleteHandler(invite.id);
					}}
				>
					Remove
				</button>
			)}
		</div>
	);
};
