import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useMemo } from 'react';
import { UserWorkspaceIdentifier } from '../types';

export const getUserWorkspaces = async (userId: string) => {
	const { data } = await axios.get<UserWorkspaceIdentifier[]>(`/workspace/user/${userId}`);
	return data;
};

export const USER_WORKSPACES_KEY = 'user-workspace-ids';

type QueryFnType = typeof getUserWorkspaces;

type UseGetUserWorkspacesOptions = {
	userId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useUserWorkspaces = ({ userId, config }: UseGetUserWorkspacesOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: USER_WORKSPACES_KEY,
		queryFn: () => getUserWorkspaces(userId),
	});

	const workspaces = useMemo(() => {
		return (data || []).sort((a, b) =>
			a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
		);
	}, [data]);

	return {
		...rest,
		workspaces,
	};
};
