import { ErrorFallback } from 'components/ErrorFallback';
import { AuthProvider } from 'features/auth';
import { NotificationProvider } from 'features/notification';

import { WorkspaceContainer } from 'features/workspace/WorkspaceContainer';
import { queryClient } from 'libs/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';

function App() {
	return (
		<div className="h-screen relative">
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<AuthProvider>
							<NotificationProvider>
								<WorkspaceContainer>
									<AppRoutes />
								</WorkspaceContainer>
							</NotificationProvider>
						</AuthProvider>
					</BrowserRouter>
				</QueryClientProvider>
			</ErrorBoundary>
		</div>
	);
}
export default App;
