import { axios } from 'libs/axios';
import { MutationConfig } from 'libs/react-query';
import { useMutation } from 'react-query';

export const getQueryForm = async ({
	sourceType,
	sourceId,
	query,
	form,
}: {
	sourceType: string;
	sourceId: string;
	query: {
		key: string;
		value: string;
	};
	[field: string]: any;
}) => {
	const { data } = await axios.post(`/query/builder`, {
		source_type: sourceType,
		source_id: sourceId,
		query: query || {},
		form,
	});
	return data;
};

export const useQueryForm = (config: MutationConfig<typeof getQueryForm> = {}) => {
	return useMutation({
		...config,
		mutationFn: getQueryForm,
	});
};
