import { axios } from 'libs/axios';
import { FormSelectValue } from 'components/Form';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useWorkspaceData } from 'features/workspace';

type InvoiceHistoryResponse = {
	amount_due: number;
	amount_paid: number;
	closing_date: number;
	period_start: number;
}[];

export const fetchInvoiceHistory = async (workspaceId: string, period: FormSelectValue) => {
	const { data } = await axios.get<InvoiceHistoryResponse>(
		`/workspace/invoices/${workspaceId}?period=${period.id}`,
	);
	return data;
};

export const useInvoiceHistory = ({
	period,
	config,
}: {
	period: FormSelectValue;
	config?: QueryConfig<typeof fetchInvoiceHistory>;
}) => {
	const { currentWorkspaceId } = useWorkspaceData();
	const response = useQuery<ExtractFnReturnType<typeof fetchInvoiceHistory>>({
		...config,
		queryKey: ['invoiceHistory', currentWorkspaceId],
		queryFn: () => fetchInvoiceHistory(currentWorkspaceId, period),
	});
	return {
		previousInvoices: response?.data || [],
		...response,
	};
};
