export const TransferOwnership = () => {
	return (
		<div>
			To learn more about transferring workspace ownership, please visit our{' '}
			<a className="text-blue-400 hover:underline" href="https://www.castodia.com/faq">
				FAQ
			</a>{' '}
			page or{' '}
			<a className="text-blue-400 hover:underline" href="mailto:support@castodia.com">
				contact us
			</a>
			.
		</div>
	);
};
