import { HTMLProps } from 'react';

interface FormSwitchProps extends Omit<HTMLProps<HTMLButtonElement>, 'value'> {
	value: boolean;
}

export const FormSwitch = ({ value, ...props }: FormSwitchProps) => {
	const className = `border w-[38px] rounded-full relative h-full ${props.className ?? ''}`;
	return (
		<button {...props} type="button" className={className}>
			<div
				className={`${
					value ? 'bg-green-400' : 'bg-neutral-400'
				}  w-full rounded-full h-full absolute top-0 z-0 transition-colors`}
			/>
			<div
				className={`${
					value ? ' translate-x-[17px]' : 'translate-x-0'
				} bg-white w-[19px] h-full rounded-full z-10 top-0 absolute transition-all`}
			/>
		</button>
	);
};
