import { Input } from '@dropbaseio/components';
import { Button } from 'components/Button';
import { FormFieldSkeleton } from 'components/Form';
import { useAuthUserData } from 'features/auth';
import { CancelSubscription } from 'features/billing/components/CancelSubscription';
import { useWorkspaceData } from 'features/workspace';
import { useLeaveWorkspace } from 'features/workspace/api/leaveWorkspace';
import { useWorkspaceInfo } from 'features/workspace/api/workspaceInfo';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCancelSubscription } from '../api/cancelSubscription';
import { DeleteWorkspaceModal } from './DeleteWorkspaceModal';

export const DeleteWorkspaceControl = () => {
	const [deleteConfirmValue, setDeleteConfirmValue] = useState('');
	const { userId } = useAuthUserData();
	const navigate = useNavigate();

	const { currentWorkspaceId, currentWorkspace, resetWorkspace, userRole } = useWorkspaceData();
	const { workspaceInfo, isFetching } = useWorkspaceInfo({ workspaceId: currentWorkspaceId });
	const toast = useToastNotification();

	const cancelSubscriptionQuery = useCancelSubscription({
		config: {
			onSuccess() {
				toast.notifySuccess('Successfully cancelled subscription');
			},
			onError(error, variables, context) {
				toast.notifyFailure('Failed to cancel subscription');
			},
		},
	});

	const leaveWorkspaceMutation = useLeaveWorkspace({
		config: {
			onSuccess: () => {
				navigate('/');
				toast.notifySuccess('Successfully left workspace');
			},
			onError: () => {
				toast.notifyFailure('Failed to leave workspace');
			},
		},
	});

	const handleLeaveWorkspace = async () => {
		leaveWorkspaceMutation.mutate({
			workspaceId: currentWorkspace?.workspace_id || '',
			userId,
		});
	};
	const workspaceIsCancelled =
		currentWorkspace?.status === 'canceled' || workspaceInfo?.marked_for_cancellation;

	const isOwner = userRole.toLowerCase() === 'owner';

	const deleteWorkspaceComponent = workspaceIsCancelled ? (
		<DeleteWorkspaceModal />
	) : (
		<CancelSubscription className="w-full animate-fadeIn" />
	);

	if (isFetching) {
		return (
			<div>
				<FormFieldSkeleton />
			</div>
		);
	}

	return (
		<div>
			{isOwner ? (
				deleteWorkspaceComponent
			) : (
				<div className="w-full">
					<Button
						color="red"
						variant="outline"
						className="my-2"
						onClick={() => handleLeaveWorkspace()}
						loading={leaveWorkspaceMutation.isLoading}
					>
						Leave Workspace
					</Button>
				</div>
			)}
		</div>
	);
};
