import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useWorkspaceData } from 'features/workspace';

type SetupIntentResponse = {
	client_secret: string;
};

export const fetchWorkspaceSetupIntent = async (workspaceId: string) => {
	const { data } = await axios.get<SetupIntentResponse>(`/workspace/setup_payment/${workspaceId}`);
	return data;
};

export const WORKSPACE_SETUP_INTENT_QUERY_KEY = 'workspace-setup-intent';

export const useWorkspaceSetupIntent = (config?: QueryConfig<typeof fetchWorkspaceSetupIntent>) => {
	const { currentWorkspaceId } = useWorkspaceData();
	const queryKey = [WORKSPACE_SETUP_INTENT_QUERY_KEY, currentWorkspaceId];
	const { data, ...rest } = useQuery<ExtractFnReturnType<typeof fetchWorkspaceSetupIntent>>({
		...config,
		queryKey,
		queryFn: () => fetchWorkspaceSetupIntent(currentWorkspaceId),
	});
	return {
		clientSecret: data?.client_secret,
		...rest,
	};
};
