import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { useWorkspaceData } from 'features/workspace';
import { Card } from '../types';

type BillingInfoResponse = {
	upcoming_invoice: {
		monthy_fee: number;
		one_time_fee: number;
		start_date: number;
		end_date: number;
		usage_fee: number;
		total_fee: number;
		next_payment_attempt: number;
	};
	usage_for_current_cycle: number;
	usage_for_past_year: any[];
	payment_methods: Card[];

	previous_invoices: {
		amount_due: number;
		amount_paid: number;
		closing_date: number;
		period_start: number;
	}[];
	last_usage_sync: { synced_at: string };
};

export const fetchBillingInfo = async (workspaceId: string) => {
	const { data } = await axios.get<BillingInfoResponse>(`/workspace/usage/${workspaceId}`);
	return data;
};

export const useBillingInfo = (config?: QueryConfig<typeof fetchBillingInfo>) => {
	const { currentWorkspaceId } = useWorkspaceData();
	const { data, ...rest } = useQuery<ExtractFnReturnType<typeof fetchBillingInfo>>({
		...config,
		queryKey: ['billingInfo', currentWorkspaceId],
		queryFn: () => fetchBillingInfo(currentWorkspaceId),
	});
	return {
		upcomingInvoice: data?.upcoming_invoice,
		usageForCurrentCycle: data?.usage_for_current_cycle,
		usageForPastYear: data?.usage_for_past_year,
		paymentMethods: data?.payment_methods || [],
		lastUsageSync: data?.last_usage_sync?.synced_at,
	};
};
