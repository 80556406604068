import { USER_WORKSPACES_KEY } from 'features/workspace/api/userWorkspaces';
import { UserWorkspaceIdentifier } from 'features/workspace/types';
import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';

export const updateWorkspacePlan = ({
	workspaceId,
	plan,
}: {
	workspaceId: string;
	plan: string;
}) => {
	return axios.put(`/workspace/${workspaceId}/plan?plan=${plan.toLowerCase()}`);
};

type UseUpdateWorkspacePlanOptions = {
	config?: MutationConfig<typeof updateWorkspacePlan>;
};

export const useUpdateWorkspacePlan = ({ config }: UseUpdateWorkspacePlanOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			const workspaces = structuredClone(
				queryClient.getQueryData<UserWorkspaceIdentifier[]>(USER_WORKSPACES_KEY),
			);
			const updatedWorkspaceIndex = workspaces?.findIndex(
				(x) => x.workspace_id === variables.workspaceId,
			);
			if (
				workspaces !== undefined &&
				updatedWorkspaceIndex !== undefined &&
				updatedWorkspaceIndex !== -1
			) {
				workspaces[updatedWorkspaceIndex].plan = variables.plan;
				queryClient.setQueryData(USER_WORKSPACES_KEY, workspaces);
			}
		},
		mutationFn: updateWorkspacePlan,
	});
};
