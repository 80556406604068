import { FormSelect, FormSelectValue } from 'components/Form';
import { useAuthUserData } from 'features/auth';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { WORKSPACE_ROLE } from 'utils';
import { useUpdateRole } from '../api/updateRole';
import { Role, UpdateRoleDto } from '../types';

export interface MemberRowProps {
	role: Role;
	usernameColWidth: string;
	emailColWidth: string;
	roleColWidth: string;
	actionColWidth: string;
	allRoleOptions: FormSelectValue[];
	selectableRoleOptions: FormSelectValue[];
	onRemoveHandler: (roleId: string) => void;
	onLeaveWorkspace: () => void;
}

export const MemberRow = ({
	usernameColWidth,
	emailColWidth,
	roleColWidth,
	actionColWidth,
	role,
	allRoleOptions,
	selectableRoleOptions,
	onRemoveHandler,
	onLeaveWorkspace,
}: MemberRowProps) => {
	const getMatchingRole = (target: string, options: FormSelectValue[]) => {
		const result = options.find((r) => r.id === target);
		return result === undefined ? options[0] : result;
	};

	const toastNotification = useToastNotification();
	const [currentRole, setCurrentRole] = useState(getMatchingRole(role.role, allRoleOptions));
	const { userId } = useAuthUserData();
	const { userRole, currentWorkspaceId } = useWorkspaceData();
	const updateRole = useUpdateRole({
		config: {
			onError(error: any) {
				toastNotification.notifyFailure(error?.response?.data?.detail?.message);
			},
		},
	});

	const handleOnRoleChange = async (newRole: FormSelectValue) => {
		if (newRole.id === role.role) {
			return;
		}

		const updateRoleDto: UpdateRoleDto = {
			roleId: role.id,
			newRole: newRole.id,
			workspaceId: currentWorkspaceId,
		};
		const response = await updateRole.mutateAsync(updateRoleDto);
		if (response !== undefined) {
			setCurrentRole(newRole);
			toastNotification.notifySuccess(`Successfully updated role to ${newRole.text}`);
		}
	};

	const isSelf = () => {
		return userId === role.user_id;
	};

	const isOwner = () => {
		return role.role === WORKSPACE_ROLE.OWNER;
	};

	const isUserMember = () => {
		return userRole === WORKSPACE_ROLE.MEMBER;
	};
	const isNotOwner = () => {
		return role.role !== WORKSPACE_ROLE.OWNER;
	};

	const canDelete = () => {
		return userRole === WORKSPACE_ROLE.OWNER || userRole === WORKSPACE_ROLE.ADMIN;
	};

	return (
		<div className="w-full h-fit animate-fadeIn flex flex-row font-bold text-xs p-2 my-1 transition-[background,box-shadow,border] hover:shadow-lg rounded-md leading-tight border border-neutral-300 hover:bg-white hover:border text-neutral-700 focus:outline-none focus:bg-white hover:border-neutral-400 group">
			<div className="truncate" style={{ width: emailColWidth }}>
				{role.email}
			</div>
			<div className="truncate capitalize" style={{ width: usernameColWidth }}>
				{role.name}
			</div>
			<div className="capitalize" style={{ width: roleColWidth }}>
				<FormSelect
					id="a"
					selectItems={selectableRoleOptions}
					value={currentRole}
					onChangeHandler={handleOnRoleChange}
					inline
					disabled={isSelf() || isOwner() || isUserMember()}
				/>
			</div>

			{isSelf() && isNotOwner() ? (
				<button
					type="button"
					className="transition-all opacity-0 h-full flex justify-center text-neutral-400 hover:text-red-500 active:text-red-600 group-hover:opacity-100"
					style={{ width: actionColWidth }}
					onClick={() => onLeaveWorkspace()}
				>
					Leave
				</button>
			) : null}

			{canDelete() && !isSelf() && !isOwner() ? (
				<button
					type="button"
					className="transition-all opacity-0 h-full flex justify-center text-neutral-400 hover:text-red-500 active:text-red-600 group-hover:opacity-100"
					style={{ width: actionColWidth }}
					onClick={() => onRemoveHandler(role.id)}
				>
					Remove
				</button>
			) : (
				<></>
			)}
		</div>
	);
};
