import { axios } from 'libs/axios';
import { ExtractFnReturnType, queryClient, QueryConfig } from 'libs/react-query';
import { useQuery } from 'react-query';
import { ScheduleResponse, Schedule } from '../types';

export const getSchedule = async ({
	scheduleId,
	userId,
}: {
	scheduleId: string;
	userId: string;
}) => {
	if (scheduleId === '-1') {
		return undefined;
	}
	const { data } = await axios.get<Schedule>(`/schedule_v2/${scheduleId}?user_id=${userId}`);
	return data;
};

type QueryFnType = typeof getSchedule;

type UseScheduleOptions = {
	scheduleId: string;
	userId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useSchedule = ({ scheduleId, userId, config }: UseScheduleOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: ['schedule', scheduleId],
		queryFn: () => getSchedule({ scheduleId, userId }),
		cacheTime: 0,
		staleTime: 0,
	});
	return {
		schedule: data,
		...rest,
	};
};

export const getScheduleData = (scheduleId: string): ScheduleResponse | undefined => {
	return queryClient.getQueryData(['schedule', scheduleId]);
};
