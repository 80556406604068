import { Input } from '@dropbaseio/components';
import { Button } from 'components/Button';
import { LoadingSpinner, SectionHeader } from 'components/Common';
import { Tooltip } from 'components/Common/Tooltip';
import { FormFieldSkeleton } from 'components/Form';
import { ContentLayout } from 'components/Layout';
import { useAuthUserData } from 'features/auth';
import { FullscreenModal } from 'features/modal';
import { useWorkspaceData } from 'features/workspace';
import { useToastNotification } from 'hooks/useToastNotification';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CardOptions } from 'features/billing/components/CardOptions';
import { useUserPaymentControls } from 'features/billing/api/useUserPaymentControls';
import { useAuthorize } from './api/authorize';
import { useDeleteAccount } from './api/deleteAccount';
import { useUserData } from './api/userData';
import { useValidateAuthAccess } from './api/validateAuth';

export const AccountPage = () => {
	const { userJWT, userId, clearData } = useAuthUserData();
	const { resetWorkspace } = useWorkspaceData();
	const [missingScopes, setMissingScopes] = useState<string[]>([]);
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const userPaymentControls = useUserPaymentControls();
	const toastNotification = useToastNotification();
	const { pathname } = useLocation();

	const { name, email } = useUserData({
		userId,
		config: {
			refetchOnMount: true,
			staleTime: 0,
		},
	});

	const { isFetching: validateAuthIsFetching } = useValidateAuthAccess({
		jwtToken: userJWT,
		config: {
			onSuccess(data) {
				// console.log(data);
				setIsAuthorized(data.valid);
				setMissingScopes(data.missing_scopes);
			},
			staleTime: 0,
			refetchOnMount: true,
		},
	});

	const { mutateAsync } = useAuthorize({
		config: {
			onSuccess() {
				toastNotification.notifySuccess('Successfully authorized');
			},
			onError() {
				setIsAuthorized(false);
				toastNotification.notifyFailure('Failed to authorize');
			},
		},
	});

	const handleGoogleAuthResponse = async (response: any) => {
		// console.log(response);
		mutateAsync(response.code);
	};

	// @ts-expect-error error: err
	const client = google.accounts.oauth2.initCodeClient({
		client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
		scope: 'https://www.googleapis.com/auth/spreadsheets',
		access_type: 'offline',
		ux_mode: 'popup',
		callback: handleGoogleAuthResponse,
	});

	const handleAuth = () => {
		client.requestCode();
	};

	const renderMissingScopes = () => {
		return missingScopes.length === 0
			? 'Current status: No permission granted'
			: `Missing scopes: ${missingScopes.toString()}`;
	};

	const renderAuthSection = () => {
		return (
			<>
				<div className="text-sm font-semibold text-neutral-700 my-2">
					{isAuthorized
						? 'Permission granted!'
						: 'Grant permission for Castodia to modify your Google Sheets'}
					<div>{!isAuthorized ? renderMissingScopes() : ''}</div>
				</div>
				<Tooltip label={isAuthorized ? 'Already authorized' : undefined}>
					<Button onClick={handleAuth} disabled={isAuthorized}>
						Authorize
					</Button>
				</Tooltip>
			</>
		);
	};

	const [deleteConfirmValue, setDeleteConfirmValue] = useState('');
	const getDeleteConfirmPhrase = `delete ${email}`;

	const navigate = useNavigate();

	const { mutate: mutateDeleteAccount, isLoading: isLoadingDeleteAccount } = useDeleteAccount({
		config: {
			onSuccess() {
				// console.log('Success');
				toastNotification.notifySuccess('Successfully deleted account');
				navigate('/');
				resetWorkspace();
				clearData();
				// console.log('cleared');
			},
			onError(error, _, __) {
				// console.log(error);
				const errorMsg = error.response ? (error.response as any).data.detail.message : '';
				toastNotification.notifyFailure(`Failed to delete account\n${errorMsg}`);
			},
		},
	});

	const navigateBack = () => {
		navigate('/workspaces');
	};

	return (
		<ContentLayout title="Profile" description="Manage your account">
			{pathname === '/account' ? (
				<button
					type="button"
					onClick={navigateBack}
					className="flex flex-row items-center transition-colors text-neutral-500 gap-2 font-semibold text-sm leading-loose mb-3 hover:text-neutral-900 w-fit"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-5 h-5"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
						/>
					</svg>
					Back
				</button>
			) : null}
			<div className="flex flex-col gap-4">
				<div>
					<SectionHeader text="User" />
					{name === undefined ? (
						<FormFieldSkeleton />
					) : (
						<div className="flex flex-row justify-between">
							<div className="flex flex-col text-sm text-neutral-700 font-semibold">
								<span>Currently signed in as: </span>
								<span>Name: {name}</span>
								<span>Email: {email}</span>
							</div>
							<Link to="/">
								<Button type="button" color="neutral" variant="outline" onClick={clearData}>
									Sign out
								</Button>
							</Link>
						</div>
					)}
				</div>
				<br />
				<div>
					<SectionHeader text="Authorization" />
					{validateAuthIsFetching ? (
						<div className="h-20 relative animate-fadeIn">
							<LoadingSpinner>
								<div className="font-semibold text-sm text-neutral-700 text-center p-4">
									Checking authorization
								</div>
							</LoadingSpinner>
						</div>
					) : (
						renderAuthSection()
					)}
				</div>
				<div>
					<SectionHeader text="Payment Methods" />
					<CardOptions {...userPaymentControls} />
				</div>
				<br />
				<div>
					<SectionHeader text="Delete" />
					{name === undefined ? (
						<FormFieldSkeleton />
					) : (
						<>
							<div className="w-full">
								<Button
									color="red"
									variant="outline"
									className="my-2"
									onClick={() => setShowConfirmDelete(true)}
									loading={isLoadingDeleteAccount}
								>
									Delete account
								</Button>
							</div>
							{showConfirmDelete && (
								<FullscreenModal onClose={() => setShowConfirmDelete(false)}>
									<div className="font-medium text-sm text-neutral-700 flex flex-col gap-2">
										<h3 className="text-lg text-neutral-900 font-bold">
											Are you sure you want to delete your account?
										</h3>
										<p>
											Warning: Your account, your workspace, and everything associated with your
											account will be deleted!
										</p>
										<p>
											Please type <strong>{getDeleteConfirmPhrase}</strong> to confirm.
										</p>
									</div>
									<br />
									<div className="flex flex-col gap-2">
										<Input
											id="account-delete-input"
											type="text"
											label=""
											placeholder=""
											value={deleteConfirmValue}
											onChange={(event) => setDeleteConfirmValue(event.target.value)}
										/>
										<Tooltip
											label={
												deleteConfirmValue !== getDeleteConfirmPhrase || isLoadingDeleteAccount
													? 'Delete phrase mismatch'
													: undefined
											}
										>
											<Button
												color="red"
												variant="outline"
												className="mt-2"
												onClick={() => mutateDeleteAccount(userId)}
												disabled={
													deleteConfirmValue !== getDeleteConfirmPhrase || isLoadingDeleteAccount
												}
												loading={isLoadingDeleteAccount}
											>
												Delete account
											</Button>
										</Tooltip>
									</div>
								</FullscreenModal>
							)}
						</>
					)}
				</div>
			</div>
		</ContentLayout>
	);
};
