import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { UpdateQueryDTO } from '../types';
import { WORKSPACE_QUERY_ID_KEY } from './getWorkspaceQuery';

export const updateQuery = ({ id, payload }: UpdateQueryDTO) => {
	return axios.put(`/query/${id}`, payload);
};

type UseUpdateQueryOptions = {
	config?: MutationConfig<typeof updateQuery>;
};

export const useUpdateQuery = ({ config }: UseUpdateQueryOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(WORKSPACE_QUERY_ID_KEY);
		},
		mutationFn: updateQuery,
	});
};
