import { axios } from 'libs/axios';
import { ExtractFnReturnType, queryClient, QueryConfig } from 'libs/react-query';
import { useQuery } from 'react-query';
import { Query } from '../types';

interface GetQueryResponse {
	values: {
		[key: string]: any;
	};
	inputs: {
		[key: string]: any;
	}[];
	query: Query;
}

export const getQuery = async (queryId: string) => {
	if (queryId === '-1') {
		return undefined;
	}
	const { data } = await axios.get<GetQueryResponse>(`/query/${queryId}`);
	return data;
};

type QueryFnType = typeof getQuery;

type UseQueryOptions = {
	queryId: string;
	config?: QueryConfig<QueryFnType>;
};

export const useGetQuery = ({ queryId, config }: UseQueryOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: ['query', queryId],
		queryFn: () => getQuery(queryId),
	});
	return {
		values: data?.values,
		inputs: data?.inputs || [],
		query: data?.query,
		...rest,
	};
};

export const getSavedQueryData = (queryId: string) => {
	return queryClient.getQueryData<Query>(['query', queryId]);
};

export const InvalidateSavedQueryData = (queryId: string) => {
	queryClient.invalidateQueries(['query', queryId]);
};
