import Axios from 'axios';

export const axios = Axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export const addCustomHeader = (key: string, value: string) => {
	axios.defaults.headers.common[key] = value;
};

export const setAuthorizationHeader = (token: string) => {
	addCustomHeader('Authorization', `Bearer ${token}`);
};
export const attachWorkspaceIdToAxios = (id: string) => {
	addCustomHeader('X-workspace-id', id);
};
