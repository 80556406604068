import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { UpdateRoleDto } from '../types';
import { ROLES_QUERY_KEY } from './getRoles';

export const updateRole = ({ workspaceId, roleId, newRole }: UpdateRoleDto) => {
	return axios.put(`/role/${roleId}`, { role: newRole, workspace_id: workspaceId });
};

type UseUpdateRoleOptions = {
	config?: MutationConfig<typeof updateRole>;
};

export const useUpdateRole = ({ config }: UseUpdateRoleOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(ROLES_QUERY_KEY);
		},

		mutationFn: updateRole,
	});
};
