import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';

export const deleteAccount = (userId: string) => {
	return axios.delete(`/user/${userId}`);
};

type UseDeleteAccountOptions = {
	config?: MutationConfig<typeof deleteAccount>;
};

export const useDeleteAccount = ({ config }: UseDeleteAccountOptions = {}) => {
	const response = useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries();
		},
		mutationFn: deleteAccount,
	});
	return { ...response };
};
