import { NoPermission } from 'components/Common';
import { ContentLayout } from 'components/Layout';
import { useWorkspaceData } from 'features/workspace';
import { SourceCardList } from './components/SourceCardList';
import { DatabaseFormContrainer } from './components/DatabaseFormContrainer';

export const DatabasePage = () => {
	const workspaceData = useWorkspaceData();

	if (workspaceData.currentWorkspace?.role !== 'owner') {
		return (
			<ContentLayout
				title="Data Sources"
				description="Connect to databases, data warehouses, and API/SaaS Apps"
			>
				<NoPermission />
			</ContentLayout>
		);
	}

	return (
		<ContentLayout
			title="Data Sources"
			description="Connect to databases, data warehouses, and API/SaaS Apps"
			width="w-full"
		>
			<div className="flex flex-row gap-8 animate-fadeIn">
				<div className="w-1/3 min-w-[18rem]">
					<SourceCardList />
				</div>
				<div className="w-1/2 animate-fadeIn">
					<DatabaseFormContrainer />
				</div>
			</div>
		</ContentLayout>
	);
};
