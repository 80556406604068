// @ts-expect-error image
import AirtableLogo from 'assets/sourcelogos/airtable.svg';
// @ts-expect-error image
import HubSpotLogo from 'assets/sourcelogos/hubspot.svg';
// @ts-expect-error image
import StripeLogo from 'assets/sourcelogos/stripe.svg';
// @ts-expect-error image
import MariaDbLogo from 'assets/sourcelogos/mariadb.svg';
// @ts-expect-error image
import MsSqlLogo from 'assets/sourcelogos/mssql.svg';
// @ts-expect-error image
import MySqlLogo from 'assets/sourcelogos/mysql.svg';
// @ts-expect-error image
import OracleLogo from 'assets/sourcelogos/oracle.svg';
// @ts-expect-error image
import PostgresLogo from 'assets/sourcelogos/postgres.svg';
// @ts-expect-error image
import RedshiftLogo from 'assets/sourcelogos/redshift.svg';
// @ts-expect-error image
import SnowflakeLogo from 'assets/sourcelogos/snowflake.svg';
// @ts-expect-error image
import ShopifyLogo from 'assets/sourcelogos/shopify.svg';
import { BetaTag } from 'components/Common/Tags';
import type { FieldSchema, SourceDefinition } from './types';

const GenericDatabaseFields: FieldSchema[] = [
	{
		id: 'host',
		label: 'Host',
		inputType: 'text',
		placeholder: 'database.host.com',
	},
	{
		id: 'username',
		label: 'User',
		inputType: 'text',
		placeholder: 'admin',
	},
	{
		id: 'password',
		label: 'Password',
		inputType: 'password',
		placeholder: 'password',
	},
	{
		id: 'database',
		label: 'Database',
		inputType: 'text',
		placeholder: 'postgres',
	},
];

const SnowflakeFields: FieldSchema[] = [
	{
		id: 'account',
		label: 'Account',
		inputType: 'text',
		placeholder: 'account identifier',
	},
	...GenericDatabaseFields.slice(1, 4),
	{
		id: 'warehouse',
		label: 'Warehouse',
		inputType: 'text',
		placeholder: 'warehouse',
	},
	{
		id: 'role',
		label: 'Role',
		inputType: 'text',
		placeholder: 'role',
	},
];

const AirtableFields: FieldSchema[] = [
	{
		id: 'token',
		label: 'Token',
		inputType: 'text',
		placeholder: 'Personal access token',
	},
];

const HubSpotFields: FieldSchema[] = [
	{
		id: 'token',
		label: 'Access Token',
		inputType: 'text',
		placeholder: 'Private access token',
	},
];

const StripeFields: FieldSchema[] = [
	{
		id: 'api_key',
		label: 'API Key',
		inputType: 'text',
		placeholder: 'API key',
	},
];

const ShopifyFields: FieldSchema[] = [
	{
		id: 'shop_url',
		label: 'Shop URL',
		inputType: 'text',
		placeholder: 'shopname.myshopify.com',
	},
	{
		id: 'access_token',
		label: 'Access Token',
		inputType: 'text',
		placeholder: 'access token',
	},
];

const getDatabaseSchemaWithPort = (port: number): FieldSchema[] => [
	...GenericDatabaseFields,
	{
		id: 'port',
		label: 'Port',
		inputType: 'number',
		placeholder: port.toString(),
		defaultValue: port.toString(),
	},
];

export const REST_SOURCES: SourceDefinition[] = [
	{
		id: 'airtable',
		text: 'Airtable',
		formSchema: AirtableFields,
		iconSrc: AirtableLogo,
		textComponent: BetaTag,
	},
	{
		id: 'hubspot',
		text: 'HubSpot',
		formSchema: HubSpotFields,
		iconSrc: HubSpotLogo,
		textComponent: BetaTag,
	},
	{
		id: 'stripe',
		text: 'Stripe',
		formSchema: StripeFields,
		iconSrc: StripeLogo,
		textComponent: BetaTag,
	},
	{
		id: 'shopify',
		text: 'Shopify',
		formSchema: ShopifyFields,
		iconSrc: ShopifyLogo,
		textComponent: BetaTag,
	},
];

export const DATABASE_SOURCES: SourceDefinition[] = [
	{
		id: 'postgres',
		text: 'Postgres',
		formSchema: getDatabaseSchemaWithPort(5432),
		iconSrc: PostgresLogo,
	},
	{
		id: 'maria',
		text: 'MariaDB',
		formSchema: getDatabaseSchemaWithPort(3306),
		iconSrc: MariaDbLogo,
	},
	{
		id: 'mssql',
		text: 'MS SQL',
		formSchema: getDatabaseSchemaWithPort(1433),
		iconSrc: MsSqlLogo,
	},
	{
		id: 'mysql',
		text: 'MySQL',
		formSchema: getDatabaseSchemaWithPort(3306),
		iconSrc: MySqlLogo,
	},
	{
		id: 'oracle',
		text: 'Oracle',
		formSchema: getDatabaseSchemaWithPort(1521),
		iconSrc: OracleLogo,
	},
	{
		id: 'redshift',
		text: 'Redshift',
		formSchema: getDatabaseSchemaWithPort(5439),
		iconSrc: RedshiftLogo,
	},
	{
		id: 'snowflake',
		text: 'Snowflake',
		formSchema: SnowflakeFields,
		iconSrc: SnowflakeLogo,
	},
];

export const ALL_SOURCES = [...DATABASE_SOURCES, ...REST_SOURCES];

export const SourceMap: Record<string, SourceDefinition> = {
	...Object.fromEntries(REST_SOURCES.map((source) => [source.id, source])),
	...Object.fromEntries(DATABASE_SOURCES.map((source) => [source.id, source])),
};
