import React from 'react';

export interface FormInputNewProp extends React.ComponentProps<'input'> {
	id: string;
	label: string;
}

export const FormInputNew = ({ id, ...props }: FormInputNewProp) => {
	return (
		<div
			className={`w-full h-fit min-w-fit ${
				props.disabled ? 'text-neutral-300' : 'text-neutral-700'
			}`}
		>
			<label className="block tracking-wide text-xs font-bold" htmlFor={id}>
				{props.label && (
					<span className="relative top-2 left-2 bg-white px-1 rounded-md text-[10px]">
						{props.label}
						{props.required && '*'}
					</span>
				)}
				<input
					id={id}
					{...props}
					className={`appearance-none block w-full transition-[background,box-shadow,border] border border-${
						props.color
					}-300 rounded-md py-2.5 px-3 leading-tight focus:outline-none focus:bg-white focus:border-neutral-400 focus:shadow-lg ${
						props.disabled ? 'placeholder-neutral-300' : 'placeholder-neutral-400'
					} ${props.className}`}
				/>
			</label>
		</div>
	);
};
