import { axios } from 'libs/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';

type WorkspaceInfoResponse = {
	id: string;
	name: string;
	plan: string;
	subscription: string;
	user_id: string;
	status: string;
	days_until_trial_end: number;
	marked_for_cancellation: boolean;
};

const getWorkspaceInfo = async (workspaceId: string) => {
	const { data } = await axios.get<WorkspaceInfoResponse>(`/workspace/${workspaceId}`);
	return data;
};

type UseWorkspaceInfoOptions = {
	workspaceId: string;
	config?: QueryConfig<typeof getWorkspaceInfo>;
};

export const WORKSPACE_INFO_KEY = 'workspace-info';

export const useWorkspaceInfo = ({ workspaceId, config }: UseWorkspaceInfoOptions) => {
	const { data, ...rest } = useQuery<ExtractFnReturnType<typeof getWorkspaceInfo>>({
		...config,
		queryKey: [WORKSPACE_INFO_KEY, workspaceId],
		queryFn: () => getWorkspaceInfo(workspaceId),
	});
	return {
		workspaceInfo: data,
		...rest,
	};
};
