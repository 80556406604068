import { useUserPaymentControls } from 'features/billing/api/useUserPaymentControls';
import { queryClient } from 'libs/react-query';
import { AddCard } from 'features/billing/components/AddCard';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

export const Confirmation = () => {
	const { watch } = useFormContext();
	const { paymentMethods } = useUserPaymentControls();
	const defaultPaymentMethod = paymentMethods.find((pm) => pm.is_default);

	return (
		<>
			<div className="flex justify-center">
				<span className="font-bold">Name:</span>&nbsp;{watch('name')}
			</div>
			<div className="flex justify-center">
				<span className="font-bold">Plan:</span>&nbsp;{watch('plan')}
			</div>
			<div className="flex justify-center">
				<span className="font-bold">Payment Method:</span>&nbsp;
				{`Card ending in ${defaultPaymentMethod?.last4}`}
			</div>
		</>
	);
};
