import { createContext } from 'react';
import { ToastNotificationContextType } from './types';

export const ToastNofiticationContext = createContext<ToastNotificationContextType>({
	notifications: [],
	notifySuccess: () => {},
	notifyFailure: () => {},
	notifyWarning: () => {},
	notifyMessage: () => {},
	removeNotification: () => {},
});
