import { useUserSubscriptionPlans } from 'features/billing/api/useUserSubscriptionPlans';
import { LoadingSpinner } from 'components/Common';
import { useFormContext } from 'react-hook-form';
import { PlanCard } from 'features/billing/components/PlanCard';

export const Plans = () => {
	const { allPlans, isLoading } = useUserSubscriptionPlans();
	const { setValue, watch } = useFormContext();

	const buttonProps = {
		text: 'Select this plan',
		onClick: (planName: string) => {
			setValue('plan', planName);
		},
	};
	const chosenPlan = watch('plan');
	if (isLoading) {
		return (
			<div className="w-full mt-10 relative">
				<LoadingSpinner />
			</div>
		);
	}
	return (
		<div className="w-full">
			<div className="flex justify-center">
				<div className="flex">
					<div className="text-base font-bold text-center">Your Plan: </div>
					<div className="ml-2">{chosenPlan}</div>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-4">
				{allPlans
					?.filter((sPlan) => sPlan.show_on_UI)
					.map((plan) => (
						<div className="col-span-1">
							<PlanCard
								plan={plan}
								isCurrent={plan.name === chosenPlan}
								isRecommended={false}
								buttonProps={buttonProps}
							/>
						</div>
					))}
			</div>
		</div>
	);
};
