import { axios } from 'libs/axios';
import { useAuthUserData } from 'features/auth';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from 'libs/react-query';
import { Card } from '../types';

type PaymentInfoResponse = {
	billing_portal: string;
	payment_methods: Card[];
};

export const fetchUserPaymentInfo = async (userId: string) => {
	const { data } = await axios.get<PaymentInfoResponse>(`/user/payment/${userId}`);
	return data;
};
export const UserPaymentInfoKey = 'userPaymentInfo';

export const useUserPaymentInfo = (config?: QueryConfig<typeof fetchUserPaymentInfo>) => {
	const { userId } = useAuthUserData();

	const { data, ...rest } = useQuery<ExtractFnReturnType<typeof fetchUserPaymentInfo>>({
		...config,
		queryKey: [UserPaymentInfoKey],
		queryFn: () => fetchUserPaymentInfo(userId),
	});
	return {
		billingPortal: data?.billing_portal,
		paymentMethods: data?.payment_methods || [],
		...rest,
	};
};
