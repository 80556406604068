import { DeleteIcon } from 'assets';
import { PropsWithChildren } from 'react';

interface ModalProps extends PropsWithChildren {
	onClose: () => void;
}

export const FullscreenModal = ({ children, onClose }: ModalProps) => {
	return (
		<div className="w-full h-full absolute top-0 left-0 z-50 bg-neutral-100 bg-opacity-50 animate-fadeIn">
			<div className="flex flex-col justify-center w-full h-full">
				<div className="m-auto w-fit h-fit p-14 pt-6 rounded-lg bg-white max-w-xl shadow-lg shadow-neutral-400">
					<div className="flex w-full justify-end">
						<button
							type="button"
							className="p-1 hover:cursor-pointer rounded-full hover:bg-gray-200"
							onClick={onClose}
						>
							<DeleteIcon className="w-6 h-6" />
						</button>
					</div>
					{children}
				</div>
			</div>
		</div>
	);
};

export const Modal = ({ children, ...props }: PropsWithChildren<any>) => {
	return (
		<div className="w-full h-full absolute top-0 left-0 z-50 bg-neutral-100 bg-opacity-50 animate-fadeIn">
			<div className="flex flex-col justify-center w-full h-full">
				<div
					{...props}
					className={`m-auto w-fit h-fit p-4 rounded-lg bg-white max-w-xl shadow-lg shadow-neutral-400 ${
						props.className || ''
					}`}
				>
					{children}
				</div>
			</div>
		</div>
	);
};
