import { axios } from 'libs/axios';
import { useMutation, useQueryClient } from 'react-query';
import { MutationConfig } from 'libs/react-query';
import { UserPaymentInfoKey } from './useUserPaymentInfo';

export const updateUserPaymentMethods = ({
	userId,
	paymentMethodId,
}: {
	userId: string;
	paymentMethodId: string;
}) => {
	return axios.post(`/user/payment_method/${userId}`, {
		payment_method_id: paymentMethodId,
	});
};

export const useUpdateUserPaymentMethods = (
	config?: MutationConfig<typeof updateUserPaymentMethods>,
) => {
	const queryClient = useQueryClient();
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries(UserPaymentInfoKey);
		},
		mutationFn: updateUserPaymentMethods,
	});
};
