import { PromptLayout } from 'components/Layout';

export const AuthMenu = () => {
	return (
		<PromptLayout>
			<div className="w-80">
				<div className="w-32 relative -left-1">
					<img
						src="https://uploads-ssl.webflow.com/5e41e49bc2af606d74c93017/5e47a8555d63256ecfd3af11_castodia%20logo%20-%20white-p-500.png"
						className="h-full w-full"
						alt="Castodia"
					/>
				</div>
				<h1 className="font-semibold text-4xl mt-1">Admin Dashboard</h1>
				<div className="border-b relative my-10 w-full">
					<span className="absolute -top-3.5 p-1 px-3 font-semibold text-sm text-neutral-700 bg-white left-1/2 -translate-x-1/2">
						Continue by
					</span>
				</div>
				<div className="flex flex-col">
					<div id="loginWithGoogleDiv" className="w-full" />
				</div>
			</div>
		</PromptLayout>
	);
};
