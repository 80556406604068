import { AxiosError } from 'axios';
import { FormSelectValue } from 'components/Form';

export const USAGE_PAGE = 0;
export const DATABASES_PAGE = 1;
export const SCHEDULES_PAGE = 2;
export const MEMBERS_PAGE = 3;
export const QUERIES_PAGE = 4;
export const ACCOUNT_PAGE = 5;
export const BILLING_PAGE = 6;
export const SETTING_PAGE = 7;
export const LOGS_PAGE = 8;

export const WORKSPACE_ROLE = {
	MEMBER: 'member',
	BILLING: 'billing',
	ADMIN: 'admin',
	OWNER: 'owner',
};

export const MEMBER_ROLES: FormSelectValue[] = [
	{ id: 'member', text: 'Member' },
	{ id: 'billing', text: 'Billing' },
	{ id: 'admin', text: 'Admin' },
	{ id: 'owner', text: 'Owner' },
];

export const SELECTABLE_MEMBER_ROLES: FormSelectValue[] = [
	{ id: 'member', text: 'Member' },
	{ id: 'billing', text: 'Billing' },
	{ id: 'admin', text: 'Admin' },
];

export const TOAST_NOTIFICATION_MESSAGE_TYPE = 0;
export const TOAST_NOTIFICATION_SUCCESS_TYPE = 1;
export const TOAST_NOTIFICATION_FAILURE_TYPE = 2;
export const TOAST_NOTIFICATION_WARNING_TYPE = 3;

export const isUUID = (id: string) => {
	const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return uuidRegex.test(id);
};

export const getErrorBody = (err: any) => {
	const axiosError = err as AxiosError;
	let errorMsg = '';
	if (axiosError.response) {
		if ((axiosError.response.data as any).detail.error === 'None') {
			errorMsg = (axiosError.response.data as any).detail.message;
		} else {
			errorMsg = (axiosError.response.data as any).detail.error;
		}
	}
	return errorMsg;
};
