import { axios } from 'libs/axios';
import { MutationConfig, queryClient } from 'libs/react-query';
import { useMutation } from 'react-query';
import { WORKSPACE_USER_SCHEDULE_ID_KEY } from './workspaceSchedules';

export const deleteSchedule = async (scheduleId: string) => {
	const { data } = await axios.delete(`/schedule_v2/${scheduleId}`);
	return data;
};

type UseDeleteScheduleOptions = {
	config?: MutationConfig<typeof deleteSchedule>;
};

export const useDeleteSchedule = ({ config }: UseDeleteScheduleOptions = {}) => {
	return useMutation({
		...config,
		onSuccess(data, variables, context) {
			if (config?.onSuccess) {
				config.onSuccess(data, variables, context);
			}
			queryClient.invalidateQueries(WORKSPACE_USER_SCHEDULE_ID_KEY);
		},
		mutationFn: deleteSchedule,
	});
};
